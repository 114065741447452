import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../header/LogoSection';
import { drawerWidth } from '../../../../config/constant';
// import bgside from '../../../../assets/img/Top1.svg'
import bgside from '../../../../assets/img/sidebg2.png'

const Sidebar = ({ drawerOpen, drawerToggle, window, auth }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    // console.log(1,matchUpMd)
    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '14px',
                        paddingRight: '14px'
                    }}
                >
                    <MenuList auth={auth}/>
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 1 }}>
                    <MenuList auth={auth} />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}  aria-label="mailbox folders" >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"               
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      backgroundImage: `url(${bgside})`,
                      backgroundSize: 'cover', 
                      backgroundPosition: 'center',
                        // boxShadow: 6,
                        borderRight: 'none',
                        opacity:0.95,
                      
                      [theme.breakpoints.up('md')]: {
                        top: '80px'
                      }
                    },
                    '& .MuiDrawer-paper::before': {
                      content: '""',
                        position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                        backgroundColor: 'rgb(94 17 94 / 77%)',
                      zIndex: -1
                    }
                  }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object,
    auth:PropTypes.object
};

export default Sidebar;