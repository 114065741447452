import React from 'react'
// import * as settings from '../settings';
import { Avatar, Backdrop, Card, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as catService from '../../../../services/catalogueServices'
import { useParams } from 'react-router-dom';




export default function ViewCatalogue(props) { 
    const [catalogue, setCatalogue] = React.useState(null)
    // const [category,setCategory] = React.useState([])
    const params = useParams()
    
    React.useEffect(() => {
        // catService.getCategory(setCategory)
        catService.getCatalogueData(setCatalogue,params.id)       
      }, [params]);
    return (<>
        
        {
            catalogue?
                        catalogue.map((cat, index) => (
                            <Card sx={{padding:2,width:'100%'}} key={cat.cat_name}> 
                                <CardHeader sx={{padding:0, marginBottom:3}} 
                                        // avatar={
                                        //     <Avatar sx={{ bgcolor: red[500] }} aria-label="catalogue">
                                        //         {cat.cat_name.charAt(0).toUpperCase()}
                                        //     </Avatar>
                                        // }
                                        // title={cat.catalogue_name}
                                        //   subheader="September 14, 2016"
                                />
                                
                                
                                
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <CardMedia
                                                component="img"
                                                height="300"
                                                image={cat.imageURL}
                                                alt={cat.cat_name}
                                                />
                                        </Grid>
                                        <Grid item xs={8} sx={{paddingLeft:2}}>
                                            <Typography gutterBottom variant="subtitle1" component="div" className='sku_header'>
                                            <Stack direction="row" spacing={1}>
                                                    {cat.catalogue_name} 
                                                    {
                                                        cat.status === "Available" ? <Chip label="Available" color="success" variant="outlined" /> :
                                                        <Chip label="Available" color="error" variant="outlined" />
                                                    }
                                                    <Avatar alt='icon' src={cat.iconlink} className="cat_avatar"/>
                                            </Stack>
                                              
                                        </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{marginBottom:5}} >
                                                {cat.description.charAt(0).toUpperCase() + cat.description.slice(1)}
                                            </Typography>
                                            <p>EAN</p>
                                            <Typography variant="body2" color="text.secondary" sx={{marginBottom:5}} >
                                                {cat.ean}
                                            </Typography>
                                            <p>SKU Category</p>
                                            <Typography variant="body2" color="text.secondary" sx={{marginBottom:5}} >
                                                {cat.category}
                                            </Typography>
                                            
                                             <Divider textAlign="left">Available Catalogue Codes</Divider><br></br>
                                            <Stack direction="row" spacing={1} sx={{padding:2}}>
                                            <Grid container>
                                                {   
                                                    cat.sku_code.map((itemcode, s) => {
                                                        return<Grid item xs={2} sx={{marginBottom:2}} ><Chip avatar={<Avatar key={itemcode}>{itemcode.charAt(0)}</Avatar>} label={itemcode} key={index} /> </Grid>
                                                    }) 
                                                }
                                                </Grid>
                                            </Stack>
                                    
                                        </Grid>
                                        
                                            
                                    </Grid>
                                    
                                  
                                </CardContent>
                            </Card>
                        ))
                :
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    }
                  
    </>)
}