import { useDispatch, useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';
import {  TextField, Button } from '@mui/material';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import bgimage from '../../../assets/img/BG.svg'

function AuthLayout(props) {
  const theme = useTheme(); 
  // const [bodyBackgroundColor, setBodyBackgroundColor] = useState('#355B3E'); // set initial color

  // useEffect(() => {
  //   document.body.style.backgroundColor = bodyBackgroundColor; // update body element style
  // }, [bodyBackgroundColor]);

  // const handleLogin = () => {
  //   setBodyBackgroundColor('#ffffff'); // update state to change body background color to white
  //   // handle login logic
  // };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
    {!isMobile && (
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor:"#8e4285",
            justifyContent: 'center', alignItems: 'center',
            backgroundImage: `url(${bgimage})`,
            backgroundRepeat: "repeat"
          }}
        >
          {props.children}
        </Box>
      )}
      {isMobile && (
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            // backgroundColor:"#355B3E"#8e4285
            backgroundColor:"#8e4285"
          }}
        >
          {props.children}
        </Box>
    )}
    </>
    
    
    
  )
}

export default AuthLayout



 
