import React, { useEffect } from 'react';

const CanvasImagePlacement = (props) => {
    const {wid} = props

  useEffect(() => {
    const Query_String = '{"images":[' +
      '{"image_name":"https://difinative.com/wp-content/uploads/2021/06/PlanetScale-1.2-1024x640.png", "Image_TopX":50, "Image_TopY":50, "Image_Height":200, "Image_Width":200, "label": "Image 1"},' +
      '{"image_name":"https://difinative.com/wp-content/uploads/2021/05/difi.png", "Image_TopX":300, "Image_TopY":300, "Image_Height":200, "Image_Width":200, "label": "Image 2"},' +
      '{"image_name":"https://difinative.com/wp-content/uploads/2021/06/PlanetScale-1.2-1024x640.png", "Image_TopX":550, "Image_TopY":550, "Image_Height":200, "Image_Width":200, "label": "Image 3"}' +
    ']}';

      const jsonString = JSON.stringify(wid.query);
      
    //   console.log(111,jsonString)
    const canvas = document.getElementById("myCanvas");
    const context = canvas.getContext("2d");

    const data = JSON.parse(wid.query);
    const images = data.images;

    images.forEach(function(image) {
      const img = new Image();
      img.onload = function() {
        context.drawImage(img, image.Image_TopX, image.Image_TopY, image.Image_Width, image.Image_Height);
        context.font = "bold 12px inherit";
        context.textAlign = "center";
        context.fillText(image.label, image.Image_TopX + image.Image_Width / 2, image.Image_TopY + image.Image_Height + 10);
      };
      img.src = image.image_name;
    });
  }, []);

  return (
    <div>
      <canvas id="myCanvas" className='myCanvas'></canvas>
    </div>
  );
};

export default CanvasImagePlacement;
