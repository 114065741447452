import { Button, Card, Grid, Stack, styled, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete';
import * as orgService from '../../../../services/organizationServices'
import { useState } from "react";
import Notification from '../../../ui/Notifications'
import { Form } from "../../../useForm";
const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
    ":hover": {
      boxShadow: theme.shadows[hovershadow]
    },
  }));

function DeleteOrganizationForm(props) { 
    // const isbutton = false
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const params = useParams()
    function deleteOrganization() {
        orgService.deleteOrganization(setNotify,params.id)
    }
    return (
        <>
            <StyledCard hovershadow={10}>
                <Grid
                    sx={{
                        display: "flex",
                        alignItems: "left",
                        padding: 2,
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h3" color="inherit">
                        Delete Organization Info
                    </Typography>
                
                </Grid>
            </StyledCard>
        <Grid container>
                <Grid xs={12}>
                    <Form>
                        <Card sx={{ padding: 4 }}>
                            <Typography variant="h3" gutterBottom component="div">
                                Are you sure?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                Are you sure you want to delete the selected organizations? All of the objects and their related items will be deleted:
                                <br />
                                Organization : {params.organization}
                            </Typography>
                            {/* <Button type="submit">Delete</Button> */}
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deleteOrganization}>
                                    Delete
                                </Button>
                            </Stack>
                        </Card>
                    </Form>

                </Grid>
            </Grid><Notification
                notify={notify}
                setNotify={setNotify} /></>

        
    )
}

export default DeleteOrganizationForm