import React, { useState } from 'react';

const SvgEditor = () => {
  const [svgCode, setSvgCode] = useState('<svg width="200" height="200"></svg>');

  const handleSvgCodeChange = (event) => {
    setSvgCode(event.target.value);
  };

  return (
    <div>
      <textarea
        value={svgCode}
        onChange={handleSvgCodeChange}
        style={{ width: '100%', height: '200px' }}
      />
      <div>
        <svg
          width="200"
          height="200"
          xmlns="http://www.w3.org/2000/svg"
          dangerouslySetInnerHTML={{ __html: svgCode }}
        />
      </div>
    </div>
  );
};

export default SvgEditor;
