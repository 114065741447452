import React, { useState, useEffect } from 'react'
import { Grid, Divider } from '@mui/material';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import * as dbServices from '../../../../services/dashboardServices'
import SqButtonGroup from './buttonGroup';


const initialFValues = {
    id: 0,
    graph_type: '-1',
    query: '',
    widget_title:'',
    width: '-1',
    height: '-1',
    widget_pos: '-1',
    is_shared:'False'
}

function WidgetForm(props) {
    // console.log("widgetform", props)
    const {auth} = props
    // console.log("log",auth)
    const role = auth.user_role
 
    const { addOrEdit, recordForEdit } = props
    const [queries, setQueries] = useState(null)
    const [widget, setWidget] = useState(null)
    const [qsel, setqsel] = useState(null)

    // useEffect(() => { 
    //     dbServices.getQueries(setQueries,setWidget)
    // },[])
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('query' in fieldValues)
            temp.query = fieldValues.query.length ? "" : "This field is required."
        if ('widget_title' in fieldValues)
            temp.widget_title = fieldValues.widget_title.length != 0 ? "" : "This field is required."
        if ('graph_type' in fieldValues)
            temp.graph_type = fieldValues.graph_type != -1 ? "" : "This field is required."
        if ('width' in fieldValues)
            temp.width = fieldValues.width.length != 0 ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }
    const queryselection = e => {
        const { value } = e.target
        // console.log("value", value)
        // setqsel()
        if (widget != null) {
            widget.map(function (item) {
                // datares.push({ id: item.id, title: item.query })
                if (item.id === value) {
                    item.id = 0
                    console.log("new item",item)
                    setValues(item)
                    setqsel([{ id: item.id, title: item.query }])

                }
            })
        }

    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
            // console.log(12,recordForEdit)
    }, [recordForEdit])
    // console.log(12,values)
    return (
        <><Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    {
                        role === 'admin' ?
                            <><Controls.Select
                                name="graph_type"
                                label="Graph Type"
                                value={values.graph_type}
                                onChange={handleInputChange}
                                options={dbServices.getGraphTypes()}
                                error={errors.graph_type}
                                required={true} />
                                <Controls.Input
                                    name="widget_title"
                                    label="Widget Title"
                                    value={values.widget_title}
                                    onChange={handleInputChange}
                                    error={errors.widget_title} />
                                <Controls.Select
                                    name="width"
                                    label="Width"
                                    value={values.width}
                                    onChange={handleInputChange}
                                    options={dbServices.getWidthTypes()}
                                    error={errors.width}
                                    required={true} />
                                <Controls.Select
                                    name="height"
                                    label="Height"
                                    value={values.height}
                                    onChange={handleInputChange}
                                    options={dbServices.getHeightTypes()}
                                    error={errors.height}
                                    required={true} />
                                <Controls.Select
                                    name="widget_pos"
                                    label="Widget Pos"
                                    value={values.widget_pos}
                                    onChange={handleInputChange}
                                    options={dbServices.getWidgetPos()}
                                    error={errors.widget_pos}
                                    required={true} />
                                <Controls.Select
                                    name="is_shared"
                                    label="is_shared"
                                    value={values.is_shared}
                                    onChange={handleInputChange}
                                    options={[{'id':'False','title':'False'},{'id':'True','title':'True'}]}
                                    error={errors.width}
                                    required={true} />
                                <Controls.InputTextArea
                                    label="Query"
                                    name="query"
                                    value={values.query}
                                    onChange={handleInputChange}
                                    error={errors.query} /></>
                            :
                            <>
                            <Controls.Select
                                name="query_selector"
                                label="Query Selector"
                                value={qsel}
                                onChange={queryselection}
                                options={queries ? queries : []}
                                error={errors.query}
                                required={true} />
                                
                                <Divider sx={{marginLeft: '5%', marginRight: '5%', borderBottomWidth: 4, marginTop:'2%',marginBottom:'2%'}}/>
                                {/* <Divider /> */}
                                
                                <Controls.Select
                                name="graph_type"
                                label="Graph Type"
                                value={values.graph_type}
                                onChange={handleInputChange}
                                options={dbServices.getGraphTypes()}
                                error={errors.graph_type}
                                required={true} />
                                <Controls.Input
                                    name="widget_title"
                                    label="Widget Title"
                                    value={values.widget_title}
                                    onChange={handleInputChange}
                                    error={errors.widget_title} />
                                <Controls.Select
                                    name="width"
                                    label="Width"
                                    value={values.width}
                                    onChange={handleInputChange}
                                    options={dbServices.getWidthTypes()}
                                    error={errors.width}
                                    required={true} />
                                <Controls.Select
                                    name="height"
                                    label="Height"
                                    value={values.height}
                                    onChange={handleInputChange}
                                    options={dbServices.getHeightTypes()}
                                    error={errors.height}
                                    required={true} />
                                <Controls.Select
                                    name="widget_pos"
                                    label="Widget Pos"
                                    value={values.widget_pos}
                                    onChange={handleInputChange}
                                    options={dbServices.getWidgetPos()}
                                    error={errors.widget_pos}
                                    required={true} />
                                {
                                    values.graph_type === "Markdown" ?
                                    <Controls.InputTextArea
                                    label="Markdown"
                                    name="query"
                                    value={values.query}
                                    onChange={handleInputChange}
                                            error={errors.query} />
                                        :
                                        <Controls.InputTextArea
                                    label= "Query"
                                    name="query"
                                    value={values.query}
                                    onChange={handleInputChange}
                                    error={errors.query} />
                            }
                                
                            </>
                    }

<SqButtonGroup onSubmit={handleSubmit} onReset={resetForm} />


                </Grid>

            </Grid>
        </Form>
           </>
    )
}

export default WidgetForm