import React, { useState } from 'react'
// import * as settings from '../settings';
import { Button, Card, CardActions, CardContent, Paper, Divider, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
// import SubHeader from '../../layout/squirrelLayout/SubHeader';
import Grid from '@mui/material/Grid';
import Controls from '../../controls/Controls'
import { useForm, Form } from '../../useForm'
import * as incService from '../../../services/incidentServices'
import Notification from '../../ui/Notifications'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));

const initialFValues = {
    id: 0,
    // incident_no : 'INC',
    title: '',
    description: '',
    category: '',
    severityType: '-1',
    incidentType: '-1',
    statusType: '-1',
    statusEvents: 'DEFAULT',
    infoChannel: '',
    external_link: ''
}
function CreateIncident(props) {
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const [records, setRecords] = useState([])
    // const [skucode, setSkucode] = useState([])
    React.useEffect(() => {  
        incService.getAllIncidentCategories(setRecords)
    }, []);
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title = fieldValues.title ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const isbutton = false

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(23,skucode)
        // catService.createCatalogue(values,setNotify,skucode) 
        incService.createIncident(values, setNotify)
        // // resetForm()
        console.log(123, values)
    }
    // const top100Films = [
    //     { title: 'The Shawshank Redemption', year: 1994 },
    //     { title: 'The Godfather', year: 1972 },
    //     { title: 'The Godfather: Part II', year: 1974 },
    //     { title: 'The Dark Knight', year: 2008 },
    //     { title: '12 Angry Men', year: 1957 },
    //     { title: "Schindler's List", year: 1993 },
    //     { title: 'Pulp Fiction', year: 1994 }
    // ]

    return (<>
        <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
             Create Incident
            </Typography>
          </Grid>
        </StyledCard>
      </Stack>
        <Form>
            <Card>
                <CardContent>
                    {/* <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                    id=""
                                                    name="incident_no"
                                                    label="Incidnet No"
                                                    required={true}
                                                    value={values.incident_no}
                                                    onChange={handleInputChange}
                                                    disabled={true}
                                                    size='small'
                                                    error={errors.incident_no}                                        
                                            />
                                        </Grid>                    
                    </Grid> */}
                    {/* <Divider></Divider> */}
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Controls.Input
                                name="title"
                                label="Incident Title"
                                required={true}
                                value={values.title}
                                onChange={handleInputChange}
                                error={errors.title}
                            />

                        </Grid>

                        <Grid item xs={12} md={3}>

                            <Controls.Select
                                name="incidentType"
                                label="Incident Type"
                                value={values.incidentType}
                                onChange={handleInputChange}
                                options={incService.getIncidentType()}
                                error={errors.incidentType}
                                required={true}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <Controls.Input
                                                            name="category"
                                                            label="Category"
                                                            required={true}
                                                            value={values.category}
                                                            onChange={handleInputChange}
                                                            error={errors.category}                                        
                                /> */}
                            <Autocomplete
  freeSolo
  id="free-solo-2-demo"
  disableClearable
  options={records.map((option) => option.title)}
  value={values.category}
  onChange={(event, newValue) => {
    setValues({...values, category: newValue});
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Category"
      size="small"
      InputProps={{
        ...params.InputProps,
        type: 'search',
      }}
    />
  )}
/>


                        </Grid>


                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Controls.Select
                                name="severityType"
                                label="Severity"
                                required={true}
                                value={values.severityType}
                                options={incService.getSeverityType()}
                                onChange={handleInputChange}
                                error={errors.severityType}
                            />

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controls.Select
                                name="statusType"
                                label="Status"
                                required={true}
                                value={values.statusType}
                                options={incService.getStatusType()}
                                onChange={handleInputChange}
                                error={errors.statusType}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controls.Input
                                name="infoChannel"
                                label="infoChannel"
                                required={true}
                                value={values.infoChannel}
                                onChange={handleInputChange}
                                error={errors.infoChannel}
                            />

                        </Grid>
                        {
                            values.incidentType === 'EVENT' ?
                                <Grid item xs={12} md={3}>
                                    <Controls.Select
                                        name="statusEvents"
                                        label="Event Flag"
                                        required={true}
                                        value={values.statusEvents}
                                        options={incService.getStatusEvents()}
                                        onChange={handleInputChange}
                                        error={errors.statusEvents}
                                    />

                                </Grid>
                                :
                                null
                        }

                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Controls.InputTextArea
                                name="description"
                                label="Description"
                                required={true}
                                value={values.description}
                                onChange={handleInputChange}
                                error={errors.description}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controls.InputTextArea
                                name="external_link"
                                label="External Link"
                                required={false}
                                value={values.external_link}
                                onChange={handleInputChange}
                                error={errors.external_link}
                            />

                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions style={{ justifyContent: 'right' }}>
                    <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>Create Incident</Button>
                    <Button type="button" variant="contained" color="secondary" onClick={resetForm}>Reset</Button>
                </CardActions>
            </Card>
        </Form>
        <Notification
            notify={notify}
            setNotify={setNotify}
        />
    </>)
}

export default CreateIncident