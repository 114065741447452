import * as api from '../config/apiConf'
import axios from 'axios';



export const getGraphTypes = () => ([
    { id: 'Bar', title: 'Bar' },
    { id: 'Line', title: 'Line' },
    { id: 'Table', title: 'Table' },
    { id: 'Canvas', title: 'Canvas' },
    { id: 'Markdown', title: 'Markdown' },
    { id: 'DynamicCanvas', title: 'Dynamic Canvas' },
    { id: 'SVG', title: 'SVG' },

])

export const getWidthTypes = () => ([
    { id: '12', title: 'Full' },
    { id: '6', title: 'Half' },
    { id: '3', title: 'Quarter' }
])

export const getHeightTypes = () => ([
    { id: '100%', title: 'Full' },
    { id: '50%', title: 'Half' }
])
export const getWidgetPos = () => ([
    { id: 'left', title: 'Left' },
    { id: 'right', title: 'Right' }
])
export function getWidgetData(setItems) {

    const token = localStorage.getItem('token');
    axios.get(api.getWicURL, { headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            setItems(res.data)
        })
}

export function getWidget(setWidgetData, id) {
    const token = localStorage.getItem('token');
    
        axios.get(api.getWidgetDataURL+`?widget_id=${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then( 
            res => {
                console.log(res.data)
                setWidgetData(res.data)
            })
    
}

export function getDashWidget(setDashData) {
    const token = localStorage.getItem('token');
    
        axios.get(api.getSmallWidgetURL, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(
            res => {
                // console.log("info", res.data)
                setDashData(res.data)
            }).catch(() => {
                // console.log(123, err)
                setDashData([])
            });
    
}
export function createWidget(wid, setNotify){
    const data = {
        graph_type: wid.graph_type,
        query: wid.query,
        widget_title:wid.widget_title,
        width: wid.width,
        height: wid.height,
        widget_pos: wid.widget_pos,
        is_shared:wid.is_shared
    }
    const token = localStorage.getItem('token');
    axios.post(api.createWicURL, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        () => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            window.location = '/squirrel/dashboard'

            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}


export function updateCustomWidget(wid, setNotify){
    const data = {
        graph_type: wid.graph_type,
        query: wid.query,
        widget_title:wid.widget_title,
        width: wid.width,
        height: wid.height,
        widget_pos: wid.widget_pos,
        is_shared:wid.is_shared
        
    }
    const token = localStorage.getItem('token');
    axios.put(api.updateWicURL+`/${wid.id}`, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        () => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            // return result 
            window.location = '/squirrel/dashboard'

        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}


// export function getAssetStatus(setTableData) {
//     const token = localStorage.getItem('token');
    
//         axios.get(`${settings.API_SERVER}/squirrel/admin-app/assets-status-view`, {
//             headers: { 'Authorization': `Bearer ${token}` }
//         }).then(
//             res => {
//                 // console.log("info_new", res.data)
//                 if (res.data.length === 0) {
//                     setTableData([])
//                 }
//                 else {
//                     // const dt =res.data
//                     setTableData(res.data)    
//                 }
//             }).catch(err => {
//                 // console.log(123, err)
//                 setTableData([])
//             });
    
// }


export function deleteWidget(setNotify,wid) { 
    const token = localStorage.getItem('token');
    axios.delete(api.deleteWicURL+`/${wid.id}`, { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            if (res.data.message === "Not Allowed") {
                setNotify({
                    isOpen: true,
                    message: "You have no permission to Delete",
                    type: "error"
                })
            }
            else
            {
                setNotify({
                    isOpen: true,
                    message: "Asset Item Deleted",
                    type: "success"
                })
             window.location = "/squirrel/assets"

            }
            
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function getQueries(setQueries,setWidget){
    const token = localStorage.getItem('token');
    axios.get(api.getQueriesURL, { headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            let datares = []
            // console.log(123234, res)

            res.data.widget_data.map(function (item) { 
                datares.push({ id: item.id, title: item.query })
                return null;
            })
            // console.log(123234, datares)

            setQueries(datares) 
            setWidget(res.data.widget_data)
        })
}

export function getReFillWidget(setWidgetData, id,setTitle,setColumns,setFilterData,setDates) {
    const token = localStorage.getItem('token');
    
        axios.get(api.getRefillURL+`?widget_id=${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(
            res => {
                
                var result = res.data
                var outRes = {}
                var title = ''
                var dateList = []
                
                if (result !== null) {
                    Object.entries(result).forEach(([, value]) => { 
                        outRes = value.result
                        title = value.widget_title
                        dateList = value.dates
                        }
                    )
                }
                var outArray = []
                var timeArray = []
                var dateArray = []
                // console.log(outRes)
                Object.entries(outRes).forEach(
                    ([, v]) => {
                         const ca = v.reduce((r, { time, ...rest }) => {
                            const key = `${time}`;
                            r[key] = r[key] || { time, items: [] };
                            r[key]['date'] = rest.date
                            // r[key]['site'] = rest.site
                            r[key]["items"].push(rest)
                             if (!dateArray.includes(rest.date)) {
                                dateArray.push(rest.date)
                            }
                            return r;
                         }, {})
                        Object.entries(ca).forEach(([s]) => {
                          
                            outArray.push(ca[s])
                            if (!timeArray.includes(s)) {
                                timeArray.push(s)
                            }
                        })
                    })
                
                
               
                    // console.log("outArray", outArray)
                var filteredArray = outArray.filter(function (el) { 
                    return el.time === '06.10.00'
                })
                dateArray = dateList.sort()
                var finalHeader = ['Store']
                dateArray.map(item => {
                    finalHeader.push(item)
                    return null
                })
                setWidgetData(outArray)
                // setTimeData(timeArray)   
                console.log(timeArray)
                setTitle(title)
                setColumns(finalHeader)
                setDates(dateList)
                
                // console.log("filter", filteredArray)
                var finalData =[]
                dateArray.map(item => {
                    var obj = {}
                    // const fdata = filteredArray.filter(function (s) {
                    //     return s.date === item
                    // })
                    // console.log("filter", fdata)
                    filteredArray.map(fa => {
                        
                        if (fa.date === item) {
                            obj[item] = fa.items
                            // console.log("fa.items", fa.items)

                        }
                   return null

                    })
                    finalData.push(obj)
                   return null
                })
                // console.log("finalData", finalData)

                var finalDataset = {}
                finalData.map((fd) => {
                    Object.entries(fd).forEach(
                        ([, v]) => { 
                            // console.log(123,k,'======',v)
                            var inisideData = v

                            inisideData.map((fds) => {


                                if (finalDataset.hasOwnProperty(fds.site)) {
                                    var tempq = finalDataset[fds.site]
                                    tempq.push(fds.total)
                                    // console.log(123,fds.total,fds.site,fds.date)
                                    finalDataset[fds.site] = tempq
                                }
                                else {
                                    var tempw = []
                                    tempw.push(fds.total)
                                    // console.log(12,fds.total,fds.site,fds.date)
                                    finalDataset[fds.site] = tempw
                                }
                   return null

                            })
                        })
                   return null
                    
                })
                // console.log(finalDataset)
                var filterDataFinal = []
                Object.entries(finalDataset).forEach(([k, v]) => {
                    var tempe = [k].concat(v)
                    // console.log('merged',temp)
                    filterDataFinal.push(tempe)
                }

                )
                console.log("filterDataFinal",filterDataFinal) 

                setFilterData(filterDataFinal)
            })
    
}


export function getFilteredData(widget_data,dates,setFilterData,time) {
    var outArray = widget_data
    var dateArray = dates
    var filteredArray = outArray.filter(function (el) { 
        return el.time === time
    })

    var finalHeader = ['Store']
    dateArray.map(item => {
        finalHeader.push(item)
        return null

    })

    var finalData =[]
    dateArray.map(item => {
        var obj = {}

        filteredArray.map(fa => {
            
            if (fa.date === item) {
                obj[item] = fa.items

            }
            return null;
        })
        finalData.push(obj)
        return null;
       
    })
    // console.log("finalData", finalData)

    var finalDataset = {}
    finalData.map((fd) => {
        Object.entries(fd).forEach(
            ([, v]) => { 
                // console.log(123,k,'======',v)
                var inisideData = v

                inisideData.map((fds) => {


                    if (finalDataset.hasOwnProperty(fds.site)) {
                        var tempr = finalDataset[fds.site]
                        tempr.push(fds.total)
                        // console.log(123,fds.total,fds.site,fds.date)
                        finalDataset[fds.site] = tempr
                    }
                    else {
                        var tempt = []
                        tempt.push(fds.total)
                        // console.log(12,fds.total,fds.site,fds.date)
                        finalDataset[fds.site] = tempt
                    }
                    return null;
                })
            })
            return null;

    })
    // console.log(1111,finalDataset)
    var filterDataFinal = []
    Object.entries(finalDataset).forEach(([k, v]) => {
        var tempy = [k].concat(v)
        // console.log('merged',tempy)
        filterDataFinal.push(tempy)
    }

    )
    setFilterData(filterDataFinal)

}



export function getReFillOnChange(setWidgetData, id, setTitle, setColumns, setFilterData, setDates,time) {
    const token = localStorage.getItem('token');
    
    axios.get(api.getRefillURL+`?widgetID=${id}&time=${time}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {

            console.log("new refill on change", res)
            var result = res.data
                var outRes = {}
                var title = ''
                var dateList  = []
                if (result !== null) {
                    // Object.entries(result).forEach(([key, value]) => { 
                    //     console.log(value)
                    //     outRes = value.result
                    //     title = value.widget_title
                    //     siteList = value.sites
                    //     }
                    // )

                    outRes = result.result
                        title = result.widget_title
                    dateList = result.dates
                }
                
                console.log(outRes)
                var outArray = []
                var timeArray = []
                var dateArray = []
                // console.log(outRes)
                Object.entries(outRes).forEach(
                    ([, v]) => {
                         const ca = v.reduce((r, { time, ...rest }) => {
                            const key = `${time}`;
                            r[key] = r[key] || { time, items: [] };
                            r[key]['date'] = rest.date
                            // r[key]['site'] = rest.site
                            r[key]["items"].push(rest)
                             if (!dateArray.includes(rest.date)) {
                                dateArray.push(rest.date)
                            }
                            return r;
                         }, {})
                        Object.entries(ca).forEach(([s]) => {
                          
                            outArray.push(ca[s])
                            if (!timeArray.includes(s)) {
                                timeArray.push(s)
                            }
                        })
                    })
                
                
               
                    // console.log("outArray", outArray)
                var filteredArray = outArray.filter(function (el) { 
                    return el.time === time
                })
                dateArray = dateList.sort()
                var finalHeader = ['Store']
            dateArray.map(item => {
                finalHeader.push(item)
                return null

            })
                setWidgetData(outArray)
                // setTimeData(timeArray)   
                console.log(timeArray)
                setTitle(title)
                setColumns(finalHeader)
                setDates(dateList)
                // console.log(dateArray,dateList)
                // console.log("filter", filteredArray)
                var finalData =[]
                dateArray.map(item => {
                    var obj = {}
                    // const fdata = filteredArray.filter(function (s) {
                    //     return s.date === item
                    // })
                    // console.log("filter", fdata)
                    filteredArray.map(fa => {
                        
                        if (fa.date === item) {
                            obj[item] = fa.items
                            // console.log("fa.items", fa.items)

                        }
                   return null

                    })
                    finalData.push(obj)
                    return null
                   
                })
                // console.log("finalData", finalData)

                var finalDataset = {}
                finalData.map((fd) => {
                    Object.entries(fd).forEach(
                        ([, v]) => { 
                            // console.log(123,k,'======',v)
                            var inisideData = v

                            inisideData.map((fds) => {


                                if (finalDataset.hasOwnProperty(fds.site)) {
                                    var tempu = finalDataset[fds.site]
                                    tempu.push(fds.total)
                                    // console.log(123,fds.total,fds.site,fds.date)
                                    finalDataset[fds.site] = tempu
                                }
                                else {
                                    var tempi = []
                                    tempi.push(fds.total)
                                    // console.log(12,fds.total,fds.site,fds.date)
                                    finalDataset[fds.site] = tempi
                                }
                   return null

                            })
                        })
                   return null
                    
                })
                // console.log(finalDataset)
                var filterDataFinal = []
                Object.entries(finalDataset).forEach(([k, v]) => {
                    var temp = [k].concat(v)
                    // console.log('merged',temp)
                    filterDataFinal.push(temp)
                }

                )
                console.log("filterDataFinal",filterDataFinal) 
                setFilterData(filterDataFinal)
         })
    
}

// export function getOpcua(setData) {
//     const token = localStorage.getItem('token');
    
//         axios.get(api.opcuaURL, {
//             headers: { 'Authorization': `Bearer ${token}` }
//         }).then( 
//             res => {
//                 console.log(res.data)
//                 setData(res.data)
//                 console.log(typeof res.data); // "object"
//             })
          

// }


export function getOpcua(setData) {
    const token = localStorage.getItem('token');

    axios.get(api.opcuaURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            console.log("me",res.data)
            var str = res.data
            // const jsonStr = str.replace(/'/g, '"'); // replace single quotes with double quotes 
            // const dict = JSON.parse(jsonStr);
            // console.log(dict)
            // const data = String(res.data);
            // console.log(res.data,"check res.data"); 
            // console.log(typeof res.data); // "object"
            // console.log(data, "check data");
            setData(str);
     
        }
    )
}
