import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import * as incService from '../../../services/incidentServices'
import Notification from '../../ui/Notifications'
import { Chip, CircularProgress, Backdrop} from '@mui/material';
import { useParams } from 'react-router'


export default function IncidentFilter(props) { 
    const params = useParams()
    const [incident, setIncident] = useState(null)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })

    React.useEffect(() => {  
            incService.geIncidentFilter(setIncident,params.incident_type)
    }, []);
    if (incident) {
        const options = {
            // filterType: "checkbox",
            print: false,
            downloadOptions: {
                filename: "squirrel_incident.csv",
                separator: ","
            },
            // onRowClick: (rowData, rowState) => { 
            //     alert(rowData[0])
            // },
            onRowsDelete: (rowsDeleted, newData) => {
                
                setNotify({ isOpen: true, message: 'Can\'t delete this! No Permission', type: 'error' })
              },
          };
        let columns = [] 
        let data = []
        incident.map((item, index) => {
            // console.log(item)
            let incident_data = {}
            const date_lst = ['updated_at', 'created_at']

            if (index === 0) {
                const arr = ['id','description', 'created_by', 'created_at', 'updated_at', 'external_link','severityType','incidentType']
                Object.entries(item).forEach(([key, value]) => {

                    if (arr.indexOf(key) > -1) {
                        
                        columns.push(
                            {
                                name: key,
                                label: key.toUpperCase(),
                                options: {
                                    filter: true,
                                    sort: true,
                                display:false

                                }

                            }
                        )
                    }
                    else {
                        if (key === "id") {
                            columns.push(
                                {
                                    name: key,
                                    label: key.toUpperCase(),
                                    options: {
                                    // filter: true,   
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return (
                                                // <a href={"squirrel-incident/view-incident/" + value}>{value}</a>
                                              <a href={"/squirrel/squirrel-incident-services/view-incident/"+value}>{value}</a>  
                                                
                                            );
                                          }
                                    }
                                }
                            )
                            
                        }
                        else {
                            if (key === 'statusType') {
                                columns.push(
                                    {
                                        name: key,
                                        label: key.toUpperCase(),
                                        options: {
                                        filter: true,   
                                            sort: true,
                                            customBodyRender: (value, tableMeta, updateValue) => {
                                                // alert(value)
                                                if (value === 'OPEN') {
                                                    return  <Chip label={value} sx={{color:'#2196f3'}} />
                                                }
                                                else if (value === 'CLOSED') {
                                                    // return <span sx={{ color: '#4caf50' }}>{value}</span>
                                                    return <Chip label={value} sx={{ color: '#4caf50' }} />
                                                }
                                                else if (value === 'ACTION_PENDING') {
                                                    // console.log(value)
                                                    return  <Chip label={value} sx={{color:'#e64a19'}} />                                    
                                                }
                                                else {
                                                    // return '#81d4fa'
                                                    
                                                    return  <Chip label={value} sx={{color:'#81d4fa'}} />
                                                }
                                                
                                              }
                                        }
                                    }
                                )
                            }
                            else {
        
                                columns.push(
                                    {
                                        name: key,
                                        label: key.toUpperCase(),
                                        options: {
                                        filter: true,   
                                        sort: true
                                        }
                                    }
                                )                
                                
                            }
                           
                        }
                    }
                        
                    })
            }
            
            Object.entries(item).forEach(([key, value]) => {
                
                if (key === 'created_by') {
                    incident_data[key] = value['email']
                }
                else if (date_lst.indexOf(key) > -1) {
                    const date = new Date(value);
const year = date.getFullYear().toString().padStart(4, '0');
const month = (date.getMonth() + 1).toString().padStart(2, '0');
const day = date.getDate().toString().padStart(2, '0');
const hours = date.getHours().toString().padStart(2, '0');
const minutes = date.getMinutes().toString().padStart(2, '0');
const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
                    incident_data[key] = formattedDate
                 }
                else {
                    
                    incident_data[key] = value   
                }
            })
            data.push(incident_data)
        })
        // console.log("incdataprops",props)
        // const data = incident_data
        // incident 
        // columns.map((item, index) => { 
        //     console.log(item.name)            
        // })
        // console.log(columns)
        return (
            <> 
                {/* <SubHeader title='Incident Hub' isButton={isbutton} btnUrl="/squirrel/squirrel-incident/create" btnTitle="Create" /> */}
                {/* <SubHeader title='Incident Hub' isButton={isbutton} btnUrl="/squirrel/squirrel-incident-services/create" btnTitle="Create" /> */}

                <MUIDataTable columns={columns} data={data} options={options} />
                <Notification
                notify={notify}
                setNotify={setNotify}
            />
            </>
      );
    }
    else {
        // console.log(2, incident)
        return <>
             <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
        </>
    }

}