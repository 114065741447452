import React, { useState } from 'react'
import { Button, Card, Container, FormControl, IconButton, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import Controls from '../../../controls/Controls'
import { Form, useForm } from '../../../useForm';
import * as locationService from '../../../../services/locationServices'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Notification from '../../../ui/Notifications'
import { Link, useParams } from 'react-router-dom';
// const ccs= require('countrycitystatejson')
import { Country, City, State } from 'country-state-city';


const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
    ":hover": {
          boxShadow: theme.shadows[hovershadow]
    },
  }));
// ########################################################
// The main Home component returned by this Module
// ########################################################
const initialFValues = {
    id: 0,
    site_code:'',
    location_code: '',    
    posID: '',
    location_name: '',    
    organization: '-1',
    location_category:'-1',
    status: '-1',    
    replenishing_store: '-1',
    description: '',
    address: '',
    house_number: '',
    street: '',    
    city: '-1',
    zipcode: '',
    latitude: '',
    longitude: '',
    mapURL: '',
    pictureURL:'',
    chiller_count: 0,
    sbk_width: 0,
    network_type: '',
    camera_angle: '',
    edge_location: '',
    image_polling_interval: 0,
    wifi_ssid: '',
    SKU_threshold_amber: 0,
    SKU_threshold_red: 0,
    SKU_rowding_threshold: 0,
    SKU_alerting_channel: '',
    Edge_server_heartbeat_interval: 0,
    Edge_server_sync_interval: 0,
    Inference_engine_interval: 0,
    Camera_position_table: [],
    Region_of_interest_table:[],
    custom_feild1:'',
    custom_feild2:'',
    custom_feild3:'',
    custom_feild4: '',
    State:'-1',
    
}

function EditLocationForm(props) {
    // Material UI Classes
    // console.log("location", props) 
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const [organization,setOrganization] = useState([])
    const [locationCategory,setLocationCategory] = useState([])
    const [location,setLocation] = useState([])
    const [city,setCity] = useState([])
    const [store,setStore] = useState([])
    const [catalogueData, setCatalogueData] = useState([])
    const [country,setCountry] = useState(null)
    
    
    React.useEffect(() => {
        locationService.getOrganizationData(setOrganization,setCountry)  
    }, []);
    React.useEffect(() => {  
        locationService.getLocationCategoryData(setLocationCategory)
    }, []);
    React.useEffect(() => {  
        locationService.getStores(setStore)
    }, []);
    React.useEffect(() => {  
        locationService.getLocation(setLocation)
    }, []);
    // React.useEffect(() => {  
    //     locationService.getCity(setCity)
    // }, []);
    React.useEffect(() => {  
        locationService.getCatalogues(setCatalogueData)
      }, []);
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('fullName' in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
        if ('mobile' in fieldValues)
            temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
        if ('departmentId' in fieldValues)
            temp.departmentId = fieldValues.departmentId.length != 0 ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        catalogue,
        setCatalogue,
        handleChangeInput,
        handleAddFields,
        handleRemoveFields
    } = useForm(initialFValues, true, validate);

    const params = useParams(); 
    React.useEffect(() => {
        locationService.getLocationData(setValues,params.id,setCatalogue)      
      }, []);
    const isbutton = false
    const handleSubmit = (e) => {
        e.preventDefault();
     
        locationService.updateLocation(values, catalogue, setNotify, params.id)
        
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    function getState(country, organization) {
        console.log(222,country)
        if (organization !== '-1' && country !== null) {
            let cn = country.find(item => item.id === organization)?.country;
            let states = State.getStatesOfCountry(cn)
            // console.log("sss", states)
            
               const statesInfo = states.map(s => ({ id: s.isoCode, title: s.name }));
              //  console.log("sates",statesInfo)
             return statesInfo
        }
        else{
            return [{id:"-1",title:""}]
        }
    
    }
    function getCitiesInfo(state, country, organization) {  
        // console.log(33,state,country,organization)
          if(state !== '-1'){
              let cn = country.find(item => item.id === organization)?.country; 
              let cities = City.getCitiesOfState(cn, state)
              // console.log("hahah",cities)
               const citiesInfo = cities.map(s => ({ id: s.name, title: s.name }));
      
               return citiesInfo
          }else{
              return [{id:"-1",title:""}]
       
          }
      
      }
    return (
        <>
            <Stack m={3} gap={3}>
          <StyledCard hovershadow={10}>
            <Grid
              sx={{
                display: "flex",
                alignItems: "left",
                padding: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" color="inherit">
                Edit Store details
              </Typography>
              <Button
                component={Link}
                to="/squirrel/stores"
                variant="contained"
                color="secondary"
              >
                <KeyboardBackspaceOutlinedIcon />
              </Button>
            </Grid>
          </StyledCard>
            </Stack>
            <Stack m={3} gap={3}>
          <StyledCard hovershadow={10}>
            <div>
            <Form>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Store Info
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}></Typography>
                    </AccordionSummary>
                     <AccordionDetails>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <Controls.Input
                                        name="site_code"
                                        label="Site Code"
                                        required={true}
                                        value={values.site_code}
                                        onChange={handleInputChange}
                                        error={errors.site_code}                                        
                                    />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                
                                    <Controls.Input
                                        name="location_code"
                                        label="Store Code"
                                        required={true}
                                        value={values.location_code}
                                        onChange={handleInputChange}
                                        error={errors.location_code}                                        
                                    />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controls.Input
                                        name="posID"
                                        label="PosID"
                                        required={true}
                                        value={values.posID}
                                        onChange={handleInputChange}
                                        error={errors.posID}
                                        
                                    />
                            </Grid>                            
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={2}> 
                                <Controls.Input
                                        name="location_name"
                                        label="Location Name"
                                        required={true}
                                        value={values.location_name}
                                        onChange={handleInputChange}
                                        error={errors.location_name}
                                        
                                    />
                            </Grid>
                            <Grid item xs={12}  md={2}>
                                <Controls.Select
                                    name="organization"
                                    label="Organization"
                                    value={values.organization}
                                    onChange={handleInputChange}
                                    options={organization}
                                    error={errors.organization}
                                    required={true}
                                />
                                </Grid>
                                <Grid item xs={12} md={2}>
                    <Controls.Select
                      name="State"
                      label="State"
                      value={values.State}
                      onChange={handleInputChange}
                      options={getState(country,values.organization)}   
                      error={errors.State}
                      required={true}
                    />
                  </Grid>
                            <Grid item xs={12} md={2}>
                                <Controls.Select
                                    name="location_category"
                                    label="Store Category"
                                    value={values.location_category}
                                    onChange={handleInputChange}
                                    options={locationCategory?locationCategory:{'id':'-1','title':'Select'}}
                                    error={errors.location_category}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controls.Select
                                    name="status"
                                    label="Status"
                                    value={values.status}
                                    onChange={handleInputChange}
                                    options={locationService.getLocationStatus()}
                                    error={errors.status}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12}  md={2}>
                                <Controls.Select
                                    name="replenishing_store"
                                    label="Replenishing Store"
                                    value={values.replenishing_store}
                                    onChange={handleInputChange}
                                    options={store}
                                    error={errors.replenishing_store}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12}  md={6}>
                                <Controls.InputTextArea
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    onChange={handleInputChange}
                                    error={errors.description}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controls.InputTextArea
                                        name="address"
                                        label="Address"
                                        value={values.address}
                                        onChange={handleInputChange}
                                        error={errors.address}  
                                        required={true}
                                    />
                            </Grid> 
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={2}>
                                <Controls.Input
                                        name="house_number"
                                        label="House Number"
                                        required={true}
                                        value={values.house_number}
                                        onChange={handleInputChange}
                                        error={errors.house_number}
                                        
                                    />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controls.Input
                                        name="street"
                                        label="Street"
                                        required={true}
                                        value={values.street}
                                        onChange={handleInputChange}
                                        error={errors.street}
                                        
                                    />
                            </Grid>
                            <Grid item xs={12}  md={2}>
                                <Controls.Select
                                    name="city"
                                    label="City"
                                    value={values.city}
                                    onChange={handleInputChange}
                                    options={getCitiesInfo(values.State,country,values.organization)}
                                    error={errors.city}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controls.Input
                                        name="zipcode"
                                        label="Zipcode"
                                        required={true}
                                        value={values.zipcode}
                                        onChange={handleInputChange}
                                        error={errors.zipcode}
                                        
                                    />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controls.Input
                                        name="latitude"
                                        label="Latitude"
                                        required={true}
                                        value={values.latitude}
                                        onChange={handleInputChange}
                                        error={errors.latitude}
                                        
                                    />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controls.Input
                                        name="longitude"
                                        label="Longitude"
                                        required={true}
                                        value={values.longitude}
                                        onChange={handleInputChange}
                                        error={errors.longitude}                                        
                                    />
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12}  md={6}>
                                <Controls.InputTextArea
                                    name="mapURL"
                                    label="Map URL"
                                    value={values.mapURL}
                                    onChange={handleInputChange}
                                    error={errors.mapURL}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controls.InputTextArea
                                        name="pictureURL"
                                        label="Store Image URL"
                                        value={values.pictureURL}
                                        onChange={handleInputChange}
                                        error={errors.pictureURL}  
                                        required={true}
                                    />
                            </Grid>                           
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Store Configurations</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                        
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                            <Grid container >
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="chiller_count"
                                        label="Chiller Count"
                                        required={true}
                                        inputProps={true}
                                        value={values.chiller_count}
                                        onChange={handleInputChange}
                                        error={errors.chiller_count}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="sbk_width"
                                        label="SBK Width"
                                        required={true}
                                        inputProps={true}
                                        value={values.sbk_width}
                                        onChange={handleInputChange}
                                        error={errors.sbk_width} 
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                            name="network_type"
                                            label="Network Type"
                                            required={true}
                                            value={values.network_type}
                                            onChange={handleInputChange}
                                            error={errors.network_type}                                        
                                        />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                            name="camera_angle"
                                            label="Camera Angle"
                                            required={true}
                                            value={values.camera_angle}
                                            onChange={handleInputChange}
                                            error={errors.camera_angle}                                        
                                        />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                            name="edge_location"
                                            label="Edge Location"
                                            required={true}
                                            value={values.edge_location}
                                            onChange={handleInputChange}
                                            error={errors.edge_location}                                        
                                        />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="image_polling_interval"
                                        label="Image Polling Interval"
                                        required={true}
                                        inputProps={true}
                                        value={values.image_polling_interval}
                                        onChange={handleInputChange}
                                        error={errors.image_polling_interval}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="SKU_threshold_amber"
                                        label="SKU Threshold Amber"
                                        required={true}
                                        inputProps={true}
                                        value={values.SKU_threshold_amber}
                                        onChange={handleInputChange}
                                        error={errors.SKU_threshold_amber}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="SKU_threshold_red"
                                        label="SKU Threshold Red"
                                        required={true}
                                        inputProps={true}
                                        value={values.SKU_threshold_red}
                                        onChange={handleInputChange}
                                        error={errors.SKU_threshold_red}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                        name="SKU_rowding_threshold"
                                        label="SKU Rowding Threshold"
                                        required={true}
                                        inputProps={true}
                                        value={values.SKU_rowding_threshold}
                                        onChange={handleInputChange}
                                        error={errors.SKU_rowding_threshold}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                        name="SKU_alerting_channel"
                                        label="SKU Alerting Channel"
                                        required={true}
                                        inputProps={true}
                                        value={values.SKU_alerting_channel}
                                        onChange={handleInputChange}
                                        error={errors.SKU_alerting_channel}                                         
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="Edge_server_heartbeat_interval"
                                        label="Edge Server Heartbeat Interval"
                                        required={true}
                                        inputProps={true}
                                        value={values.Edge_server_heartbeat_interval}
                                        onChange={handleInputChange}
                                        error={errors.Edge_server_heartbeat_interval}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="Edge_server_sync_interval"
                                        label="Edge Server Sync Interval"
                                        required={true}
                                        inputProps={true}
                                        value={values.Edge_server_sync_interval}
                                        onChange={handleInputChange}
                                        error={errors.Edge_server_sync_interval}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Controls.Input
                                        name="Inference_engine_interval"
                                        label="Inference_engine_interval"
                                        required={true}
                                        inputProps={true}
                                        value={values.Inference_engine_interval}
                                        onChange={handleInputChange}
                                        error={errors.Inference_engine_interval}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                        name="Camera_position_table"
                                        label="Camera_position_table"
                                        required={true}
                                        // inputProps={true}
                                        value={values.Camera_position_table}
                                        onChange={handleInputChange}
                                        error={errors.SKU_rowding_threshold}                                         
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                        name="Region_of_interest_table"
                                        label="Region_of_interest_table"
                                        required={true}
                                        // inputProps={true}
                                        value={values.Region_of_interest_table}
                                        onChange={handleInputChange}
                                        error={errors.Region_of_interest_table}                                         
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                        name="wifi_ssid"
                                        label="WIFI SSID"
                                        required={true}
                                        value={values.wifi_ssid}
                                        onChange={handleInputChange}
                                        error={errors.wifi_ssid}                                         
                                    />
                                </Grid>
                            </Grid>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        SKU Threshold Mapping
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                        </Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            {
                               catalogue !== undefined || catalogue !== null? catalogue.map((cat, index) => (
                                   
                                    <Grid container key={index}>
                                        <Grid item xs={3}>
                                            <Controls.Select
                                            name="catalogueName"
                                            label="Catalogue"
                                            value={cat.catalogueName}
                                            options={catalogueData}
                                            onChange={event => handleChangeInput(cat.id, event)}
                                                />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controls.Input
                                            name="threshold"
                                            label="Threshold Value"
                                            // variant="filled"
                                            value={cat.threshold}
                                            onChange={event => handleChangeInput(cat.id, event)}
                                                />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controls.Input
                                            name="minimum_reorder_quantity"
                                            label="Minimum Re-Order Quantity"
                                            // required={true}
                                            inputProps={true}
                                            value={cat.minimum_reorder_quantity}
                                            onChange={event => handleChangeInput(cat.id, event)}
                                                />
                                        </Grid>
                                        {index === 0 ? <IconButton
                                                onClick={handleAddFields}
                                                >
                                                <AddIcon />
                                            </IconButton> :
                                            <IconButton disabled={cat.length === 1} onClick={() => handleRemoveFields(cat.id)}>
                                                <RemoveIcon />
                                            </IconButton>} 
                                        
                                    </Grid>
                               ))
                                    :null
                            }

                   
                     </AccordionDetails>
                    </Accordion>
                    <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Additional Info
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  If any Additional information update here (Not Mandatory)
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container key="extrafields">
                  <Grid item xs={12}>
                    <Controls.Input
                      name="custom_feild1"
                      label="Custom Field 1"
                      // required={true}
                      value={values.custom_feild1}
                      onChange={handleInputChange}
                      error={errors.custom_feild1}
                    />
                                </Grid>
                                <Grid item xs={12}>
                    <Controls.Input
                      name="custom_feild2"
                      label="Custom Field 2"
                      // required={true}
                      value={values.custom_feild2}
                      onChange={handleInputChange}
                      error={errors.custom_feild2}
                    />
                                </Grid>
                                <Grid item xs={12}>
                    <Controls.Input
                      name="custom_feild3"
                      label="Custom Field 3"
                      // required={true}
                      value={values.custom_feild3}
                      onChange={handleInputChange}
                      error={errors.custom_feild3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.Input
                      name="custom_feild4"
                      label="Custom Field 4"
                      // required={true}
                      value={values.custom_feild4}
                      onChange={handleInputChange}
                      error={errors.custom_feild4}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
                    <Accordion expanded={true}>
                    
                    <AccordionDetails>
                        <Typography>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>Update Store</Button>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                </Form>
                    </div>
                </StyledCard>
                </Stack>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
      
    );
  }

export default  EditLocationForm; 