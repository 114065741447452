import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as orgServices from '../../../../services/organizationServices'
import { Backdrop, CircularProgress, Divider, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';


export default function OrganizationCard(props) {

    const [organization, setOrganization] = React.useState(null)
    const params = useParams()
    React.useEffect(() => {

        orgServices.getOrganizationData(setOrganization,params.id)
      
      },[params.id]);
    // console.log(organization)
    if (organization !== undefined) {
        return (
            <>
                <Grid container>
                    {
                        organization?
                        organization.map((org, index) => (
                            <Card sx={{padding:2,width:'100%'}} key={index}> 
                                <CardHeader sx={{padding:0, marginBottom:3}} className='eh-header'
                                        // avatar={
                                        //     <Avatar sx={{ bgcolor: red[500] }} aria-label="organization">
                                        //         {org.org_name.charAt(0).toUpperCase()}
                                        //     </Avatar>
                                        // }
                                        title={org.organization}
                                        //   subheader="September 14, 2016"
                                />
                                <CardMedia
                                    component="img"
                                    height="500"
                                    image={org.logo}
                                    alt={org.organization}
                                    />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary" sx={{marginBottom:5}} >
                                        {org.description}
                                    </Typography>

                                    <Divider textAlign="left">Organization Info</Divider>
                                    <p>Address</p>
                                        <Typography variant="body2" color="text.secondary" sx={{marginBottom:2}} >
                                            {org.address}
                                        </Typography>
                                        <p>Country</p>
                                        <Typography variant="body2" color="text.secondary" sx={{marginBottom:2}} >
                                            {orgServices.findCountryByCOde(org.country)}
                                        </Typography>
                                        <p>Email</p>
                                        <Typography variant="body2" color="text.secondary" sx={{marginBottom:2}} >
                                            {org.email}
                                        </Typography>
                                        <p>Contact Number</p>
                                        <Typography variant="body2" color="text.secondary" sx={{marginBottom:2}} >
                                            {org.primary_contact_number}
                                        </Typography>
                                </CardContent>
                            </Card>
                        ))
                            :
                            <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    }
                  </Grid>
            </>
         
        );
    }
    else {
        return <></>
    }
   
}