import React, { useEffect, useState } from "react";
import { Backdrop, Card, CardContent, CardHeader, CircularProgress, IconButton, styled, Typography } from "@mui/material";
import * as dsService from '../../../../services/dashboardServices'
import Controls from '../../../controls/Controls'
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Notification from '../../../ui/Notifications'
import LineChart from "./LineChart";
import BarChart from "./barChart";
import Markdown from './markdown' 
import TableInventory from "./TableInventory";
import CanvasImagePlacement from "./dynamicDiag";
import MyComponent from "./markdownDiag";
import SVGComponent from "./svgcomponents";
import WebSocketExample from "./DemoWebsocket";
import WebSocketExample2 from "./opcui";



const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: "rgb(255 255 255 / 80%)",
  width: '100%',
  height: '100%',
  marginBottom: "3px",
  ":hover": {
    boxShadow: theme.shadows[10],
  },
  boxShadow: theme.shadows[3],

}));

// export default function Widget({ id, setRecordForEdit, wid, setOpenPopup,provided }) {
  export default function Widget({ id, setRecordForEdit, wid, setOpenPopup }) {
  const [widgetData, setWidgetData] = useState(null)
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
  useEffect(() => {
    dsService.getWidget(setWidgetData,id)
  }, [])

 
  
  function editWidget() {
    setRecordForEdit(wid) 
    setOpenPopup(true)
  }
  function deleteWidget() {
    // alert(wid.id)
    dsService.deleteWidget(setNotify,wid.id)
  }
  return (
  <>
    {
        widgetData ?
        
          <>
            {/* <CardWrapper key={"info" + widgetData["widget_" + id]}  {...provided.draggableProps} {...provided.dragHandleProps}  */}
            <CardWrapper key={"info" + widgetData["widget_" + id]}>
            <CardHeader
              title={widgetData["widget_" + id].widget_title}
              action={<><IconButton aria-label="settings" onClick={editWidget}>
                <SettingsIcon sx={{width:"12px"}} />
              </IconButton>
                <IconButton aria-label="delete" onClick={deleteWidget} >
                  <DeleteForeverRoundedIcon sx={{width:"12px"}}/>
                </IconButton></>} />

            <CardContent>
              <Typography variant="body2" color="text.secondary"></Typography>
              {widgetData["widget_" + id].graph_type === 'Table' ?
                <TableInventory tableData={widgetData["widget_" + id].tableData === undefined ? widgetData["widget_" + id].EventData : widgetData["widget_" + id].tableData} tbcols={widgetData["widget_" + id].tableCol} />
                :
                widgetData["widget_" + id].graph_type === 'Bar' ?
                  <BarChart bardata={widgetData["widget_" + id].monthly} />
                  :
                  widgetData["widget_" + id].graph_type === 'Line' ?
                    <LineChart bardata={widgetData["widget_" + id].daily} />
                    :
                    widgetData["widget_" + id].graph_type === 'Canvas' ?
                    <Markdown wid={wid} />
                      :
                    widgetData["widget_" + id].graph_type === 'DynamicCanvas' ?

                      <CanvasImagePlacement wid={wid}/>
                      :widgetData["widget_" + id].graph_type === 'Markdown' ?

                      <MyComponent wid={wid}/>
                      :widgetData["widget_" + id].graph_type === 'SVG' ?

                      <WebSocketExample wid={wid}/>
                      :
              null 
              
              }

            </CardContent>
          </CardWrapper>
            <Notification
              notify={notify}
              setNotify={setNotify} /></>
        :null
      //   <Backdrop
      //   sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      //   open
      // >
      //   <CircularProgress color="inherit" />
      // </Backdrop>
    }
    </>
  );
}
