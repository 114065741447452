import React from 'react'
// import * as settings from '../settings';
import {  Backdrop, CircularProgress,Paper, TableBody, TableCell, TableRow, Button, Stack, Grid, Typography, Card  } from '@mui/material';
import { Link } from 'react-router-dom';
import * as assetServices from '../../../services/assetServices';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import useTable from "../../ui/useTable";
import TableContainer from '@mui/material/TableContainer';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DoneIcon from '@mui/icons-material/Done';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';


import { styled } from "@mui/material/styles";
const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));
// ########################################################
// The main Home component returned by this Module
// ########################################################

const headCells = [
    { id: 'asset_id', label: 'No' },
    { id: 'asset_name', label: 'Asset' },
    { id: 'location', label: 'Store' },
    { id: 'asset_ip', label: 'IP' },
    { id: 'status', label: 'Status' },
    { id: 'shelf', label: 'Shelf' },
    { id: 'SerailNo', label: 'Serial No' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]
function Assets(props) {
    // Material UI Classes 
    let isbutton = false
    const role = props.auth.user_role

    const [asset, setAsset] = React.useState(null)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    React.useEffect(() => {

        assetServices.getAssets(setAsset)
        
    }, []);
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(asset, headCells, filterFn);
    if (role === 'admin') {
        isbutton = true
    }
    return (<>
    
        {/* <SubHeader title='Assets' isButton={isbutton} btnUrl="/squirrel/assets/add-asset" btnTitle="Add Asset"/>
         */}
        <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Assets
            </Typography>
            {role === "admin" ? (
                          <Button component={Link} to="/squirrel/assets/add-asset" variant="contained" color="secondary">
                Add
              </Button>
            ) : null}
          </Grid>
            </StyledCard>
            </Stack>
            <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
        {
            asset ?
                <Paper>
        <TableContainer>

                    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((item,index) =>
                            (<TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.asset_name}</TableCell>
                                <TableCell>{item.location}</TableCell>
                                <TableCell>{item.asset_ip}</TableCell>
                                <TableCell>{item.asset_status === false? <ReportGmailerrorredIcon color="error">Down</ReportGmailerrorredIcon>:<DoneIcon color="success">Up</DoneIcon>}</TableCell>
                                {/* <TableCell>{item.asset_status + ' '+ typeof(item.asset_status)}</TableCell> */}
                                <TableCell>Shelf {item.shelf ? parseInt(item.shelf)+1: 0 }</TableCell>
                                <TableCell>{item.serial_no? 'NA':item.serial_no}</TableCell>
                                <TableCell> 
                                    {
                                        role === 'admin'?
                                        <><Button
                                                color="primary"
                                                component={Link}
                                                to={"/squirrel/assets/edit-asset/" + item.asset_id}
                                            >
                                                <ModeEditOutlineOutlinedIcon fontSize="small" />
                                            </Button><Button
                                                color="secondary"
                                                component={Link}
                                                to={"/squirrel/assets/delete-asset/" + item.asset_id + "/" + item.asset_name}
                                            >
                                                    <CloseIcon fontSize="small" />
                                                </Button></>
                                            : null
                                    }
                                   
                                </TableCell>
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </TblContainer>
                        <TblPagination />
            </TableContainer>
                </Paper>
                :null
            
            //     <Backdrop
            //     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            //     open
            //   >
            //     <CircularProgress color="inherit" />
            //   </Backdrop>
            }
            </StyledCard>
            </Stack>
        </>
    )
}

export default Assets