import React from 'react'
import TextField from '@mui/material/TextField';

export default function InputTextArea(props) {

    const { name, label, required, value, error = null, onChange, maxrows=4 } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            required={required}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            multiline
            // maxRows={maxrows}
            minRows={maxrows}
            sx={{width:"100% !important"}}
            {...(error && {error:true,helperText:error})}
        />
    )
}