import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Fab,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography, 
} from "@mui/material";
import React, { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from "react-router-dom";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import * as orgServices from '../../../services/organizationServices'
// import { styled } from "@mui/material/styles";
import Dimage from "../../../assets/images/defaulteh.jpg"
import ReactCardFlip from 'react-card-flip';


export default function Organization(props) {
  const role = props.auth.user_role;
    const [data, setData] = React.useState(null)
    const [orgz, setOrgz] = React.useState(null)



    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

  
    React.useEffect(() => {
      orgServices.getOrganizations(setData)
      setOrgz(data)
  }, [data]);
    // if (orgz === null || orgz.length === 0) {
    //     if (data !== null) {
    //         setOrgz(data)
    //     }
        
    // } 


    const [isFlippedArray, setIsFlippedArray] = useState(new Array(data).fill(false));

    const handleMouseEnter = (index) => {
      const newIsFlippedArray = [...isFlippedArray];
      const currentIsFlipped = newIsFlippedArray[index];
      newIsFlippedArray.fill(false); // flip all cards back to normal position
      newIsFlippedArray[index] = !currentIsFlipped; // flip the clicked card
      setIsFlippedArray(newIsFlippedArray);
  };

  const handleMouseLeave = () => {
    setIsFlippedArray(new Array(data).fill(false)); // flip all cards back to normal position
  };


    
  return (
    <>
      <Stack m={3} gap={3}>
      <Card sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Organization
            </Typography>
            {role === "admin" ? (
                          <Button component={Link} to="/squirrel/organizations/add-new-organization" variant="contained" color="secondary">
                Add
              </Button>
            ) : null}
          </Grid>
              </Card>
              <Card sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
                      {
                          data ?
                          <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={data}
                          size="small"
                          sx={{ marginBottom: 3 }}
                          onChange={(event, newValue) => {
                            setOrgz(newValue)
                          }}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.organization}
                          renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected} />
                                  {option.organization}
                              </li>
                          )}
                          style={{ width: 500 }}
                          renderInput={(params) => (
                              <TextField {...params} label="Organization" placeholder="DE00...." />
                          )} />
                              : "No data"
              } 
          </Grid>
        </Card>
        <Grid container spacing={3}>
                {
                    data?
                        data.map((org, index) => (
                            <Grid item xs={12} md={3} key={org.organization_id}>
                                <ReactCardFlip isFlipped={isFlippedArray[index]} flipDirection="horizontal">
                                <div key="front" onMouseEnter={() => handleMouseEnter(index)}>
                                <Card sx={{padding: 1, maxWidth: '100%',height: 490 }} className="org_card" key={index}>
                                    <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#1ebf6db8',color:'#ffffff' }} aria-label="organization">
                                            {org.organization.charAt(0).toUpperCase()}
                                        </Avatar>
                                    }
                                    // title={org.organization}
                                    //   subheader="September 14, 2016"
                                    />

                                    <Box sx={{paddingLeft:2,paddingRight:2}}>
                                    <CardMedia
                                    className='wsk-cp-img'
                                    component="img"
                                    height="294"
                                    image={org.logo || Dimage}
                                    alt={org.organization}
                                    />

                                    </Box>
                                    {console.log(org.logo,"check mediaimageeeeee")}
                                    
                                    <Card sx={{
                                      position: 'absolute',
                                      marginTop:2,
                                      left: 0,
                                      right: 0,
                                      top: '90%',
                                      transform: 'translateY(-50%)',
                                      textAlign: 'center',
                                      backgroundColor:'#212121',
                                      }}>

                                    <CardHeader
                                    
                                    title={org.organization}>
                                    
                                    </CardHeader>
                                    </Card>

                                    
                                        
                                        

                                       



                                    {/* <CardActions sx={{justifyContent:"center"}}>                               
                                        
                                        
                                        
                                    </CardActions> */}
                                </Card>
                                </div>
                                <div key="back" onMouseLeave={handleMouseLeave}>
                            
                                <Card sx={{ padding: 1, maxWidth: '100%',height: 490 }} className="org_card" key={index}>
                                    <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#1ebf6db8',color:'#ffffff' }} aria-label="organization">
                                            {org.organization.charAt(0).toUpperCase()}
                                        </Avatar>
                                    }
                                    title={org.organization}
                                    //   subheader="September 14, 2016"
                                    />
                                    
                                   
                                    <Card sx={{maxHeight:"70%"}}>
                                    <CardContent sx={{padding:0}}>
                                    <Typography variant="h2" className="titleEh" sx={{ marginBottom: 2, marginLeft:3 }}>
                                        {org.description}
                                        </Typography>
                                        </CardContent>
                                        </Card>


                                        <Box sx={{
                                     position: 'absolute',
                                     left: 0,
                                     right: 0,
                                     top: '90%',
                                     transform: 'translateY(-50%)',
                                     textAlign: 'center',
                                      
                                     
                                      }}>

                                    <CardContent>
                                        <Fab size="small" color="default" sx={{ marginTop: -2, marginBottom: 1,marginLeft:1 }} aria-label="add" className="view-iconU">
                                            <Tooltip title='view'>
                                            <IconButton aria-label="view" component={Link} to={'/squirrel/organizations/view-organization/'+org.organization_id} >
                                            <VisibilityOutlinedIcon />
                                        </IconButton>
                                            </Tooltip>
                                        </Fab>
                                        {
                                            role === 'admin' ?
                                            <><Fab size="small" color="default" sx={{ marginTop: -2, marginBottom: 1,marginLeft:1 }} aria-label="add" className="edit-iconU">
                                                    <Tooltip title='edit'>
                                                        <IconButton aria-label="Edit" component={Link} to={'/squirrel/organizations/edit-organization/' + org.organization_id}>
                                                            <ModeEditOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </Fab><Fab size="small" color="default" sx={{ marginTop: -2, marginBottom: 1,marginLeft:1 }} aria-label="add" className="del-iconU">
                                                        <Tooltip title='delete'>
                                                            <IconButton aria-label="delete" component={Link} to={'/squirrel/organizations/delete-organization/' + org.organization_id + '/' + org.org_name}>
                                                                <DeleteOutlineOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </Fab></>
                                                :
                                                null
                                        }
                                       
                                    {/* <Typography variant="body2" color="text.secondary" sx={{ marginTop: 4 }}>
                                        {org.description}
                                    </Typography> */}
                                    </CardContent> 
                                    </Box>


                                        
                                        

                                        



                                    {/* <CardActions sx={{justifyContent:"center"}}>                               
                                        
                                        
                                        
                                    </CardActions> */}
                                </Card>

                                </div>
                                  </ReactCardFlip>


                            </Grid>
                            
                            
                        ))
                        :null
                      //   <Backdrop
                      //   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      //   open
                      // >
                      //   <CircularProgress color="inherit" /> 
                      // </Backdrop>
                }
            </Grid>
      </Stack>
    </>
  );
}
