import React from 'react';
import { Button, ButtonGroup } from '@mui/material';

function SubmitButton({ onSubmit }) {
  return (
    <Button type="submit" color="secondary" variant="contained" onClick={onSubmit}>
      Submit
    </Button>
  );
}

function ResetButton({ onReset }) {
  return (
    <Button color="info" variant="contained" onClick={onReset}>
      Reset
    </Button>
  );
}

function SqButtonGroup({ onSubmit, onReset }) {
  return (
    <ButtonGroup sx={{ float: 'right' }}>
          <ResetButton onReset={onReset} sx={{ marginRight: '2px' }} />
      <SubmitButton onSubmit={onSubmit} />
          
    </ButtonGroup>
  );
}

export default SqButtonGroup;
