import React from 'react';
import AuthLayout from './AuthLayout'

function LoginLayout(props) {
    return (
        <React.Fragment>
            <AuthLayout>
                {props.children}
            </AuthLayout>           
        </React.Fragment>
    )
}

export default LoginLayout