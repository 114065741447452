import { Button, Card, Grid, Stack, styled, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { Form } from '../../../useForm'
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import Notification from '../../../ui/Notifications'
import * as locationService from '../../../../services/locationServices'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));

function DeleteLocationForm(props) { 
    const isbutton = false
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const params = useParams()
    function deleteLocation() {
        locationService.deleteLocation(setNotify,params.id)
    }
    return (
        <>
                    <Stack m={3} gap={3}>
          <StyledCard hovershadow={10}>
            <Grid
              sx={{
                display: "flex",
                alignItems: "left",
                padding: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" color="inherit">
                Delete Store
              </Typography>
              <Button
                component={Link}
                to="/squirrel/stores"
                variant="contained"
                color="secondary"
              >
                <KeyboardBackspaceOutlinedIcon />
              </Button>
            </Grid>
          </StyledCard>
        </Stack>
            <Grid container>
                <Grid xs={12}>
                    <Form>
                        <Card sx={{padding:4}}>
                            <Typography variant="h3" gutterBottom component="div">
                                Are you sure?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                Are you sure you want to delete the selected Store? All of the objects and their related items will be deleted:
                                <br />
                                Store : {params.location}
                            </Typography>
                            {/* <Button type="submit">Delete</Button> */}
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deleteLocation}>
                                    Delete
                                </Button>
                            </Stack>
                        </Card>
                    </Form>
                    
                </Grid>
            </Grid>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />

        </>
    )
}

export default DeleteLocationForm