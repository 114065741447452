import React, { useState } from "react";
// import * as settings from '../settings';
import { Button, Card, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Controls from "../../../controls/Controls";
// import { useForm, Form } from '../../../UseForm'
import * as orgService from "../../../../services/organizationServices";
import Notification from "../../../ui/Notifications";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Form, useForm } from "../../../useForm";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow]
  },
}));

const initialFValues = {
  id: 0,
  organization_name: "",
  org_type: "-1",
  description: "",
  email: "",
  address: "",
  country: "-1",
  contactNo: "",
  website: "",
  serviceURL: "",
  logoURL: "", 
};

function AddOrganizationForm(props) {
  // Material UI Classes
  // console.log("org", props)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("organization_name" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
          : "Email is not valid.";
    console.log("field",fieldValues)
    if ("contactNo" in fieldValues)
      temp.contactNo =
        fieldValues.contactNo.length > 11 ? "" : "Minimum 11 numbers required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );
  // const isbutton = false;

  const handleSubmit = (e) => {
    e.preventDefault();
    orgService.createOrganization(values, setNotify);
    resetForm();
  };

  return (
    <>
      <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Add Organization
                      </Typography>
                      <Button component={Link} to="/squirrel/organizations" variant="contained" color="secondary">
                <KeyboardBackspaceOutlinedIcon />
              </Button>
          </Grid>
        </StyledCard>
      </Stack>
      <StyledCard hovershadow={10} sx={{padding:2}}>
        <Form onSubmit={handleSubmit}>
          <Accordion expanded={true}>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Controls.Input
                    name="organization_name"
                    label="Organization Name"
                    required={true}
                    value={values.organization_name}
                    onChange={handleInputChange}
                    error={errors.organization_name}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controls.Select
                    name="org_type"
                    label="Organization Type"
                    value={values.org_type}
                    onChange={handleInputChange}
                    options={orgService.getOrganizationsType()}
                    error={errors.org_type}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controls.Input
                    name="email"
                    label="Email"
                    required={true}
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controls.Select
                    name="country"
                    label="Country"
                    value={values.country}
                    onChange={handleInputChange}
                    options={orgService.getCountriesData()}
                    error={errors.country}
                    required={true}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Controls.InputTextArea
                    name="description"
                    label="Description"
                    required={true}
                    value={values.description}
                    onChange={handleInputChange}
                    error={errors.description}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputTextArea
                    name="address"
                    label="Address"
                    required={true}
                    value={values.address}
                    onChange={handleInputChange}
                    error={errors.address}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Controls.Input
                    name="contactNo"
                    label="Contact No."
                    required={true}
                    value={values.contactNo}
                    onChange={handleInputChange}
                    error={errors.contactNo}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controls.Input
                    name="website"
                    label="Website"
                    required={true}
                    value={values.website}
                    onChange={handleInputChange}
                    error={errors.website}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Controls.InputTextArea
                    name="serviceURL"
                    label="Service URL"
                    required={true}
                    value={values.serviceURL}
                    onChange={handleInputChange}
                    error={errors.serviceURL}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.InputTextArea
                    name="logoURL"
                    label="Logo URL"
                    required={true}
                    value={values.logoURL}
                    onChange={handleInputChange}
                    error={errors.logoURL}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={true}>
            <AccordionDetails style={{float:"right"}}>
              <Button type="submit" variant="contained" color="secondary">
                Create
              </Button>
              {/* <Button variant="contained" color="default" onClick={resetForm} >Reset</Button> */}
            </AccordionDetails>
          </Accordion>
        </Form>
        </StyledCard>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}



export default AddOrganizationForm;
