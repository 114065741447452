import React, {useState} from 'react'
// import * as settings from '../settings';
import { Avatar, Backdrop, Card, CardContent,CardActions, CardHeader,Paper,CardMedia,Box, Chip, CircularProgress, Divider, Stack, Typography,Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import * as incService from '../../../services/incidentServices'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm, Form } from '../../useForm'
import Notification from '../../ui/Notifications'
import Controls  from '../../controls/Controls';
import { styled } from "@mui/material/styles";
const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));

let initialFValues = {
    id : 0,
    // id : 'INC',
    title : '',
    description : '',
    category : '',
    severityType :'-1',
    incidentType : '-1',
    statusType : '-1',
    statusEvents : 'DEFAULT',     
    infoChannel : '',
    external_link : ''
}

export default function ViewIncident(props) { 
    const [incident, setIncident] = React.useState(null)
    const [incidentdata, setIncidentdata] = React.useState(null)
    const params = useParams()
    const [expanded, setExpanded] = React.useState('panel1');
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const [editflag, setEditflag] = React.useState(false)


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : 'panel1');
    };

    

    
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title = fieldValues.title ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    if (incidentdata !== null) {
        initialFValues = incidentdata
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    React.useEffect(() => {
 
        incService.getIncident(setIncident,params.id,setValues)
        
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(23,skucode)
        // catService.createCatalogue(values,setNotify,skucode) 
        incService.updateIncident(values, setNotify,params.id)
        // // resetForm()

    }
    const editStatus = (e) => {
        e.preventDefault();
        setEditflag(true)
    }
    const backView = (e) => {
        e.preventDefault();

        setEditflag(false)

        // window.location = `/squirrel/squirrel-incident/view-incident/${params.id}`

    }
    const isbutton = false

    
    function formatDate(date) {
        const year = date.getFullYear();
        const month = addLeadingZero(date.getMonth() + 1);
        const day = addLeadingZero(date.getDate());
        const hour = addLeadingZero(date.getHours());
        const minute = addLeadingZero(date.getMinutes());
        
        return `${year}-${month}-${day} ${hour}:${minute}`;
      }
      
      function addLeadingZero(number) {
        return number < 10 ? `0${number}` : number;
      }
    console.log(editflag)
    return (<>
        <Paper sx={{ padding: 2 }}>
        <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
             view Incident
            </Typography>
          </Grid>
        </StyledCard>
      </Stack>
            
            {
                editflag ?
                    values?
                            <><Form>
                            <Card key={'edit_card'}>
                                            
                                    <CardContent>

                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <Controls.Input
                                                    name="title"
                                                    label="Incident Title"
                                                    required={true}
                                                    value={values.title}
                                                    onChange={handleInputChange}
                                                    error={errors.title} />

                                            </Grid>

                                            <Grid item xs={12} md={3}>

                                                <Controls.Select
                                                    name="incidentType"
                                                    label="Incident Type"
                                                    value={values.incidentType}
                                                    onChange={handleInputChange}
                                                    options={incService.getIncidentType()}
                                                    error={errors.incidentType}
                                                    required={true} />

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Controls.Input
                                                    name="category"
                                                    label="Category"
                                                    required={true}
                                                    value={values.category}
                                                    onChange={handleInputChange}
                                                    error={errors.category} />

                                            </Grid>


                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <Controls.Select
                                                    name="severityType"
                                                    label="Severity"
                                                    required={true}
                                                    value={values.severityType}
                                                    options={incService.getSeverityType()}
                                                    onChange={handleInputChange}
                                                    error={errors.severityType} />

                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Controls.Select
                                                    name="statusType"
                                                    label="Status"
                                                    required={true}
                                                    value={values.statusType}
                                                    options={incService.getStatusType()}
                                                    onChange={handleInputChange}
                                                    error={errors.statusType} />

                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Controls.Input
                                                    name="infoChannel"
                                                    label="infoChannel"
                                                    required={true}
                                                    value={values.infoChannel}
                                                    onChange={handleInputChange}
                                                    error={errors.infoChannel} />

                                            </Grid>
                                            {
                            values.incidentType === 'EVENT' ? 
                                                <Grid item xs={12} md={3}>
                                                    <Controls.Select
                                                                        name="statusEvents"
                                                                        label="Event Flag"
                                                                        required={true}
                                                    value={values.statusEvents}
                                                    options={incService.getStatusEvents()}  
                                                                        onChange={handleInputChange}
                                                                        error={errors.statusEvents}                                        
                                                        />
                                                
                                </Grid>
                                :
                                null
                        }
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Controls.InputTextArea
                                                    name="description"
                                                    label="Description"
                                                    required={true}
                                                    value={values.description}
                                                    onChange={handleInputChange}
                                                    error={errors.description} />

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Controls.InputTextArea
                                                    name="external_link"
                                                    label="External Link"
                                                    required={false}
                                                    value={values.external_link}
                                                    onChange={handleInputChange}
                                                    error={errors.external_link} />

                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions style={{ justifyContent: 'right' }}>
                                        <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>Update Incident</Button>
                                        <Button type="button" variant="contained" color="secondary" onClick={resetForm}>Reset</Button>
                                        <Button type="button" variant="contained" color="info" onClick={backView}>View</Button>
                                    </CardActions>
                                </Card>
                                </Form>
                            <Notification
                            notify={notify}
                                setNotify={setNotify} /></>
                        :
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    :
                    incident ?
                        incident.map((inc, index) => {
                            return <>
                                
                                <Card sx={{ padding: 2, border: 1 }} key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item xs='12' md='8'>
                                            {/* <CardHeader sx={{padding:2, marginBottom:3}} title={"INCIDENT NO : " + inc.id} /> */}
                                            <Typography variant="h3" gutterBottom component="div" sx={{ marginBottom: 3 }}>
                                                INCIDENT NO: {inc.id}
                                            </Typography>
                                            <Divider sx={{ marginBottom: 3 }}></Divider>

                                            <Typography variant="h4" gutterBottom component="div" sx={{ marginBottom: 3 }}>
                                                {inc.id}: {inc.title}
                                            </Typography>
                                            <Typography variant="h5" gutterBottom component="div" sx={{ marginBottom: 3 }}>
                                                Category : {inc.category}
                                            </Typography>
                                            <Typography variant="h5" gutterBottom component="div" sx={{ marginBottom: 3 }}>
                                                {inc.description}
                                            </Typography>
                                            
                                        </Grid>
                                        <Grid item xs='12' md='4'>
                                            <Button variant="outlined" color={incService.getStatusColor(inc.statusType)} style={{marginBottom:2}}>
                                                {inc.statusType}
                                            </Button>
                                            <Button variant="outlined" color='secondary' onClick={editStatus} style={{marginBottom:2,marginLeft:2}}>
                                                Edit
                                            </Button> 
                                            
                                            <Box sx={{ border: 1, padding: 2, borderColor: '#8080803b' }}>
                                                <table key={"incident_table"} sx={{padding:2}}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{ width: 150 }}>
                                                        Type
                                                        </td>
                                                        <td>
                                                                <Typography variant="h5" gutterBottom component="div" >
                                                                : {inc.incidentType}
                                                                </Typography>
                                                        </td>
                                                    </tr> 
                                                    <tr>
                                                        <td style={{ width: 150 }}>

                                                            Status
                                                        </td>
                                                        <td>
                                                            <Typography variant="h5" gutterBottom component="div" >
                                                                : {inc.statusType}
                                                            </Typography>
                                                        </td>
                                                    </tr>  
                                                    {
                                                        inc.incidentType === 'EVENT' ?
                                                            <tr>
                                                                <td style={{ width: 150 }}>

                                                                Event Status
                                                                </td>
                                                                <td>
                                                                    <Typography variant="h5" gutterBottom component="div">
                                                                        : {inc.statusEvents}
                                                                        </Typography>
                                                                </td>
                                                            </tr>   
                                                    
                                                        :
                                                        null
                                                    }
                                                     <tr>
                                                        <td style={{ width: 150 }}>
                                                            Severity
                                                        </td>
                                                        <td>
                                                            <Typography variant="h5" gutterBottom component="div" color={incService.getStatusColor(inc.severityType)}>
                                                                : {inc.severityType}
                                                            </Typography>
                                                        </td>
                                                    </tr> 
                                                    <tr>
                                                        <td style={{ width: 150 }}>
                                                            Channel
                                                        </td>
                                                        <td>
                                                            <Typography variant="h5" gutterBottom component="div" >
                                                                : {inc.infoChannel}
                                                            </Typography>
                                                        </td>
                                                    </tr>  
                                                    <tr>
                                                        <td style={{ width: 150 }}>
                                                            External Links
                                                        </td>
                                                        <td>
                                                            <Typography variant="h5" gutterBottom component="div" >
                                                                : {inc.external_link}
                                                            </Typography>
                                                        </td>
                                                        </tr>   
                                                        <tr>
                                                        <td style={{ width: 150 }}>
                                                            Created at
                                                        </td>
                                                        <td>
                                                            <Typography variant="h5" gutterBottom component="div" >
                                                                : {formatDate(new Date(inc.created_at))}
                                                            </Typography>
                                                        </td>
                                                        </tr>  
                                                        </tbody>
                                                </table>
                                                
                                                
                                                
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                                  
                            </>
                        })
                        :
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                  
            }
        </Paper>
        
    
                  
    </>)
}