import React, { useState } from 'react';
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardMedia,
  CardContent,
  Badge,
  Fab,
  Button,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Stack,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import * as locationService from "../../../services/locationServices";
import StoreIcon from "@mui/icons-material/Store";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KitchenIcon from "@mui/icons-material/Kitchen";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled } from "@mui/material/styles";
import Dimage from "../../../assets/images/defaulteh.jpg"
import ReactCardFlip from 'react-card-flip';
// import InventoryFlow from './views/inventory_view_1';

// ########################################################
// The main Home component returned by this Module
// ########################################################
const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow],
  },
}));

const MyCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  '&:hover .cardOverlay': {
    transform: 'translateY(0)',
  },
  '&:hover .cardHeader': {
    transform: 'translateY(-100%)',
  },
}));

const CardOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  transform: 'translateY(100%)',
  transition: '0.2s ease-in-out',
}));

const CardHeaderWrapper = styled(CardHeader)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: `${theme.spacing(1)} 0 0 0`,
  backgroundColor: theme.palette.background.paper,
  transform: 'translateY(-100%)',
  transition: '0.2s ease-in-out',
}));

const CardArc = styled('div')(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  position: 'absolute',
  bottom: '100%',
  right: 0,
  zIndex: 1,
}));

function Locations(props) {
  // Material UI Classes
  const [location, setLocation] = React.useState(null);
  const [stores, setStores] = React.useState([]);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const role = props.auth.user_role;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    
    React.useEffect(() => {

        locationService.getLocations(setLocation,setStores)
        
    }, []);

    const defaultimage = Dimage

    const [isFlippedArray, setIsFlippedArray] = useState(new Array(stores.length).fill(false));

    const handleMouseEnter = (index) => {
      const newIsFlippedArray = [...isFlippedArray];
      const currentIsFlipped = newIsFlippedArray[index];
      newIsFlippedArray.fill(false); // flip all cards back to normal position
      newIsFlippedArray[index] = !currentIsFlipped; // flip the clicked card
      setIsFlippedArray(newIsFlippedArray);
  };

  const handleMouseLeave = () => {
    setIsFlippedArray(new Array(stores.length).fill(false)); // flip all cards back to normal position
  };

  


   
  return (
    <>
      <Stack m={3} gap={3}>
      <Card sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Stores
            </Typography>
            {role === "admin" ? (
              <Button
                component={Link}
                to="/squirrel/stores/add-store"
                variant="contained"
                color="secondary"
              >
                Add
              </Button>
            ) : null}
          </Grid>
        </Card>
          <Card sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            {location ? (
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={location}
                size="small"
                sx={{ marginBottom: 3 }}
                onChange={(event, newValue) => {
                  console.log(newValue)
                  if (newValue.length !== 0) {
                    setStores(newValue);
                  }
                  else {
                    setStores(location)
                  }
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.site_code}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.site_code}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stores"
                    placeholder="DE00...."
                  />
                )}
              />
            ) : (
              "No data"
            )}
          </Grid>
          </Card>
             
              <Grid container spacing={3}>
                {   
                    
                    stores ?
                        stores.map((loc, index) => (
                          <Grid item xs={12} md={3} key={loc.location_id}>
                            <div className='image-container'>
                            <Card className='card-action'> 
                              <img src={loc.pictureURL} className='store-image' style={{ width: '100%', height: '220px', objectFit: 'cover' }} onError={(e) => {
    e.target.src = Dimage; // Set a default image URL or fallback image source
  }}/>
                              <div className='card-overlay'>
                                <CardContent>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
  <Fab size="small" color="default" sx={{ marginBottom: 1, backgroundColor: 'rgb(224 224 224 / 20%)' }} aria-label="add">
    {loc.location_category === 'Shop' ? (
      <Tooltip title={loc.location_category}>
        <StoreIcon />
      </Tooltip>
    ) : (
      <Tooltip title={loc.location_category}>
        <KitchenIcon />
      </Tooltip>
    )}
  </Fab>
  <span style={{ marginLeft: '0.5em',fontWeight: "bolder", fontSize: "larger" }}>{loc.site_code}</span>
</div>


                                <Typography variant="h2" className="titleEh" >
                                  {loc.location_name} 
                                  </Typography>
                                  
                                    {/* <Typography variant="h4" className='descriptions'  sx={{ marginTop: 0}}>
                                    {loc.description}
                                    </Typography> */}
                                  <Box sx={{
                                     position: 'absolute',
                                     left: 0,
                                    right: 0,   
                                     top:"60%",
                                     textAlign: 'center',
                                                                           
                                      }}>
                                  <Fab size="small" color="default" sx={{  marginBottom: 1,marginLeft:1 }} aria-label="add" className='view-iconU'  >
                                        <Tooltip title='view'>
                                            <IconButton aria-label="view" component={Link} to={'/squirrel/stores/view-store/' + loc.location_id} >
                                                <VisibilityOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Fab>
                                    {
                                        role === 'admin' ?
                                    <>
                                    
                                    <Fab size="small" color="default" sx={{  marginBottom: 1,marginLeft:1 }} aria-label="add" className='edit-iconU' >
                                                <Tooltip title='edit'>
                                                    <IconButton aria-label="Edit" component={Link} to={'/squirrel/stores/edit-store/' + loc.location_id}>
                                                        <ModeEditOutlineOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
 
                                            </Fab><Fab size="small" color="default" sx={{  marginBottom: 1,marginLeft:1 }} aria-label="add" className='del-iconU'  >
                                                    <Tooltip title='delete'>
                                                        <IconButton aria-label="delete" component={Link} to={'/squirrel/stores/delete-store/' + loc.location_id + '/' + loc.location_name + ',' + loc.site_code}>
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </Fab> </>
                                                
                                                : null
                                        }
                                        </Box>
                                </CardContent>
                              </div>
                            {/* hi */}
                              </Card>    
                              </div>  
                            </Grid>
                        ))
                        :
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                }
            </Grid>
      </Stack>
    </>
  );
}

export default Locations;
