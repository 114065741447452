import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Card, Toolbar } from '@mui/material';
import { styled } from "@mui/material/styles";


const replaceSVGPlaceholder = (svgCode, replacements) => {
  let result = svgCode;

  for (let i = 1; i <= Object.keys(replacements).length; i++) {
    result = result.replace(`%${i}%`, replacements[i]);
  }
  


  return result;
};


const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
    ":hover": {
      boxShadow: theme.shadows[hovershadow],
    },
  }));


export default function SVGComponent(props) {
  const [svgCode, setSvgCode] = useState('');

const {wid}=props

//   useEffect(() => {
//     fetch(svg1)
//       .then((response) => response.text())
//       .then((data) => {
//         const replacedCode = replaceSVGPlaceholder(data, { 1: 'Warning', 2: 'in progress', 3: 'Done' });
//         setSvgCode(replacedCode);
//       });
//   }, []);

useEffect(() => {
    const replacedCode = replaceSVGPlaceholder(wid.query, { 1: 'Loading', 2: 'Loading', 3: 'loading' });
    setSvgCode(replacedCode);
}, [wid]
)

        
         
  return (
    
     <StyledCard hovershadow={10}>
      
           
        <Box sx={{marginLeft:1}} dangerouslySetInnerHTML={{ __html: svgCode }} />

        </StyledCard>

    //    <div> {wid.query} </div>
   
   
  );
}






