import Chart from "react-apexcharts"


function BarChart(props) {
    const { bardata } = props
    
    if (bardata !== undefined) {
        const categories = bardata['date']
        let series = []
        Object.entries(bardata).map((key, value) => {                               
            if (key[0] !== 'date') {
                series.push({'name':key[0],'data':key[1]})
            }
        })
        const chart1={
            options : {
                 chart: {
                     zoom: {
                         enabled: true,
                         autoScaleYaxis: true
                     },
                     toolbar: {
                         show: true
                    },
                    stacked: true
                 },
                colors: ['#f5f5dc',
                '#9c2542',
                '#aa381e',
                '#c32148',
                '#e68fac',
                '#ff0800',
                '#78866b',
                '#bd33a4',
                '#004225',
                '#21abcd',
                '#b31b1b',
                '#536872',
                '#6e7f80',
                '#5f9ea0',
                '#592720',
                '#91a3b0',
                '#f4c2c2',
                '#915c83',
                '#1f75fe',
                '#cd5c5c',
                '#fff600',
                '#0093af',
                '#b2beb5',
                '#a67b5b',
                '#fb607f',
                '#a4c639',
                '#e7feff',
                '#dfff00',
                '#e32636',
                '#de5d83',
                '#3b444b',
                '#f2f3f4',
                '#e3dac9',
                '#7b3f00',
                '#e97451',
                '#d2691e',
                '#007ba7',
                '#ffe4c4',
                '#1e4d2b',
                '#e52b50',
                '#a0785a',
                '#800020',
                '#de3163',
                '#e03c31',
                '#ffe135',
                '#e9d66b',
                '#ed872d',
                '#062a78',
                '#ffa6c9',
                '#007fff',
                '#3d0c02',
                '#b2ffff',
                '#2a52be',
                '#318ce7',
                '#ff55a3',
                '#bf94e4',
                '#cd9575',
                '#fad6a5',
                '#de6fa1',
                '#873260',
                '#848482',
                '#00bfff',
                '#72a0c1',
                '#0095b6',
                '#8a3324',
                '#8a2be2',
                '#fe6f5e',
                '#ffef00',
                '#7fff00',
                '#a3c1ad',
                '#c46210',
                '#3b3c36',
                '#ffc1cc',
                '#c41e3a',
                '#7c0a02',
                '#006a4e',
                '#99badd',
                '#ffa700',
                '#008000',
                '#fae7b5',
                '#ff007f',
                '#1dacd6',
                '#e30022',
                '#eb4c42',
                '#a8516e',
                '#efbbcc',
                '#9f8170',
                '#ffb7c5',
                '#6d9bc3',
                '#964b00',
                '#0247fe',
                '#960018',
                '#cd7f32',
                '#fbceb1',
                '#0d98ba',
                '#a2a2d0',
                '#5d8aa8',
                '#007aa5',
                '#e4717a',
                '#ed9121',
                '#480607',
                '#2f847c',
                '#f0dc82',
                '#fdee00',
                '#841b2d',
                '#ff033e',
                '#de3163',
                '#bcd4e6',
                '#d19fe8',
                '#4b3621',
                '#e34234',
                '#7fffd4',
                '#0070ff',
                '#006b3c',
                '#08e8de',
                '#702963',
                '#d70040',
                '#ff0038',
                '#126180',
                '#a52a2a',
                '#f4bbff',
                '#b5a642',
                '#f0f8ff',
                '#cb4154',
                '#00308f',
                '#87a96b',
                '#36454f',
                '#a1caf1',
                '#568203',
                '#ff7e00',
                '#98777b',
                '#4997d0',
                '#92a1cf',
                '#89cff0',
                '#faebd7',
                '#ffbf00',
                '#3d2b1f',
                '#d2691e',
                '#efdecd',
                '#a52a2a',
                '#8db600',
                '#a32638',
                '#ace5ee',
                '#007ba7',
                '#deb887',
                '#79443b',
                '#ace1af',
                '#ffebcd',
                '#253529',
                '#f0ffff',
                '#ec3b83',
                '#faf0be',
                '#a57164',
                '#98817b',
                '#0087bd',
                '#bf4f51',
                '#4b5320',
                '#c19a6b'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '28%',
                        endingShape: 'rounded',
                        borderRadius: 5,
                    },
                    },
                    legend: {
                    show: true
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                    },
                xaxis: {
                categories: categories,
                labels: {
                    style: {
                    colors: "#8A92A6",
                    },
                }
                },
                yaxis: {
                title: {
                    text: ''
                },
                labels: {
                    minWidth: 19,
                    maxWidth: 19,
                    style: {
                        colors: "#8A92A6",
                    },
                }
                },
                fill: {
                opacity: 1
                },
                tooltip: {
                y: {
                    formatter: function (val) {
                    return val 
                    }
                }
                }
            },
            series: series
         }
        return (<>
        <Chart className="d-activity" options={chart1.options} series={chart1.series} type="bar"   height="380"  />
            
            
        </>)
    }
    else {
        return (<>
            No Data Found
        </>)
    }

    
}
export default BarChart