import { Box, Card, Fab, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Controls from "../../controls/Controls";
import { styled } from "@mui/material/styles";
import Notification from "../../ui/Notifications";
import saly from "../../../assets/img/Saly-38.svg";
import Popup from "../../ui/Popup";
import WidgetForm from "./forms/widgetForm";
import * as dsService from "../../../services/dashboardServices";
import WidgetCustom from "./forms/WidgetCustom";

const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow],
  },
  boxShadow: theme.shadows[3],

}));
export default function Dashboard(props) {
  const role = props.auth.user_role;
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForDelete, setRecordForDelete] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const createWidget = () => {
    setOpenPopup(true);
    setRecordForEdit(null);
  };
  const addOrEdit = (wid, resetForm) => {
    if (wid.id == 0) {
      dsService.createWidget(wid, setNotify);
    } else {
      dsService.updateCustomWidget(wid, setNotify);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  return (
    <>
      <Stack sx={{marginBottom:2}} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="h3" color="inherit">
                  Welcome to Your Dashboard
                  <img
                    src={saly}
                    alt="bg"
                    style={{
                      width: "36px",
                      position: "absolute",
                      marginLeft: "-4px",
                      marginTop: "-5px",
                      zIndex: "1",
                      transform: "scaleX(-1)",
                    }}
                  ></img>
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Here you can view your Squirrel system and track progress.
                </Typography>
              </Box>
            </Grid>

            {role === "admin" ? (
              <Grid item xs={12}>
                <Box sx={{ float: "right" }}>
                  <Controls.Button
                    text="Create Widget"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={createWidget}
                  />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </StyledCard>

      </Stack>
      <Grid container spacing={1}>
            <WidgetCustom setRecordForEdit={setRecordForEdit} setOpenPopup={setOpenPopup}/>
        </Grid>
      <Popup
        title="Widget Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <WidgetForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          auth={props.auth}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
