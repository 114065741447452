import React, { useState, useEffect } from "react";
import WebSocket from "isomorphic-ws";
import { Box } from '@mui/system';
import { Card, Toolbar } from '@mui/material';
import { styled } from "@mui/material/styles";


function isObjectEmpty(obj) { return Object.getOwnPropertyNames(obj).length === 0; }


const replaceSVGPlaceholder = (svgCode, replacements ) => {

    // const isObjectEmpty = (replacements) => {
    //     console.log(Object.keys(replacements).length === 0 , Object.keys(replacements).length, "check objecttttttttttttttttttttt")
    //     return Object.keys(replacements).length === 0 
        
    // }

    const isobject = isObjectEmpty(replacements)
    
    let result = svgCode;

    console.log(isobject,"check isobject")
    if (isobject) {
      
        result = result.replace(/%([^%]+)%/g, (match, content) => {
            // Process the content inside % % as needed
            return  "0" 
          });
          
          
    }
    else{

        for (const i in replacements) {
            result = result.replace(`%${i}%`, String(replacements[i]));
            console.log(replacements[i],i)
          }

          result = result.replace(/%([^%]+)%/g, (match, content) => {
            // Process the content inside % % as needed
            return  "0" 
          });

    }

console.log(replacements,"check replacementssss")
console.log(typeof replacements , "check type of replacement")

// for (const i in replacements) {
//   result = result.replace(`%${i}%`, isObjectEmpty?"0": String(replacements[i]));
//   console.log(replacements[i],i)
// }
return result;
};



const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow],
  },
}));

const WebSocketExample = (props) => {
  const [svgCode, setSvgCode] = useState('');
  const [message, setMessage] = useState(null);
  const { wid } = props;

  useEffect(() => {
    const socket = new WebSocket("wss://websocket.squirrelvision.ai");
    socket.onopen = () => {
      console.log("WebSocket connection established");
    };
    socket.onmessage = (event) => {
      let data = event.data;

      
if (typeof data === 'string') {
    data = JSON.parse(data)
    console.log('data is a string');
  } else if (typeof data === 'object') {
    data = data
    console.log('data is an object');
  } else {
    console.log('data is neither a string nor an object');
  }


      console.log(typeof message , "check type")
      setMessage(data);
    };
    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

 

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (message) {
      const replacedCode = replaceSVGPlaceholder(wid.query, message);
      setSvgCode(replacedCode);
    }
  }, [message, wid.query]);

  return (
    <div>
      {svgCode && (
        <div>
          <StyledCard hovershadow={10}>
            <Box sx={{marginLeft:1}} dangerouslySetInnerHTML={{ __html: svgCode }} />
          </StyledCard>
        </div>
      )}
    </div>
  );
};

export default WebSocketExample;
