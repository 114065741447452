import { Backdrop, Button, Card, CircularProgress, Grid, Paper, Stack, TableBody, TableCell, TableRow, Toolbar, Typography } from "@mui/material"
import Controls from '../../../controls/Controls';
import AddIcon from '@mui/icons-material/Add';
import Popup from '../../../ui/Popup';
import useTable from "../../../ui/useTable";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as adminServices from '../../../../services/sqadmin'
import CategoryForm from "./CategoryForm";
import Notification from "../../../ui/Notifications";
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Form } from "../../../useForm";
const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow]
  },
}));



const headCells = [
    { id: 'firstname', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'start_date', label: 'Start Date' },
    { id: 'end_date', label: 'End Date' },
    { id: 'organization', label: 'Organization' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]
function Contract(props) {
    const isbutton = false
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [recordForDelete, setRecordForDelete] = useState(null)
    const [openPopup, setOpenPopup] = useState(false)
    const [records, setRecords] = useState([])
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [isDelete,setIsDelete] = useState(false)
    const [organization, setOrganization] = useState(null)
    
    // const getAllCategories= () => {
    //     getAllCategories(setRecords)
    // }
    useEffect(() => { 
        adminServices.getContract(setRecords)
    }, [])
    useEffect(() => { 
        adminServices.getOrganization(setOrganization)
    }, [])

    const addNewCategory = () => {
        // console.log("check")
        setOpenPopup(true);
        setRecordForEdit(null); 
    }
    const editCategory = (item) => {
        // console.log("check")
        setRecordForEdit(item)
        setOpenPopup(true);
    }
    const deleteCategory= (item) => {
        // console.log("check")
        setRecordForDelete(item)
        setIsDelete(true);
        
    }
    const addOrEdit = (category, resetForm) => {
        if (category.id == 0) {
            adminServices.createContract(category, setNotify)
            adminServices.getAllContract(setRecords)
        }        
        else {
            adminServices.updateContract(category, setNotify)
            adminServices.getAllContract(setRecords)
        }
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
    }
    const deletOnCategory = () => {
        adminServices.deleteContract(recordForDelete, setNotify)        
        adminServices.getAllContract(setRecords)
        setRecordForDelete(false)
        setIsDelete(false)
     }
    
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);
    return (
        <>
            <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Contract
                      </Typography>
                     
          </Grid>
        </StyledCard>
      </Stack>
            {
                records ?
            <Paper>
                <Toolbar>
                    <Controls.Button
                        text="Add New"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={addNewCategory}
                    />
                        </Toolbar>
        <TableContainer sx={{ maxHeight: 440 }}>
                        
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((item,index) =>
                            (<TableRow key={index}>
                                <TableCell>{item.firstname}</TableCell>
                                <TableCell>{item.organization}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{item.start_date}</TableCell>
                                <TableCell>{item.end_date}</TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color="primary"
                                        onClick={() => { editCategory(item) }}>
                                        <EditOutlinedIcon fontSize="small" />
                                    </Controls.ActionButton>
                                    <Controls.ActionButton
                                        color="secondary"
                                        onClick={() => { deleteCategory(item) }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </TblContainer>
                            <TblPagination />
                            
                </TableContainer>
                            
            </Paper>
                     :
                     <Backdrop
                         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                         open
                       >
                         <CircularProgress color="inherit" />
                       </Backdrop>
             }
             
            <Popup
                title="Contract Details"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
               
                    <CategoryForm
                        recordForEdit={recordForEdit}
                        addOrEdit={addOrEdit}
                        organization={organization}
                    />
                
            </Popup>
            <Popup
                title="Delete Data"
                openPopup={isDelete}
                setOpenPopup={setIsDelete}
            >
                
                        <Form>
                            <Card sx={{padding:4}}>
                                <Typography variant="h3" gutterBottom component="div">
                                    Are you sure?
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Are you sure you want to delete the selected data?
                                    All of the objects and their related items will be deleted!!!                                    
                                </Typography>
                                {/* <Button type="submit">Delete</Button> */}
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deletOnCategory}>
                                        Delete
                                    </Button>
                                </Stack>
                            </Card>
                        </Form>
                        
            
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
}

export default Contract