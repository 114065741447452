import { Button, Card, Grid, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import { useForm, Form } from '../../../useForm'
import * as catService from '../../../../services/catalogueServices'
import Notification from '../../../ui/Notifications';
const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));

function DeleteCatalogueForm(props) { 
    const isbutton = false
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const params = useParams()
    function deleteCatalogue() {
        catService.deleteCatalogue(setNotify,params.id)
    }
    return (
        <>
            <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Delete
            </Typography>
                          <Button component={Link} to="/squirrel/catalogues" variant="contained" color="secondary">
                Back
              </Button>
          </Grid>
            </StyledCard>
            </Stack>
            <Grid container>
                <Grid xs={12}>
                    <Form>
                        <Card sx={{padding:4}}>
                            <Typography variant="h3" gutterBottom component="div">
                                Are you sure?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                Are you sure you want to delete the selected Catalogue? All of the objects and their related items will be deleted:
                                <br />
                                Catalogue : {params.catalogue}
                            </Typography>
                            {/* <Button type="submit">Delete</Button> */}
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deleteCatalogue}>
                                    Delete
                                </Button>
                            </Stack>
                        </Card>
                    </Form>
                    
                </Grid>
            </Grid>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />

        </>
    )
}

export default DeleteCatalogueForm