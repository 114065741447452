import React, { useState } from 'react'
import { Button, Card, CardActions, CardContent, Paper, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import * as catService from '../../../../services/catalogueServices'
import Notification from '../../../ui/Notifications';
import { styled } from "@mui/material/styles";
import { Link, useParams } from 'react-router-dom';
const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));

const initialFValues = {
    catalogue_id: 0,
    catalogue_name: '',
    display_name: '',
    ean: 0,
    sku_code: [],
    category:'-1',
    description: '',
    status: '-1',
    weburl: '',
    imageURL: '',
    iconlink:''  
}

function EditCatalogueForm(props) {
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const [category, setCategory] = useState([])
    const [skucode, setSkucode] = useState([])
    React.useEffect(() => {  
        catService.getCategory(setCategory)
    }, []);
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('category_name' in fieldValues)
            temp.category_name = fieldValues.category_name ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const isbutton = false
    const params = useParams();
    // //console.log("id =", params)
    React.useEffect(() => {
        catService.getCatalogue(setValues,params.id)      
      }, [params]);
    function handleSelecetedTags(items) { 
        setSkucode(items);
        //console.log(1230000,items)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(23, skucode)
        catService.updateCatalogue(values,setNotify,params.id,skucode) 
    }
    
    
    return (<>
        <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Edit
            </Typography>
                          <Button component={Link} to="/squirrel/catalogues" variant="contained" color="secondary">
                Back
              </Button>
          </Grid>
            </StyledCard>
            </Stack>
        <Form>
            <Card>
                <CardContent>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Controls.Input
                                                    name="catalogue_name"
                                                    label="Catalogue"
                                                    required={true}
                                                    value={values.catalogue_name}
                                                    onChange={handleInputChange}
                                                    error={errors.catalogue_name}                                        
                        />
                        
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controls.Input
                                                    name="display_name"
                                                    label="Display Name"
                                                    required={true}
                                                    value={values.display_name}
                                                    onChange={handleInputChange}
                                                    error={errors.display_name}                                        
                        />
                        
                    </Grid>
                    <Grid item xs={12}  md={3}>
                                        <Controls.Select
                                            name="category"
                                            label="Category"
                                            value={values.category}
                                            onChange={handleInputChange}
                                            options={category}
                                            error={errors.category}
                                            required={true}
                                        />
                        </Grid>
                        <Grid item xs={12}  md={3}>
                                        <Controls.Select
                                            name="status"
                                            label="Status"
                                            value={values.status}
                                            onChange={handleInputChange}
                                            options={catService.getSkuStatus()}
                                            error={errors.status}
                                            required={true}
                                        />
                        </Grid>
                    
                    
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                        <Controls.Input
                                                    name="ean"
                                                    label="EAN"
                                                    required={true}
                                                    value={values.ean}
                                                    onChange={handleInputChange}
                                                    error={errors.ean}                                        
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.TagsInput
                            selectedTags={handleSelecetedTags}
                            fullWidth
                            variant="outlined"
                            id="sku_code"
                            name="sku_code"
                            placeholder="add sku codes"
                            label="SKU Code"
                            tags={values.sku_code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.InputTextArea
                                                    name="imageURL"
                                                    label="Image URL"
                                                    required={true}
                                                    value={values.imageURL}
                                                    onChange={handleInputChange}
                                                    error={errors.imageURL}                                        
                                    />
                        </Grid>
                        <Grid item xs={12}>
                                    <Controls.InputTextArea
                                                    name="description"
                                                    label="Description"
                                                    required={true}
                                                    value={values.description}
                                                    onChange={handleInputChange}
                                                    error={errors.description}                                        
                                    />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.InputTextArea
                                                    name="iconlink"
                                                    label="Icon"
                                                    required={true}
                                                    value={values.iconlink}
                                                    onChange={handleInputChange}
                                                    error={errors.iconlink}                                        
                                    />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{float:'right'}}>
                    <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>Update Catalogue</Button>
                    
                </CardActions>
            </Card>
        </Form>
        <Notification
                notify={notify}
                setNotify={setNotify}
            />
    </>)
}

export default EditCatalogueForm