import axios from 'axios';
import * as api from '../config/apiConf'

export function getAssets(setAsset) {

    const token = localStorage.getItem('token');
    axios.get(api.getAssetsURL, { headers: { 'Authorization': `Bearer ${token}` }
        }).then(
        res => {
        setAsset(res.data.results)
        })
}
export function getLocation(setLocation) {
    const token = localStorage.getItem('token');
    axios.get(api.getStoresURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            const dataSet = res.data.results
            // console.log(12345, dataSet)
            const lc = []
            dataSet.map((item, index) => {
                lc.push({ id: item.site_code, title: item.location_name })
                return null;
            })
            // console.log(2223,lc)
            setLocation(lc)
        })
}

export function getParent(setParent) {
    const token = localStorage.getItem('token');

    axios.get(api.getAssetsURL, { headers: { 'Authorization': `Bearer ${token}` }
        }).then(
            res => {
                var d = res.data.results
            var data_results = []
                
                d.map(item => {
                    var temp = {}
                    // if (item.location === location) {
                    if(item.asset_category === 'Jetson' || item.asset_category==='Raspberry pi'){
                       temp['id'] = item.location+":"+item.asset_name
                        temp['title'] = item.location+":"+item.asset_name
                        data_results.push(temp)
                    }
                    return null;
                })
                setParent(data_results)
            //    console.log(filterHome)
        })
    // console.log(parent)
    
    // return parent
    
}

export function getUsecase(setUsecase) {
    const token = localStorage.getItem('token');
    axios.get(api.getUsecaseURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            const dataSet = res.data.results
            let useCase = []
            dataSet.map((item) => {
                useCase.push({id:item.name,title:item.name})
                return null;
            })
            console.log(222,useCase)
            setUsecase(useCase)
        })
}

export function getLocationData(id,setLocation) {
    const token = localStorage.getItem('token');
    axios.get(api.getStoresURL+`/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            const dataSet = res.data
            // console.log(12345, dataSet)
            
            setLocation(dataSet.site_code)
        })
}
export function createAsset(values, setNotify) {
    const dataset = {
        asset_name:values.asset_name,
    brand:values.brand,
    asset_model:values.asset_model,
    asset_category:values.asset_category,
    description:values.description,
    purchaseDate:values.purchaseDate,
    deployementDate:values.deployementDate,
    asset_ip:values.asset_ip,
    confForJetson:values.confForJetson,
        location: values.location,
        shelf: values.shelf,
        asset_position: values.asset_position,
        serial_no: values.serial_no,
        mac_ip:values.mac_id,
    parent:values.parent,
    usecases:values.usecases
    }
  

    
    
    const token = localStorage.getItem('token'); 
    console.log(token)
    axios.post(api.createAssetsURL,dataset, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
            window.location = "/squirrel/assets"

        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: "" + err,
                type: "error"
            })
        });
}
export function getAssetData(id, setAssetData) {
    const token = localStorage.getItem('token'); 
    // console.log(token)
    axios.get(api.getAssetsURL+`/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => { 
            const values = res.data
            const dataset = {
            asset_id:values.asset_id,
            asset_name:values.asset_name,
            brand:values.brand,
            asset_model:values.asset_model,
            asset_category:values.asset_category,
            description:values.description,
            purchaseDate:values.purchaseDate,
            deployementDate:values.deployementDate,
            asset_ip:values.asset_ip,
            confForJetson:values.confForJetson,
                location: values.location,
                shelf: values.shelf,
                asset_position: values.asset_position,
                serial_no: values.serial_no,
                mac_id: values.mac_ip,
        parent:values.parent,
        usecases:values.usecases === ''?'-1':values.usecases
                
            }
            setAssetData(dataset)
        })
    
}
export function getAssettable(location, setAs) {
    const token = localStorage.getItem('token'); 

    axios.get(api.getAssetListURL+`?location=${location}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => { 

            console.log(res.data)
            setAs(res.data)
        })
}

export function getAssetInfo(setValues, id)  {
    const token = localStorage.getItem('token'); 
    // console.log(token)
    axios.get(api.getAssetsURL+`/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => { 
            const values = res.data
            const dataset = {
            asset_id:values.asset_id,
            asset_name:values.asset_name,
            brand:values.brand,
            asset_model:values.asset_model,
            asset_category:values.asset_category,
            description:values.description,
            purchaseDate:values.purchaseDate,
            deployementDate:values.deployementDate,
            asset_ip:values.asset_ip,
            confForJetson:values.confForJetson,
                location: values.location,
                shelf: values.shelf,
                asset_position: values.asset_position,
                serial_no: values.serial_no,
                mac_id:values.mac_ip,
                parent:values.parent,
                usecases:values.usecases === ''?'-1':values.usecases
                
            }
            setValues(dataset)
        })
    
}
export function updateAsset(values, setNotify,id) {
    const dataset = {
        asset_name:values.asset_name,
    brand:values.brand,
    asset_model:values.asset_model,
    asset_category:values.asset_category,
    description:values.description,
    purchaseDate:values.purchaseDate,
    deployementDate:values.deployementDate,
    asset_ip:values.asset_ip,
    confForJetson:values.confForJetson,
        location: values.location,
        shelf: values.shelf,
        asset_position: values.asset_position,
        mac_ip:values.mac_id,
    parent:values.parent,
    usecases:values.usecases === ''?'-1':values.usecases
    }
    const token = localStorage.getItem('token'); 
    // console.log(token)
    axios.put(api.updateAssetsURL+`/${id}`,dataset, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            window.location = "/squirrel/assets"

            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: "" + err,
                type: "error"
            })
        });
}
export function deleteAsset(setNotify,id) { 
    const token = localStorage.getItem('token');
    axios.delete(api.deleteAssetsURL+`/${id}`, { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            console.log(res)
            if (res.data.message === "Not Allowed") {
                setNotify({
                    isOpen: true,
                    message: "You have no permission to Delete",
                    type: "error"
                })
            }
            else
            {
                setNotify({
                    isOpen: true,
                    message: "Asset Item Deleted",
                    type: "success"
                })
             window.location = "/squirrel/assets"

            }
            
            // return result 
        }).catch(err => {
            console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export const getAssetCategory = () => ([
    { id: 'Camera', title: 'Camera' },
    { id: 'Jetson', title: 'Jetson' },
    { id: 'Internet Router', title: 'Internet Router' },
    { id: 'Raspberry pi', title: 'Raspberry pi' },
])

export const getShelf = () => ([
    { id: '0', title: '0' },
    { id: '1', title: '1' },
    { id: '2', title: '2' },
    { id: '3', title: '3' },
    { id: '4', title: '4' },
    { id: '5', title: '5' },
    { id: '6', title: '6' },
    { id: '7', title: '7' },
    { id: '8', title: '8' },
    { id: '9', title: '9' },
])

export const getShelfPos = (shelf) => (
    [
    { id: shelf+'1', title: 'Left' },
    { id: shelf+'2', title: '2nd position from left' }, 
    { id: shelf+'3', title: '3rd position from left' },
    { id: shelf+'4', title: '4th position from left' },
    { id: shelf+'5', title: '5th position from left' },
    { id: shelf+'6', title: '6th position from left' },
    { id: shelf+'7', title: '7th position from left' },
    { id: shelf+'8', title: '8th position from left' },
    { id: shelf+'9', title: '9th position from left' },
])