import React, { useEffect, useState } from "react";
// import FlowBuilder from './FlowBuilder'
import { Button, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { useForm, Form } from "../../../useForm";
import Controls from "../../../controls/Controls";
import * as locService from "../../../../services/locationServices";
// import DAGViewer from "./DAGViewer";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import JSONInput from "react-json-editor-ajrm";
// import DirectedGraphTable from "./svgeditor";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SvgEditor from "./svgeditor";
import DAGSquirrel from "./DAGViewer";


// const ErrorBoundary = ({ children }) => {
//   const [hasError, setHasError] = useState(false);
//   const [error, setError] = useState(null);
//   const [errorInfo, setErrorInfo] = useState(null);

//   const componentDidCatch = (error, errorInfo) => {
//     setHasError(true);
//     setError(error);
//     setErrorInfo(errorInfo);
//   };

//   if (hasError) {
//     // You can provide a fallback UI here or render nothing
//     return null;
//   }

//   return children;
// };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialFValues = {
  id: 0,
  number_of_input: "",
  number_of_output: "",
  inputs: [],
  outputs: [],
  digraph_script: "",
};
function FlowConfigurator(props) {
  const [numberOfInputs, setNumberOfInputs] = useState(0);
  const [numberOfOutputs, setNumberOfOutputs] = useState(0);
  const [inputValues, setInputValues] = useState(null);
  const [outputValues, setOutputValues] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [dag, setDag] = useState(`
  digraph {
   
  }
  `);
  const [tableData, setTableData] = useState([
    { id: 1, node: '', connectedTo: '', value: '', expression: '', outputVariable: '' },
  ]);
  const [value, setValue] = useState(0);
  const handleTableInputChange = (e, index, field) => {
    const { value } = e.target;
    const updatedData = [...tableData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setTableData(updatedData);
  };
  

  const handleAddRow = () => {
    const newRow = {
      id: Date.now(),
      node: '',
      connectedTo: '',
      value: '',
      expression: '',
      outputVariable: '',
    };
    setTableData([...tableData, newRow]);
  };

  const handleRemoveRow = (id) => {
    const updatedData = tableData.filter((row) => row.id !== id);
    setTableData(updatedData);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = props;
  useEffect(() => {
    locService.getInventoryInfo(setInventory, id);
  }, [id]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const handleNumberchange = (event) => {
    const { value } = event.target;
    setNumberOfInputs(parseInt(value));
  };
  const handleNumberOutchange = (event) => {
    const { value } = event.target;
    setNumberOfOutputs(parseInt(value));
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleMultipleChange = (event) => {
    handleInputChange(event);
    handleNumberchange(event);
  };
  
  const handleMultipleOutChange = (event) => {
    handleInputChange(event);
    handleNumberOutchange(event);
  };
  const handleDynamicInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
    const handleDynamicOutputChange = (event) => {
      const { name, value } = event.target;
      setOutputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
  };
  const generateGraphCode = (tableData) => {
    let graphCode = 'digraph {';
  
    const inputDataValues = {};
    tableData.forEach((row) => {
      const { node, connectedTo, value, expression, outputVariable } = row;
      let label = '';
  
      // Include the node value in the label
      if (value !== undefined) {
        label += `Value: ${value}\n`;
  
        // Store the input value in the inputValues object
        inputDataValues[node] = value;
      }
  
      // Check if an expression is provided
      if (expression) {
        try {
          // Replace variables in the expression with their values from inputValues
        //   let evaluatedExpression = expression;
        // Object.keys(inputValues).forEach((key) => {
        //   const regex = new RegExp(`\\b${key}\\b`, 'g');
        //   //console.log(key)
        //   evaluatedExpression = evaluatedExpression.replace(regex, inputValues[key]);

        // });
        // const evaluatedExpression = (expression, inputDataValues) => {
        //   // Create a variable assignment statement for each key-value pair in the values object
        //   const variableAssignments = Object.entries(inputDataValues)
        //     .map(([key, value]) => `const ${key} = ${value};`)
        //     .join('\n');
        
        //   // Construct the dynamic JavaScript code string
        //   const code = `${variableAssignments}\n${expression}`;
        // //console.log(code,"code")
        //   // Evaluate the code using eval() and return the result
        //   return eval(code);
        //   };
          const variableAssignments = Object.entries(inputDataValues)
            .map(([key, value]) => `const ${key} = ${value};`)
            .join('\n');
          const evaluatedExpression = `${variableAssignments}\n${expression}`;
          // //console.log(111,variableAssignments,evaluatedExpression)
          // Evaluate the modified expression
          const result = eval(evaluatedExpression); // Note: eval() can be dangerous if used with untrusted input
          label += `${expression} = ${result}`;
  
          // Store the output value in the inputValues object
          inputDataValues[outputVariable] = result;
        } catch (error) {
          label += `${expression} = Error: ${error.message}`;
        }
      }
  
      graphCode += `${node} -> ${connectedTo} [label="${label}", output="${outputVariable}"];`;
    });
  
    graphCode += '}';
  
    //console.log('Input Values:', inputDataValues); // Example: { a: 5, b: 10, c: 15 }
  
    return graphCode;
  };
  
  

    const handleGenerateGraph = () => {
      const graphCode = generateGraphCode(tableData);
      //console.log(graphCode);
      setDag(graphCode)
      // Perform further actions with the generated graph code
    };
  

  return (
    <Stack m={3} gap={3}>
      {/* <Card sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)" }}>
        <Grid
          sx={{
            display: "flex",
            alignItems: "left",
            padding: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" color="inherit">
            Flow Configurator
          </Typography>
        </Grid>
      </Card> */}
      {/* <FlowBuilder /> */}
      {/* <pre>{location?location:null}</pre> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Digraph Form" {...a11yProps(2)} />
                <Tab label="Input Data(Json)" {...a11yProps(1)} />
                <Tab label="Digraph" {...a11yProps(0)} />
                <Tab label="SVG Creator" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Controls.InputTextArea
                value={dag}
                maxrows={4}
                onChange={(e) => setDag(e.target.value)}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {inventory ? (
                <Box>
                  <textarea
        value={inventory.inventory}
        // onChange={inventory.inventory}
        style={{ width: '100%', height: '200px' }}
      />
                  {/* <JSONInput
                    id="a_unique_id"
                    placeholder={inventory.inventory}
                    // colors={darktheme}
                    // locale={locale}
                    height="800px"
                  /> */}
                  {/* <Typography component="pre" variant="body2">
        <ol>
          {inventory.inventory.map((inv, index) => (
            <li key={index}>{JSON.stringify(inv)}</li>
          ))}
        </ol>
      </Typography> */}
                </Box>
              ) : null}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Form>
                <Card sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)" }}>
                  <CardHeader> 
                  <Typography variant="h3" color="inherit">
            Flow Configurator
          </Typography>
                  </CardHeader>

                  <CardContent>
                    <Grid item xs={12} md={6}>
                      <Controls.Input
                        name="number_of_inputs"
                        label="No. Of Inputs"
                        required={true}
                        value={values.number_of_inputs}
                        onChange={handleMultipleChange}
                        error={errors.number_of_inputs}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {!isNaN(numberOfInputs) ?Array.from(Array(numberOfInputs).keys()).map((index) => (
                        <Controls.Input
                          key={index}
                          name={`input_${index}`}
                          label={`Input ${index + 1}`}
                          required={true}
                          value={values[`input_${index}`]}
                          onChange={handleDynamicInputChange}
                          error={errors[`input_${index}`]}
                        />
                      )):null}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controls.Input
                        name="number_of_outputs"
                        label="No. Of Outputs"
                        required={true}
                        value={values.number_of_outputs}
                        onChange={handleMultipleOutChange}
                        error={errors.number_of_outputs}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {!isNaN(numberOfOutputs) ?Array.from(Array(numberOfOutputs).keys()).map((index) => (
                        <Controls.Input
                          key={index}
                          name={`output_${index}`}
                          label={`output ${index + 1}`}
                          required={true}
                          value={values[`output_${index}`]}
                          onChange={handleDynamicOutputChange}
                          error={errors[`output_${index}`]}
                        />
                      )):null}
                    </Grid>
                    <Divider />
                    <div style={{ width: "100%", overflowX: "auto",marginTop:2 }}>
                    <Button onClick={handleAddRow} variant="outlined"
                color="secondary" startIcon={<AddIcon />}>
  Add Row
</Button>
      <table style={{width: "100%",tableLayout: "fixed"}}>
        <thead>
          <tr>
            <th>Node</th>
            <th>Connected To</th>
            <th>Value</th>
            <th>Expression</th>
            <th>Output Variable</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={row.id}>
            <td>
              <Controls.Input
                value={row.node}
                onChange={(e) => handleTableInputChange(e, index, 'node')}
                style={{ width: '100%', boxSizing: 'border-box' }}
              />
            </td>
            <td>
              <Controls.Input
                value={row.connectedTo}
                onChange={(e) => handleTableInputChange(e, index, 'connectedTo')}
                style={{ width: '100%', boxSizing: 'border-box' }}
              />
            </td>
            <td>
              <Controls.Input
                value={row.value}
                onChange={(e) => handleTableInputChange(e, index, 'value')}
                style={{ width: '100%', boxSizing: 'border-box' }}
              />
            </td>
            <td>
              <Controls.Input
                value={row.expression}
                onChange={(e) => handleTableInputChange(e, index, 'expression')}
                style={{ width: '100%', boxSizing: 'border-box' }}
              />
            </td>
            <td>
              <Controls.Input
                value={row.outputVariable}
                onChange={(e) => handleTableInputChange(e, index, 'outputVariable')}
                style={{ width: '100%', boxSizing: 'border-box' }}
              />
            </td>
            <td>
            <IconButton onClick={() => handleRemoveRow(row.id)} aria-label="Remove">
      <DeleteIcon />
    </IconButton>
            </td>
          </tr>
          
          ))}
        </tbody>
      </table>
                      {/* <button onClick={handleAddRow}>Add Row</button> */}
    <Button onClick={handleGenerateGraph} variant="outlined"
                color="secondary" startIcon={<AddIcon />}>
  Generate Graph
</Button>
    </div>
                  </CardContent>
                </Card>
               
              </Form>
            </TabPanel>
            <TabPanel value={value} index={3}>
              {/* <DirectedGraphTable /> */}
              <SvgEditor />
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)" }}>
            <CardContent>
            {/* <ErrorBoundary> */}
                <DAGSquirrel dot={dag} height="100vh" />
                {/* </ErrorBoundary> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default FlowConfigurator;
