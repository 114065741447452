import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'


const initialFValues = {
    id: 0,
    name: '',
    description: '',
    automation_script: '',
    nexus_repo_link: '',
    github_link: '',
    requirements_list: '',
    tested_by: '',
    is_active: false,
} 

export default function CCForm(props) {
    const { addOrEdit, recordForEdit} = props
    const options = [
        { id: true, title: 'True' },
        { id: false, title: 'False' }
      ];
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                <Controls.Input
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <Controls.InputTextArea
                        name="description"
                        label="Description"
                        required={true}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                    <Controls.Input
                        name="automation_script"
                        label="Automation Script"
                        value={values.automation_script}
                        onChange={handleInputChange}
                        error={errors.automation_script}
                    />
                    <Controls.Input
                        name="nexus_repo_link"
                        label="Nexus Repo Link"
                        value={values.nexus_repo_link}
                        onChange={handleInputChange}
                        error={errors.nexus_repo_link}
                    />
                    <Controls.Input
                        name="github_link"
                        label="Github Link"
                        value={values.github_link}
                        onChange={handleInputChange}
                        error={errors.github_link}
                    />
                    <Controls.Input
                        name="requirements_list"
                        label="Requirements List"
                        value={values.requirements_list}
                        onChange={handleInputChange}
                        error={errors.requirements_list}
                    />
                    <Controls.Input
                        name="tested_by"
                        label="Tested by"
                        value={values.tested_by}
                        onChange={handleInputChange}
                        error={errors.tested_by}
                    />
                     <Controls.Select
                        name="is_active"
                        label="Is Active"
                        required={true}
                        value={values.is_active}
                        options={options}
                        onChange={handleInputChange}
                        error={errors.is_active}
                    />
                    
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>

            </Grid>
        </Form>
    )
}
