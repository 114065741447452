import React from 'react'
// import * as settings from '../settings';
import {  Backdrop, CircularProgress,Paper, TableBody, TableCell, TableRow,Grid, Divider, List, ListItem, ListItemText, ListSubheader   } from '@mui/material';
import * as assetServices from '../../../../services/assetServices'
import { useState } from "react";
// import useTable from "../../../components/useTable";


// ########################################################
// The main Home component returned by this Module
// ########################################################

const headCells = [
    { id: 'asset_name', label: 'Asset' },
    { id: 'location', label: 'Store' },
    { id: 'asset_ip', label: 'IP' },
    { id: 'brand', label: 'Brand' },
    { id: 'description', label: 'Description' }
    // { id: 'actions', label: 'Actions', disableSorting: true }
]
function AssetView(props) {
    // // Material UI Classes 
    const {id} = props
    // const isbutton = true
    
    const [location, setLocation] = React.useState(null)
    const [as, setAs] = React.useState(null)
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    
    React.useEffect(() => { 
        assetServices.getLocationData(id,setLocation)
    }, [])
    // // console.log(location)
    React.useEffect(() => {
        assetServices.getAssettable(id,setAs)
    }, []);
    // console.log(as,as.length)
    // // const {
    // //     TblContainer,
    // //     TblHead,
    // //     TblPagination,
    // //     recordsAfterPagingAndSorting
    // // } = useTable(asset, headCells, filterFn);
    
    return (<>
        <Grid container spacing={1}>
        {
            as ? 
                
                as.map((config, index) => {
                    return <Grid item xs={4}>
                        <List sx={{ width: '100%', bgcolor: 'background.paper',borderRadius:2 }} >
                            <ListItem>                    
                                <ListItemText sx={{ width: '150px' }} id="switch-list-label-wifi" primary="Asset Name" />
                                {config.asset_name}
                            </ListItem>
                            <Divider component="li" />
                            <ListItem>                    
                                <ListItemText sx={{ width: '150px' }} id="switch-list-label-wifi" primary="Store" />
                                {config.location}
                            </ListItem>
                            <Divider component="li" />
                            <ListItem>                    
                                <ListItemText sx={{ width: '150px' }} id="switch-list-label-wifi" primary="Description" />
                                {config.descritpion}
                            </ListItem>
                            <Divider component="li" />
                            <ListItem>                    
                                <ListItemText sx={{ width: '150px' }} id="switch-list-label-wifi" primary="Ip" />
                                {config.asset_ip}
                            </ListItem>
                            {/* <Divider component="li" /> */}
                        </List>
                     </Grid>   
                })
                
                : "No Data"
            }
        </Grid>
        </>
    )
}

export default AssetView