import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});
  const [catalogue, setCatalogue] = useState([
    {
      id: uuidv4(),
      catalogueName: "-1",
      threshold: "",
      minimum_reorder_quantity: 0,
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    if (name === "shelf") {
      var asset = values.asset_name.split("-");
      // var v = parseInt(value) - 1
      // console.log(1,asset[0],value)

      var asset_name = asset[0] + "-" + value;
      // console.log(values.asset_name)
      setValues({
        ...values,
        [name]: value,
        asset_name: asset_name,
        asset_position: "",
      });
      
    }
    if (name === "asset_position") {
      var assetss = values.asset_name.split("-");
      // console.log(values.asset_name,value)

      var asset_names = assetss[0] + "-" + value;
      setValues({
        ...values,
        [name]: value,
        asset_name: asset_names,
      });
    }
    if (validateOnChange) validate({ [name]: value });
  };
  const handleChangeInput = (id, event) => {
    const newCatalogue = catalogue.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    // console.log(newCatalogue)
    setCatalogue(newCatalogue);
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  const handleAddFields = () => {
    setCatalogue([
      ...catalogue,
      {
        id: uuidv4(),
        catalogueName: "-1",
        threshold: "",
        minimum_reorder_quantity: 0,
      },
    ]);
  };
  const handleRemoveFields = (id) => {
    const values = [...catalogue];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setCatalogue(values);
  };
  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    catalogue,
    setCatalogue,
    handleChangeInput,
    handleAddFields,
    handleRemoveFields,
  };
}

const StyledForm = styled('form')(({ theme }) => ({
    "& .MuiFormControl-root": {
      width: "100%",
      padding: 4,
      margin: theme.spacing(1),
    },
  }));
  
  export function Form(props) {
    const { children, ...other } = props;
    return (
      <StyledForm autoComplete="off" {...other}>
        {props.children}
      </StyledForm>
    );
  }