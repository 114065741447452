import * as React from 'react';
import { Backdrop, CircularProgress, Divider, Grid, List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import * as locService from '../../../../services/locationServices'


function ThresholdInfo(props) { 
    const {id} = props
    const [threshold, setThreshold] = React.useState(null)
    React.useEffect(() => {
        locService.getThresholdInfo(setThreshold,id)      
    }, []);
    return (<>
        {
            threshold ? 
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                                <List sx={{ width: '100%', bgcolor: '#EBF0F0',borderRadius:2 }} key="first">
                                    <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>                    
                                        <ListItemText sx={{ width: '150px' }} id="switch-list-label-wifi" primary="Catalogue" />
                                        Threshold
                                    </ListItem>
                                    {/* <Divider component="li" /> */}
                                
                         {
                            threshold.map((item, index) => {
                                return (
                                    <>
                                        <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                                            <ListItemText sx={{ width: '150px' }} id="switch-list-label-wifi" primary={item.catalogueName} />
                                            {item.threshold}
                                            </ListItem>
                                        </>
                                    
                                
                                )
                            })
                            }
                            </List>
                        
                        </Grid>
                </Grid>
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
        }
    </>)

}

export default ThresholdInfo