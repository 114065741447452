import * as api from "../config/apiConf";
import axios from "axios";

export function getAllCategories(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getLcURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      const result = res.data.results;
      const dataSet = [];
      result.map((item, index) => {
        dataSet.push({
          id: item.category_id,
          category: item.category_name,
          description: item.description,
        });
        return null;
      });
      setRecords(dataSet);

    });
}

export function createCategory(categories, setNotify, setRecords) {
  const data = {
    category_name: categories.category,
    description: categories.description,
  };
  const token = localStorage.getItem("token");
  axios
    .post(api.createLcURL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      // return result
      getAllCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function updateCategory(categories, setNotify, setRecords) {
  const data = {
    category_name: categories.category,
    description: categories.description,
  };
  const token = localStorage.getItem("token");
  axios
    .put(api.updateLcURL + `/${categories.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Updated Successfully",
        type: "success",
      });
      getAllCategories(setRecords);

      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export async function deleteCategory(categories, setNotify, setRecords) {
  const token = localStorage.getItem("token");
  await axios
    .delete(api.deleteLcURL + `/${categories.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
      getAllCategories(setRecords);

      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

//Catalogue category
export function getAllCCategories(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getCcURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      const result = res.data.results;
      const dataSet = [];
      result.map((item, index) => {
        dataSet.push({
          id: item.category_id,
          category: item.category_name,
          description: item.description,
        });
        return null;
      });
      setRecords(dataSet);
    });
}

export function createCCategory(categories, setNotify, setRecords) {
  const data = {
    category_name: categories.category,
    description: categories.description,
  };
  const token = localStorage.getItem("token");
  axios
    .post(api.createCcURL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      getAllCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function updateCCategory(categories, setNotify, setRecords) {
  const data = {
    category_name: categories.category,
    description: categories.description,
  };
  const token = localStorage.getItem("token");
  axios
    .put(api.updateCcURL + `/${categories.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Updated Successfully",
        type: "success",
      });
      getAllCCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export async function deleteCCategory(categories, setNotify, setRecords) {
  // console.log("catego",categories)

  const token = localStorage.getItem("token");
  await axios
    .delete(api.deleteCcURL + `/${categories.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
      getAllCCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

// Incident categories

export function getAllIncidentCategories(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getIncidentURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      const result = res.data.results;
      const dataSet = [];
      // console.log(result)
      result.map((item, index) => {
        dataSet.push({
          title:  item.category_string,
        });
        return null;
      });
      setRecords(dataSet);
    });
}
export function createIncidentCategory(categories, setNotify, setRecords) {
  const data = {
    title: categories.title,
    description: categories.description,
    application: categories.application,
    parent: categories.parent,
    category_string: categories.category_string,
  };
  const token = localStorage.getItem("token");
  axios
    .post(api.createIncidentURL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      getAllIncidentCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function updateIncidentCategory(categories, setNotify, setRecords) {
  const data = {
    title: categories.title,
    description: categories.description,
    application: categories.application,
    parent: categories.parent,
    category_string: categories.category_string,
  };
  const token = localStorage.getItem("token");
  axios
    .put(api.updateIncidentURL`/${categories.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Updated Successfully",
        type: "success",
      });
      getAllIncidentCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export async function deleteIncidentCategory(
  categories,
  setNotify,
  setRecords
) {
  const token = localStorage.getItem("token");
  await axios
    .delete(api.deleteIncidentURL + `/${categories.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
      getAllIncidentCategories(setRecords);
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

// Asset Compute

export function getAssetCompute(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getAcURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // const data = res.data.results["created_by"]['email']
      // console.log(data)
      // console.log(res.data)

      setRecords(res.data.results);
    });
}

export function createAssetCompute(categories, setNotify) {
  const data = {
    location: categories.location,
    ip: categories.ip,
    installDate: categories.installDate,
    confForJetson: categories.confForJetson,
  };
  const token = localStorage.getItem("token");
  axios
    .post(api.createAcURL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function updateAssetCompute(categories, setNotify) {
  const data = {
    location: categories.location,
    ip: categories.ip,
    installDate: categories.installDate,
    confForJetson: categories.confForJetson,
  };
  const token = localStorage.getItem("token");
  axios
    .put(api.updateAcURL + `/${categories.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Updated Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}
export function getAllAssetCompute(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getAcURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      const result = res.data.results;
      const dataSet = [];
      console.log(result);
      result.map((item, index) => {
        dataSet.push({
          id: item.compute_id,
          location: item.location,
          ip: item.ip,
          installDate: item.installDate,
          confForJetson: item.confForJetson,
        });
        return null;
      });
      setRecords(dataSet);
    });
}
export async function deleteAssetCompute(categories, setNotify) {
  console.log("catego", categories);

  const token = localStorage.getItem("token");
  await axios
    .delete(api.deleteAcURL + `/${categories.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function getLocation(setLocation) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getStoresURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      const dataSet = res.data.results;
      // console.log(12345, dataSet)
      const lc = [];
      dataSet.map((item, index) => {
        lc.push({ id: item.site_code, title: item.location_name });
        return null;
      });
      // console.log(2223,lc)
      setLocation(lc);
    });
}

export function getLocationCompute(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getLcoURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      setRecords(res.data.results);
    });
}

export function createLocationCompute(categories, setNotify) {
  const data = {
    location: categories.location,
    ip: categories.ip,
    installDate: categories.installDate,
    confForJetson: categories.confForJetson,
  };
  const token = localStorage.getItem("token");
  axios
    .post(api.createLcoURL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function updateLocationCompute(categories, setNotify) {
  const data = {
    location: categories.location,
    ip: categories.ip,
    installDate: categories.installDate,
    confForJetson: categories.confForJetson,
  };
  const token = localStorage.getItem("token");
  axios
    .put(api.updateLcoURL + `/${categories.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Updated Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}
export function getAllLocationCompute(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getLcoURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      const result = res.data.results;
      const dataSet = [];
      console.log(result);
      result.map((item, index) => {
        dataSet.push({
          id: item.compute_id,
          location: item.location,
          ip: item.ip,
          installDate: item.installDate,
          confForJetson: item.confForJetson,
        });
        return null;
      });
      setRecords(dataSet);
    });
}
export async function deleteLocationCompute(categories, setNotify) {
  console.log("catego", categories);

  const token = localStorage.getItem("token");
  await axios
    .delete(api.deleteLcoURL + `/${categories.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}




// contract

export function getOrganization(setOrganization) {
    
    const token = localStorage.getItem('token');
    axios.get(api.getOrganizationURL, { headers: { 'Authorization': `Bearer ${token}` }
            }).then(
            res => {
                    const dataSet = res.data.results
                    // console.log(dataSet)
                    const organization = []
                    dataSet.map((item, index) => {
                        organization.push({id:item.organization_id,title:item.organization})
                        return null;
                    })
                setOrganization(organization)
            })
}


export function getContract(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getContractURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {

            setRecords(res.data.results)
        })
}

export function createContract(categories,setNotify) { 
    const data = {
        "organization": categories.organization,
        "firstname": categories.firstname,
        "lastname": categories.lastname,
        "email": categories.email,
        'start_date': categories.start_date,
        'end_date':categories.end_date
    }
    const token = localStorage.getItem('token');
    axios.post(api.createContractURL, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function updateContract(categories,setNotify) { 
    const data = {
        "organization": categories.organization,
        "firstname": categories.firstname,
        "lastname": categories.lastname,
        "email": categories.email,
        'start_date': categories.start_date,
        'end_date':categories.end_date
    }
    const token = localStorage.getItem('token');
    axios.put(api.updateContractURL+`/${categories.id}`, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function getAllContract(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getContractURL, {
        headers: { 'Authorization': `Bearer ${token}`}
    }).then(
        res => {
            // console.log(123456, res.data.results)    
            const result = res.data.results
            const dataSet = []
            console.log(result)
            result.map((item, index) => {
                dataSet.push(
                    {
                        id: item.compute_id,
                        organization: item.organization,
                        firstname: item.firstname,
                        lastname: item.lastname,
                        email: item.email,
                        start_date: item.start_date,
                        end_date:item.end_date
                    }
                )
                return null;
            })
            setRecords(dataSet)
        }
    )
}

export async function deleteContract(categories,setNotify) { 
    console.log("catego",categories)

    const token = localStorage.getItem('token');
    await axios.delete(api.deleteContractURL+`/${categories.id}`, { headers: { 'Authorization': `Bearer ${token}`} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Deleted Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}


// Service

export function getService(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getServiceURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            // const data = res.data.results["created_by"]['email']
            // console.log(data)
            // console.log(res.data)
            
            setRecords(res.data.results)
        })
}


export function createService(categories,setNotify) { 
    const data = {
        "organization": categories.organization,
        "name": categories.name,
        "description": categories.description,
        "shortkey": categories.shortkey
    }


    const token = localStorage.getItem('token');
    axios.post(api.createServiceURL, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function updateService(categories,setNotify) { 
    const data = {
        "organization": categories.organization,
        "name": categories.name,
        "description": categories.description,
        "shortkey": categories.shortkey
    }
    const token = localStorage.getItem('token');
    axios.put(api.updateServiceURL+`/${categories.id}`, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function getAllService(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getServiceURL, {
        headers: { 'Authorization': `Bearer ${token}`}
    }).then(
        res => {
            // console.log(123456, res.data.results)    
            const result = res.data.results
            const dataSet = []
            console.log(result)
            result.map((item, index) => {
                dataSet.push(
                    {
                        id: item.compute_id,
                        organization: item.organization,
                        name: item.name,
                        description: item.description,
                        shortkey: item.shortkey
                    }
                )
                return null;
            })
            setRecords(dataSet)
        }
    )
}

export async function deleteService(categories,setNotify) { 
    console.log("catego",categories)

    const token = localStorage.getItem('token');
    await axios.delete(api.deleteServiceURL+`/${categories.id}`, { headers: { 'Authorization': `Bearer ${token}`} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Deleted Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}


// Bill


export function getBill(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getBillURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            // const data = res.data.results["created_by"]['email']
            // console.log(data)
            // console.log(res.data)
            
            setRecords(res.data.results)
        })
}


export function createBill(categories,setNotify) { 
    const data = {
        "organization": categories.organization,
        "name": categories.name,
        "description": categories.description,
        "shortkey": categories.shortkey
    }


    const token = localStorage.getItem('token');
    axios.post(api.createBillURL, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function updateBill(categories,setNotify) { 
    const data = {
        "organization": categories.organization,
        "name": categories.name,
        "description": categories.description,
        "shortkey": categories.shortkey
    }
    const token = localStorage.getItem('token');
    axios.put(api.updateBillURL+`/${categories.id}`, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}
export function getAllBill(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getBillURL, {
        headers: { 'Authorization': `Bearer ${token}`}
    }).then(
        res => {
            // console.log(123456, res.data.results)    
            const result = res.data.results
            const dataSet = []
            console.log(result)
            result.map((item, index) => {
                dataSet.push(
                    {
                        id: item.compute_id,
                        organization: item.organization,
                        name: item.name,
                        description: item.description,
                        shortkey: item.shortkey
                    }
                )
                return null;
            })
            setRecords(dataSet)
        }
    )
}
export async function deleteBill(categories,setNotify) { 

    const token = localStorage.getItem('token');
    await axios.delete(api.deleteBillURL+`/${categories.id}`, { headers: { 'Authorization': `Bearer ${token}`} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Deleted Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}


export function getServices(setLocation) {
    const token = localStorage.getItem('token');
    axios.get(api.getServiceURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            const dataSet = res.data.results
            // console.log(12345, dataSet)
            const lc = []
            dataSet.map((item, index) => {
                lc.push({ id: item.service_id, title: item.name })
                return null;
            })
            // console.log(2223,lc)
            setLocation(lc)
        })
}


// partner



export function getPartner(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getPartnerURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            // const data = res.data.results["created_by"]['email']
            // console.log(data)
            // console.log(res.data)
            
            setRecords(res.data.results)
        })
}


export function createPartner(categories,setNotify) { 
    const data = {
        "name": categories.name,
        "description": categories.description,
        "address": categories.address,
        "pin": categories.pin,
        "country": categories.country,
        "primaryContact": categories.primaryContact,
        "contactEmail": categories.contactEmail,
        "website": categories.website,
        "serviceURL": categories.serviceURL,
        "category": categories.category,
        "logoURL": categories.logoURL
    }


    const token = localStorage.getItem('token');
    axios.post(api.createPartnerURL, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function updatePartner(categories,setNotify) { 
    const data = {
        "name": categories.name,
        "description": categories.description,
        "address": categories.address,
        "pin": categories.pin,
        "country": categories.country,
        "primaryContact": categories.primaryContact,
        "contactEmail": categories.contactEmail,
        "website": categories.website,
        "serviceURL": categories.serviceURL,
        "category": categories.category,
        "logoURL": categories.logoURL
    }
    const token = localStorage.getItem('token');
    axios.put(api.updatePartnerURL+`/${categories.id}`, data
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}
export function getAllPartner(setRecords) {
    const token = localStorage.getItem('token');
    axios.get(api.getPartnerURL, {
        headers: { 'Authorization': `Bearer ${token}`}
    }).then(
        res => {
            // console.log(123456, res.data.results)    
            const result = res.data.results
            const dataSet = []
            console.log(result)
            result.map((item, index) => {
                dataSet.push(
                    {
                        id: item.compute_id,
                        name: item.name,
                        description: item.description,
                        address: item.address,
                        pin: item.pin,
                        country: item.country,
                        primaryContact: item.primaryContact,
                        contactEmail: item.contactEmail,
                        website: item.website,
                        serviceURL: item.serviceURL,
                        category: item.category,
                        logoURL: item.logoURL
                    }
                )
                return null;
            })
            setRecords(dataSet)
        }
    )
}
export async function deletePartner(categories,setNotify) { 
    console.log("catego",categories)

    const token = localStorage.getItem('token');
    await axios.delete(api.deletePartnerURL+`/${categories.id}`, { headers: { 'Authorization': `Bearer ${token}`} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Deleted Successfully",
                type: "success"
            })
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}




export function getUsecase(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getUsecaseURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {

      setRecords(res.data.results);
    }); 
}

export function createUsecase(obj, setNotify) {
  const data = {
    name: obj.name,
    description: obj.description,
    automation_script: obj.automation_script,
    nexus_repo_link: obj.nexus_repo_link,
    github_link: obj.github_link,
    requirements_list: obj.requirements_list,
    tested_by: obj.tested_by,
    is_active: obj.is_active,

  };
  const token = localStorage.getItem("token");
  axios
    .post(api.createUsecaseURL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function updateUsecase(obj, setNotify) {
  const data = {
    name: obj.name,
    description: obj.description,
    automation_script: obj.automation_script,
    nexus_repo_link: obj.nexus_repo_link,
    github_link: obj.github_link,
    requirements_list: obj.requirements_list,
    tested_by: obj.tested_by,
    is_active: obj.is_active,
  };
  const token = localStorage.getItem("token");
  axios
    .put(api.updateUsecaseURL + `/${obj.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Updated Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}
export function getAllUsecase(setRecords) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getUsecaseURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      const result = res.data.results;
      const dataSet = [];
      console.log(result);
      result.map((obj, index) => {
        dataSet.push({
          id: obj.compute_id,
          name: obj.name,
          description: obj.description,
          automation_script: obj.automation_script,
          nexus_repo_link: obj.nexus_repo_link,
          github_link: obj.github_link,
          requirements_list: obj.requirements_list,
          tested_by: obj.tested_by,
          is_active: obj.is_active,
        });
        return null;
      });
      setRecords(dataSet);
    });
}
export async function deleteUsecase(obj, setNotify) {

  const token = localStorage.getItem("token");
  await axios
    .delete(api.deleteUsecaseURL + `/${obj.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}
