import Input from "./Input";
import Select from "./Select";
import Button from "./Button";
import InputTextArea from "./InputTextArea";
import ActionButton from "./ActionButton"
import TagsInput from "./TagsInput"; 

const Controls = {  
    Input,
    Select,
    Button,
    InputTextArea,
    ActionButton,
    TagsInput
}

export default Controls;