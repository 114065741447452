// assets
import { DashboardOutlined } from '@mui/icons-material';
 
// constant
const icons = { DashboardOutlined };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard-menu',
    // title: 'Dashboard',
    type: 'group',
    permission:['admin','user','guest','creator','store_manager','business','operation','reporting'],
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
