import React from 'react'
import ReactMarkdown from 'react-markdown';
// import Img from 'react-img';
// import Mermaid from 'react-mermaid';



export default function Markdown(props){
    const {wid} = props

    return (
      <div>
        <img src={wid.query} alt={wid.title} style={{ maxWidth: '100%', height: 'auto' }}/>
      </div> 
    );
  };
  