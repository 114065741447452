import React from 'react'
import TextField from '@mui/material/TextField';

export default function Input(props) {

    const { name, label, required, value, error = null, onChange, inputProps } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            required={required}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            size='small'
            inputProps={inputProps ? { type: 'number' } : { type: 'text' }}
            {...(error && {error:true,helperText:error})}
        />
    )
}


