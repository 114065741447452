import React, { useEffect } from 'react';
import mermaid from "mermaid";


mermaid.initialize({
  startOnLoad: true
});

const Mermaid = ({ chart }) => {
  useEffect(() => {
    mermaid.contentLoaded();
  }, []);

  return <div className="mermaid">{chart}</div>;
};



const MyComponent = (props) => {
  const {wid} = props
  return (

      <Mermaid
        chart={wid.query}
      />
  );
};

export default MyComponent;

