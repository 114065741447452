import React, { useEffect } from 'react'
import { Grid, Box } from '@mui/material';
import Controls from '../../../../controls/Controls';
import { useForm, Form } from '../../../../useForm';

const initialFValues = {
    id: 0,
    category: '',
    description: '',
}

export default function CCForm(props) {
    const { addOrEdit, recordForEdit } = props

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('category' in fieldValues)
            temp.category = fieldValues.category ? "" : "This field is required."
        if ('description' in fieldValues)
            temp.description = fieldValues.description.length != 0 ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="category"
                        label="Category"
                        value={values.category}
                        onChange={handleInputChange}
                        error={errors.category}
                    />
                    <Controls.Input
                        label="Description"
                        name="description"
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ mr: 1 }}>
                        <Controls.Button text="Reset" color="info" onClick={resetForm} />
                    </Box>
                    <Controls.Button type="submit" text="Submit" color="secondary" />
                    </Grid>

                </Grid>
              
            </Grid>
        </Form>
    )
}
