import React, { useState } from "react";
// import * as settings from '../settings';
import { Backdrop, Button, Card, CircularProgress, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Controls from "../../../controls/Controls";
// import { useForm, Form } from '../../../UseForm'
import * as orgService from "../../../../services/organizationServices";
import Notification from "../../../ui/Notifications";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Form, useForm } from "../../../useForm";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useParams } from 'react-router-dom';

const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow]
  },
}));


const initialFValues = {
    id: 0,
    organization_name: '',
    org_type:'-1',
    description: '',
    email:'',
    address: '',
    country: '-1',
    contactNo: '',
    website: '',
    serviceURL: '',
    logoURL:''  
}

function EditOrganizationForm(props) {
    // Material UI Classes
    // console.log("org", props)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('organization_name' in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
        if ('contactNo' in fieldValues)
            temp.mobile = fieldValues.mobile.length > 15 ? "" : "Minimum 15 numbers required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const params = useParams();
    React.useEffect(() => {
        orgService.getOrganization(setValues,params.id)      
      });
    
    // const isbutton = false

    const handleSubmit = (e) => {
        e.preventDefault();
        orgService.updateOrganization(values,setNotify,params.id) 
        resetForm()
        
    }

    return (
        <>
           <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Edit Organization Info
                      </Typography>
                      <Button component={Link} to="/squirrel/organizations" variant="contained" color="secondary">
                <KeyboardBackspaceOutlinedIcon />
              </Button>
          </Grid>
        </StyledCard>
            </Stack>
        <StyledCard hovershadow={10}>
            
            {
                values.length !== 0 ?
                <div>
                <Form>
                    <Accordion expanded={true}>
                        
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                                    name="organization_name"
                                                    label="Organization Name"
                                                    required={true}
                                                    value={values.organization_name}
                                                    onChange={handleInputChange}
                                                    error={errors.organization_name}                                        
                                    />
                                </Grid>
                                <Grid item xs={12}  md={3}>
                                        <Controls.Select
                                            name="org_type"
                                            label="Organization Type"
                                            value={values.org_type}
                                            onChange={handleInputChange}
                                            options={orgService.getOrganizationsType()}
                                            error={errors.org_type}
                                            required={true}
                                        />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                                    name="email"
                                                    label="Email"
                                                    required={true}
                                                    value={values.email}
                                                    onChange={handleInputChange}
                                                    error={errors.email}                                        
                                    />
                                </Grid>
                                <Grid item xs={12}  md={3}>
                                        <Controls.Select
                                            name="country"
                                            label="Country"
                                            value={values.country}
                                            onChange={handleInputChange}
                                            options={orgService.getCountriesData()}
                                            error={errors.country} 
                                            required={true}
                                        />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Controls.InputTextArea
                                                    name="description"
                                                    label="Description"
                                                    required={true}
                                                    value={values.description}
                                                    onChange={handleInputChange}
                                                    error={errors.description}                                        
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controls.InputTextArea
                                                    name="address"
                                                    label="Address"
                                                    required={true}
                                                    value={values.address}
                                                    onChange={handleInputChange}
                                                    error={errors.address}                                        
                                    />
                                </Grid>                                
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={3}>
                                    <Controls.Input
                                                    name="contactNo"
                                                    label="Contact No."
                                                    required={true}
                                                    value={values.contactNo}
                                                    onChange={handleInputChange}
                                                    error={errors.contactNo}                                        
                                    />
                                </Grid>
                                <Grid item xs={12}  md={9}>
                                    <Controls.Input
                                                    name="website"
                                                    label="Website"
                                                    required={true}
                                                    value={values.website}
                                                    onChange={handleInputChange}
                                                    error={errors.website}                                        
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Controls.InputTextArea
                                                    name="serviceURL"
                                                    label="Service URL"
                                                    required={true}
                                                    value={values.serviceURL}
                                                    onChange={handleInputChange}
                                                    error={errors.serviceURL}                                        
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controls.InputTextArea
                                                    name="logoURL"
                                                    label="Logo URL"
                                                    required={true}
                                                    value={values.logoURL}
                                                    onChange={handleInputChange}
                                                    error={errors.logoURL}                                        
                                    />
                                </Grid>                                
                            </Grid>
                            
                        </AccordionDetails>
                    </Accordion>   
                    <Accordion expanded={true}>
                    
                    <AccordionDetails>
                            <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>Update Organization</Button>
                            {/* <Button variant="contained" color="default" onClick={resetForm} >Reset</Button> */}
                        
                    </AccordionDetails>
                    </Accordion>
                </Form>
                        </div>
                        
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
            }
        </StyledCard>
            
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )

}

// description: '',
// email:'',
// address: '',
// country: '-1',
// contactNo: '',
// website: '',
// serviceURL: '',
// logoURL:''  

export default  EditOrganizationForm; 