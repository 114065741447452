import React from 'react'
import { connect, useSelector } from 'react-redux'
import Routes from './Urls'
import * as actions from './store/authActions';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from './components/layout/NavigationScroll';
import themes from './components/themes';

export const App = (props) => {
  const customization = useSelector((state) => state.customization);

  const auth = useSelector((state) => state.auth);
  // console.log("auth",auth)

  React.useEffect(() => {
    props.setAuthenticatedIfRequired();
  }, [props]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
        <Routes {...props} auth={auth} />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null && typeof state.auth.token !== 'undefined',
    token: state.auth.token
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setAuthenticatedIfRequired: () => dispatch(actions.authCheckState()),
    logout: () => dispatch(actions.authLogout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
