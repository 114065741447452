import * as api from '../config/apiConf'
import axios from 'axios';



export function getCategory(setCategory) {
    
    const token = localStorage.getItem('token');
    axios.get(api.getCcURL, { headers: { 'Authorization': `Bearer ${token}` }
            }).then(
            res => {
                    const dataSet = res.data.results
                    console.log(dataSet)
                    const cat = []
                    dataSet.map((item, index) => {
                        cat.push({ id: item.category_name, title: item.category_name })
                        return null;
                    })
                    setCategory(cat)  
            })
}


export const getSkuStatus = () => ([
    { id: 'Available', title: 'Available' },
    { id: 'Not Available', title: 'Not Available' },
])


export async function createCatalogue(data, setNotify,skucode) {
    const token = localStorage.getItem('token');
    let tags = ''
    skucode.map((tg, index) => {
        if (index === 0) {
            tags += tg
        }
        else {
            tags += ',' + tg
        }
        return null;
    }) 
    console.log(tags)
    const payload = {
        catalogue_name:data.catalogue_name,
        display_name: data.display_name,
        ean:data.ean,
        sku_code: tags,
        category:data.category,
        description: data.description,
        status: data.status,
        weburl: data.weburl,
        imageURL: data.imageURL,
        iconlink:data.iconlink  
    }
    await axios.post(api.createCataloguesURL, payload
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // console.log(res)
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
            window.location = "/squirrel/catalogues"
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}


export function getCatalogues(setCatalogue) {

    const token = localStorage.getItem('token');
    axios.get(api.getCataloguesURL, { headers: { 'Authorization': `Bearer ${token}` }
        }).then(
        res => {
        // console.log(123456, res.data.results)        
        setCatalogue(res.data.results)
        })
}

export function getCatalogue(setValues,id) {

    const token = localStorage.getItem('token');
    axios.get(api.getCataloguesURL+`/${id}`, { headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            // console.log(123456, res.data) 
            const tags = res.data.sku_code.split(',')
            const results = {
                catalogue_id: res.data.catalogue_id,
                catalogue_name:res.data.catalogue_name,
                display_name:res.data.display_name,
                sku_code: tags,
                ean:res.data.ean,
                category:res.data.category,
                description: res.data.description,
                status: res.data.status,
                weburl: res.data.weburl,
                imageURL: res.data.imageURL,
                iconlink:res.data.iconlink 
        }   
            //console.log(2387, res.data) 
           
        setValues(results)
        })
}


export function deleteCatalogue(setNotify,id) { 
    const token = localStorage.getItem('token');
    axios.delete(api.deleteCataloguesURL+`/${id}`, { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            //console.log(res)
            if (res.data.message === "Not Allowed") {
                setNotify({
                    isOpen: true,
                    message: "You have no permission to Delete",
                    type: "error"
                })
            }
            else
            {
                setNotify({
                    isOpen: true,
                    message: "Catalogue Item Deleted",
                    type: "success"
                })
            window.location = "/squirrel/catalogues"
                
            }
            
            // return result 
        }).catch(err => {
            //console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}

export function getCatalogueData(setCatalogue,id) {

    const token = localStorage.getItem('token');
    axios.get(api.getCataloguesURL+`/${id}`, { headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {  
            //console.log(res.data)
            const sku_code = res.data.sku_code.split(',')
            res.data['sku_code'] = sku_code
            // res.data['category'] = res.data.category.category_name 
            setCatalogue([res.data])
        })
}

export async function updateCatalogue(data, setNotify, id,skucode) {
    const token = localStorage.getItem('token');
    //console.log("submit", data,skucode)
    let tags = ''
    skucode.map((tg, index) => {
        if (index === 0) {
            tags += tg
        }
        else {
            tags += ',' + tg
        }
        return null;
    }) 
    //console.log(tags)
    const payload = {
        catalogue_name:data.catalogue_name,
        display_name:data.display_name,
        ean:data.ean,
        sku_code: tags,
        category:data.category,
        description: data.description,
        status: data.status,
        weburl: data.weburl,
        imageURL: data.imageURL,
        iconlink:data.iconlink  
    }
    //console.log(payload)
    await axios.put(api.updateCataloguesURL+`/${id}`, payload
    , { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} }).then(
        res => {
            // //console.log(res)
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            window.location = "/squirrel/catalogues"
            // return result 
        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: ""+err,
                type: "error"
            })
        });
}
