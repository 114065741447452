import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, auth }) => {
    const theme = useTheme();
    // console.log(item, auth.auth.user_role)
    const role = "admin"
    // const role = auth.auth.user_role
    const permission = item.permission
    // console.log(permission.indexOf(role) > -1,role,permission)
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    // console.log(item.permission.indexOf('admin') > -1)
    if (item.permission.indexOf(role) > -1) {
        return (
            <>
            <List
                    subheader={
                        item.title && (
                            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                {item.title}
                                {item.caption && (
                                    <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom color="white">
                                        {item.caption}
                                    </Typography>
                                )}
                            </Typography>
                        )
                    }
                >
                    {items}
                        </List>
                <Divider sx={{ mt: 0.25, mb: 1.25 }} />
            </>
        );
    }
    else {
        return null
    }
    // return (
    //     <>
    //     <List
    //             subheader={
    //                 item.title && (
    //                     <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
    //                         {item.title}
    //                         {item.caption && (
    //                             <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
    //                                 {item.caption}
    //                             </Typography>
    //                         )}
    //                     </Typography>
    //                 )
    //             }
    //         >
    //             {items}
    //                 </List>
    //         <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    //     </>
    // );

    
};

NavGroup.propTypes = {
    item: PropTypes.object,
    auth: PropTypes.object
};

export default NavGroup;
