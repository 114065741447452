import React, { useState } from 'react';
// import * as settings from '../settings';
import {
  Grid,
  Button,
  Card,
  Typography,
  Fab,
  Tooltip,
  Stack,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
// import SubHeader from '../../layout/squirrelLayout/SubHeader';
import * as catServices from "../../../services/catalogueServices";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled } from "@mui/material/styles";
import BlurOnIcon from '@mui/icons-material/BlurOn';
import ReactCardFlip from 'react-card-flip';


const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow],
  },
}));

// ########################################################
// The main Home component returned by this Module
// ########################################################
function Catalogue(props) {
  // Material UI Classes
  const role = props.auth.user_role;

  let isbutton = false;
  const [catalogue, setCatalogue] = React.useState(null);
  React.useEffect(() => {
    catServices.getCatalogues(setCatalogue);
  }, []);
  if (role === "admin") {
    isbutton = true;
  }

  const [isFlippedArray, setIsFlippedArray] = useState(new Array(catalogue).fill(false));

    const handleMouseEnter = (index) => {
      const newIsFlippedArray = [...isFlippedArray];
      const currentIsFlipped = newIsFlippedArray[index];
      newIsFlippedArray.fill(false); // flip all cards back to normal position
      newIsFlippedArray[index] = !currentIsFlipped; // flip the clicked card
      setIsFlippedArray(newIsFlippedArray);
  };

  const handleMouseLeave = () => {
    setIsFlippedArray(new Array(catalogue).fill(false)); // flip all cards back to normal position
  };



  return (
    <>
      {/* <SubHeader title='Catalogues' isButton={isbutton} btnUrl="/squirrel/catalogues/add-catalogue" btnTitle="Add Catalogue"/> */}
      <Stack m={3} gap={3}>
      <Card sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Catalogues
            </Typography>
            {role === "admin" ? (
              <Button
                component={Link}
                to="/squirrel/catalogues/add-catalogue"
                variant="contained"
                color="secondary"
              >
                Add
                          </Button>
                          
            ) : null}
          </Grid>
        </Card>
      </Stack>
      <Grid container spacing={3}>
        {
          catalogue
            ? catalogue.map((cat, index) => (
                <Grid item xs={12} md={3} key={cat.catalogue_id}>
                  <ReactCardFlip isFlipped={isFlippedArray[index]} flipDirection="horizontal">
                                <div key="front" onMouseEnter={() => handleMouseEnter(index)}>
                  <Card
                    sx={{ maxWidth: "100%", height: 323 }}
                    className="org_card"
                    key={index}
                  >
                    
                    <Box sx={{paddingLeft:2,paddingRight:2}}>
                    <CardMedia
                      component="img"
                      height="200"
                      image={cat.imageURL}
                      alt={cat.catalogue_name}
                    />
                    </Box>
                    <Card sx={{
                                     position: 'absolute',
                                     left: 0,
                                     right: 0,
                                     top: '90%',
                                     transform: 'translateY(-50%)',
                                     textAlign: 'center',
                                     backgroundColor:'#212121',
                                     
                                      
                                     
                                      }}>

                      <CardHeader sx={{padding: 1, borderColor: 'green' }}
                      
                      title={cat.catalogue_name}>
                      </CardHeader>
                     

                      </Card>
                  </Card>
                  </div>
                  <div key="back" onMouseLeave={handleMouseLeave}>

                  <Card
                    sx={{ padding: 1, maxWidth: "100%", height: 323 }}
                    className="org_card"
                    key={index}
                  >
                    
                    <CardHeader
                      // avatar={
                      //     <Avatar sx={{ bgcolor: red[500] }} aria-label="organization">
                      //         {cat.catalogue_name.charAt(0).toUpperCase()}
                      //     </Avatar>
                      // }
                      title={cat.catalogue_name}
                      //   subheader="September 14, 2016"
                    />

                      <Card sx={{maxHeight:"70%"}}>
                      <CardContent sx={{padding:0}}>

                      <Typography variant="h2" className="titleEh" sx={{ marginTop: 2 }}>
                      {cat.description}
                      </Typography>
                      </CardContent>
                      </Card>
                    
                    <Box sx={{
                                     position: 'absolute',
                                     left: 0,
                                     right: 0,
                                     top: '90%',
                                     transform: 'translateY(-50%)',
                                     textAlign: 'center',
                                      
                                     
                                      }}>
                      <Fab
                        size="small"
                        color="default"
                        sx={{ marginTop: -2, marginBottom: 1,marginLeft:1 }}
                        aria-label="add"
                        className="view-iconU"
                      >
                        <Tooltip title="view">
                          <IconButton
                            aria-label="view"
                            component={Link}
                            to={
                              "/squirrel/catalogues/view-catalogue/" +
                              cat.catalogue_id
                            }
                          >
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Fab>
                      {role === "admin" ? (
                        <>
                          <Fab
                            size="small"
                            color="default"
                            sx={{ marginTop: -2, marginBottom: 1,marginLeft:1 }}
                            aria-label="add"
                            className="edit-iconU"
                          >
                            <Tooltip title="edit">
                              <IconButton
                                aria-label="Edit"
                                component={Link}
                                to={
                                  "/squirrel/catalogues/edit-catalogue/" +
                                  cat.catalogue_id
                                }
                              >
                                <ModeEditOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Fab>
                          <Fab
                            size="small"
                            color="default"
                            sx={{ marginTop: -2, marginBottom: 1,marginLeft:1 }}
                            aria-label="add"
                            className="del-iconU"
                          >
                            <Tooltip title="delete">
                              <IconButton
                                aria-label="delete"
                                component={Link}
                                to={
                                  "/squirrel/catalogues/delete-catalogue/" +
                                  cat.catalogue_id +
                                  "/" +
                                  cat.catalogue_name
                                }
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Fab>
                        </>
                      ) : null}
                      {/* <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 4 }}
                      >
                        {cat.description}
                      </Typography> */}


                     
                        

                    </Box>
                  
                  </Card>
                  </div>
                  </ReactCardFlip>

                </Grid>
              ))
            : null
          // <Backdrop
          //     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          //     open
          //   >
          //     <CircularProgress color="inherit" />
          //   </Backdrop>
        }
      </Grid>
    </>
  );
}

export default Catalogue;
