import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'; // Routing 
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './store/reducer'
import './assets/scss/style.scss'; 

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),thunk
});
// const middleware = [...getDefaultMiddleware(), thunk];
// // const composeEnhanced = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // The first one is to make the chrome dev extension work
// // // const store = createStore(reducer, composeEnhanced(applyMiddleware(thunk))); 
// const store = configureStore({
//     reducer,
//     middleware,
//     devTools: process.env.NODE_ENV !== 'production',
// });
  
const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter> 
  </Provider>
);