import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import TextField from '@mui/material/TextField';


const initialFValues = {
    id: 0,
    name: '',
    description: '',
    address: '',
    pin: '',
    country: '',
    primaryContact: '',
    contactEmail: '',
    website: '',
    serviceURL: '',
    category: '',
    logoURL:''

} 

export default function CCForm(props) {
    const { addOrEdit, recordForEdit, organization } = props
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }
  
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                     <Controls.InputTextArea
                        name="description"
                        label="Description"
                        required={true}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />

                     <Controls.Input
                        name="lastname"
                        label="Last Name"
                        value={values.lastname}
                        onChange={handleInputChange}
                        error={errors.lastname}
                    />
                     <Controls.InputTextArea
                        name="address"
                        label="Address"
                        required={true}
                        value={values.address}
                        onChange={handleInputChange}
                        error={errors.address}
                    />

                    <Controls.Input
                        name="contactEmail"
                        label="Contact Email"
                        value={values.contactEmail}
                        onChange={handleInputChange}
                        error={errors.contactEmail}
                    />
                    <Controls.Input
                        name="pin"
                        label="Pin"
                        value={values.pin}
                        onChange={handleInputChange}
                        error={errors.pin}
                    />
                    <Controls.Input
                        name="country"
                        label="Country"
                        value={values.country}
                        onChange={handleInputChange}
                        error={errors.country}
                    />
                    <Controls.Input
                        name="primaryContact"
                        label="primaryContact"
                        value={values.primaryContact}
                        onChange={handleInputChange}
                        error={errors.primaryContact}
                    />
                    <Controls.InputTextArea
                        name="website"
                        label="Website"
                        required={true}
                        value={values.website}
                        onChange={handleInputChange}
                        error={errors.website}
                    />
                    <Controls.InputTextArea
                        name="serviceURL"
                        label="serviceURL"
                        required={true}
                        value={values.serviceURL}
                        onChange={handleInputChange}
                        error={errors.serviceURL}
                    />
                    <Controls.InputTextArea
                        name="logoURL"
                        label="logoURL"
                        required={true}
                        value={values.logoURL}
                        onChange={handleInputChange}
                        error={errors.logoURL}
                    />
                    <Controls.Input
                        name="category"
                        label="Category"
                        value={values.category}
                        onChange={handleInputChange}
                        error={errors.category}
                    />
                                     
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>

            </Grid>
        </Form>
    )
}
