import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import TextField from '@mui/material/TextField';


const initialFValues = {
    id: 0,
    organization: '-1',
    firstname: '',
    lastname: '',
    email: '',
    start_date: '',
    end_date: ''  
} 

export default function CCForm(props) {
    const { addOrEdit, recordForEdit, organization } = props
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }
  
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Select
                        name="organization"
                        label="Organization"
                        required={true}
                        value={values.organization}
                        options={organization}
                        onChange={handleInputChange}
                        error={errors.organization}
                    />
                    <Controls.Input
                        name="firstname"
                        label="First Name"
                        value={values.firstname}
                        onChange={handleInputChange}
                        error={errors.firstname}
                    />
                     <Controls.Input
                        name="lastname"
                        label="Last Name"
                        value={values.lastname}
                        onChange={handleInputChange}
                        error={errors.lastname}
                    />
                    <Controls.Input
                        name="email"
                        label="Email"
                        value={values.email}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                    <TextField
                                name="start_date"
                                label="Start Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.start_date}
                                // value={values.purchaseDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.start_date}                 
                            />
                   <TextField
                                name="end_date"
                                label="End Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.end_date}
                                // value={values.purchaseDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.end_date}                 
                            />
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>

            </Grid>
        </Form>
    )
}
