import * as api from '../config/apiConf';
import axios from 'axios';


export const getIncidentType = () => ([
    { id: 'BUSINESS', title: 'BUSINESS' },
    { id: 'IT', title: 'IT' },
    { id: 'ASSET', title: 'ASSET' },
    { id: 'EVENT', title: 'EVENT' },
])


export const getStatusType = () => ([
    { id: 'OPEN', title: 'Open' },
    { id: 'CLOSED', title: 'Closed' },
    { id: 'ACTION_TAKEN', title: 'Action Taken' },
    { id: 'ACTION_PENDING', title: 'Action Pending' },
])

export const getStatusEvents = () => ([
    { id: 'DUMMY', title: 'DUMMY' },
    { id: 'VERIFIED', title: 'VERIFIED' },
    { id: 'UNVERIFIED', title: 'UNVERIFIED' },
    { id: 'DEFAULT', title: 'DEFAULT' },
])


export const getSeverityType = () => ([
    { id: 'CRITICAL', title: 'Critical' },
    { id: 'MAJOR', title: 'Major' },
    { id: 'MODERATE', title: 'Moderate' },
    { id: 'MINOR', title: 'Minor' },
    { id: 'INSIGNIFICANT', title: 'Insignificant' },
    { id: 'DEFAULT', title: 'Default' },
])

export function geIncidents(setIncident) {
    const token = localStorage.getItem('token');
    axios.get(api.getIncidentsURL, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            // const data = res.data.results["created_by"]['email']
            // console.log(data)
            // console.log(res.data)
            
            setIncident(res.data.results)
        })
}
export function getIncident(setIncident,incident_no,setValues) {
    const token = localStorage.getItem('token');
    axios.get(api.getIncidentsURL+`?id=${incident_no}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            const dataSet = res.data.results
            console.log(12345, dataSet)            
            setIncident(dataSet)
            const values = res.data.results
            console.log('new', values)  
            let dataset = {}
            dataSet.map((values, index) => {
                dataset = {
                    id : values.id,
                    title : values.title,
                    description : values.description,
                    category : values.category,
                    severityType :values.severityType,
                    incidentType : values.incidentType,
                    statusType : values.statusType,
                    statusEvents : values.statusEvents,    
                    infoChannel : values.infoChannel,
                    external_link : values.external_link
                }
                return null;
            })
            console.log("dataset",dataset)
            setValues(dataset)

        })
}
// export function getIncidentdata(setIncidentdata,incident_no) {
//     const token = localStorage.getItem('token');
//     axios.get(`${settings.API_SERVER}/squirrel/admin-app/incident?incident_no=${incident_no}`, {
//         headers: { 'Authorization': `Bearer ${token}` }
//     }).then(
//         res => {
//             const values = res.data.results
//             console.log('new', values)     
//             const dataset = {
//                 title : values.title,
//                 description : values.description,
//                 category : values.category,
//                 severityType :values.severityType,
//                 incidentType : values.incidentType,
//                 statusType : values.statusType,
//                 statusEvents : values.statusEvents,    
//                 infoChannel : values.infoChannel,
//                 external_link : values.external_link
//             }
//             setIncidentdata(dataset)
//         })
// }
export function createIncident(values, setNotify) {
    const dataset = {
        title : values.title,
        description : values.description,
        category : values.category,
        severityType :values.severityType,
        incidentType : values.incidentType,
        statusType : values.statusType,
        statusEvents : values.statusEvents,    
        infoChannel : values.infoChannel,
        external_link : values.external_link
    }

    const token = localStorage.getItem('token'); 
    axios.post(api.createIncidentsURL,dataset, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            setNotify({
                isOpen: true,
                message: "Created Successfully",
                type: "success"
            })
            // return result 
            window.location = "/squirrel/squirrel-incident-services"

        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: "" + err,
                type: "error"
            })
        });
}
export function updateIncident(values, setNotify,id) {
    const dataset = {
        title : values.title,
        description : values.description,
        category : values.category,
        severityType :values.severityType,
        incidentType : values.incidentType,
        statusType : values.statusType,
        statusEvents : values.statusEvents,    
        infoChannel : values.infoChannel,
        external_link : values.external_link
    }

    const token = localStorage.getItem('token'); 
    axios.put(api.updateIncidentsURL+`/${values.id}`,dataset, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            setNotify({
                isOpen: true,
                message: "Updated Successfully",
                type: "success"
            })
            // return result 
            window.location = `/squirrel/squirrel-incident/view-incident/${id}`

        }).catch(err => {
            // console.log(err)
            setNotify({
                isOpen: true,
                message: "" + err,
                type: "error"
            })
        });
}
export function getStatusColor(status) {
    // alert(status)
    if (status === 'OPEN') {
        return 'secondary'
    }
    else if (status === 'CLOSED') {
        return 'success'
    }
    else if (status === 'CRITICAL') {
        console.log(status)
        return 'error'
    }
    else if (status === 'MAJOR') {
        return 'error'
    }
    else if (status === 'MODERATE') {
        return '#f57c00'
    }
    else if (status === 'MINOR') {
        return '#ccff90'
    }
    else{
        return 'info'
    }

}

export function getColor(status) {
    // alert(status)
    if (status === 'OPEN') {
        return '#2196f3'
    }
    else if (status === 'CLOSED') {
        return '#4caf50'
    }
    else if (status === 'CRITICAL') {
        console.log(status)
        return '#e64a19'
    }
    else if (status === 'MAJOR') {
        return '#d84315'
    }
    else if (status === 'MODERATE') {
        return '#f57c00'
    }
    else if (status === 'MINOR') {
        return '#ccff90'
    }
    else {
        return '#81d4fa'
    }
}


export function geIncidentFilter(setIncident,type) {
    const token = localStorage.getItem('token');
    axios.get(api.getIncidentsURL+`?incidentType=${type}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    }).then(
        res => {
            // const data = res.data.results["created_by"]['email']
            // console.log(data)
            // console.log(res.data)
            
            setIncident(res.data.results)
        })
}


export function getAllIncidentCategories(setRecords) {
    const token = localStorage.getItem("token");
    axios
      .get(api.getIncidentURL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(123456, res.data.results)
        const result = res.data.results;
        const dataSet = [];
        // console.log(result)
        result.map((item, index) => {
          dataSet.push({
            id: item.category_id,
            title: item.title,
            description: item.description,
            application: item.application,
            parent: item.parent,
            category_string: item.category_string,
          });
          return null;
        });
        setRecords(dataSet);
      });
  }