import { Backdrop, Box, Card, CircularProgress, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import * as React from 'react';
import * as locService from '../../../../services/locationServices'
import listicon1 from "../../../../assets/img/list.png"



function ConfigurationInfo(props) { 
    const {id} = props
    const [config, setConfig] = React.useState(null)
    React.useEffect(() => {
        locService.getConfigInfo(setConfig,id)      
    }, []);
    return (<>
        {
            config ?
            
            
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                   
                        <List sx={{ width: '100%', bgcolor: '#EBF0F0',borderRadius:5 }} >
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>    
                            <img src={listicon1}  width="20" height="20" />          
                                <ListItemText sx={{ width: '150px',marginLeft:2 }} id="switch-list-label-wifi" primary="Store" />
                                {config.site_code} ({config.posID})
                            </ListItem >
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />                      
                                <ListItemText sx={{ width: '150px',marginLeft:2 }} id="switch-list-label-wifi" primary="SBK Width" />
                                {config.sbk_width}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Edge Location" />
                                {config.edge_location}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Camera Angle" />
                                {config.camera_angle}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Network Type" />
                                {config.networkType}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="WIFI SSID" />
                                {config.wifi_ssid}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Camera Position Table" />
                                <Box sx={{ flexGrow: 1,marginLeft:8, textAlign: 'right' }}>
                                {config.Camera_position_table}
                                </Box>
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Region of Interest Table" />
                                {config.Region_of_interest_table}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />                      
                                <ListItemText sx={{ width: '150px',marginLeft:2 }} id="switch-list-label-wifi" primary="Image Polling Interval" />
                                {config.image_polling_interval}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="SKU Threshold Amber" />
                                {config.SKU_threshold_amber}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="SKU Threshold Red" />
                                {config.SKU_threshold_red}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="SKU Rowding Threshold" />
                                {config.SKU_rowding_threshold}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="SKU Alerting Channel" />
                                {config.SKU_alerting_channel}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Edge Server Heartbeat Interval" />
                                {config.Edge_server_heartbeat_interval}
                            </ListItem>
                            
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Edge Server Sync Interval" />
                                {config.Edge_server_sync_interval}
                            </ListItem>
                            <ListItem sx={{ '&:hover': { backgroundColor: '#CAACCD',borderRadius:2 } }}>
                            <img src={listicon1}  width="20" height="20" />  
                                <ListItemText sx={{ width: '150px',marginLeft:2 }}  id="switch-list-label-bluetooth" primary="Inference Engine Interval" />
                                {config.Inference_engine_interval}
                            </ListItem>
                        </List>
                       
                    </Grid>
                </Grid>
                
                    
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress color="inherit" />
              </Backdrop>
        }
        
    </>)

}

export default ConfigurationInfo