import * as settings from './settings';

export const SESSION_DURATION = settings.SESSION_DURATION



export const loginRequestURL = `${settings.API_SERVER}/api/v2/auth/users/login`
export const accessRequestURL = `${settings.API_SERVER}/api/v2/auth/users/access-request`
export const createUserRequestURL = `${settings.API_SERVER}/api/v2/auth/users/create-user`
export const getUsersRequestURL = `${settings.API_SERVER}/api/v2/auth/users/get-users`
export const getUserRequestURL = `${settings.API_SERVER}/api/v2/auth/users/get-me`

export const getUpdatePasswordURL = `${settings.API_SERVER}/api/v2/auth/users/update-password`
export const getActivityURL = `${settings.API_SERVER}/api/v2/auth/users/activities-log`
export const getApprvalURL = `${settings.API_SERVER}/api/v2/auth/users/users-approval`


export const createOrganizationURL = `${settings.API_SERVER}/api/v2/organizations/create`
export const getOrganizationURL = `${settings.API_SERVER}/api/v2/organizations/read`
export const updateOrganizationURL = `${settings.API_SERVER}/api/v2/organizations/update`
export const deleteOrganizationURL = `${settings.API_SERVER}/api/v2/organizations/delete`

export const createStoresURL = `${settings.API_SERVER}/api/v2/stores/create`
export const getStoresURL = `${settings.API_SERVER}/api/v2/stores/read`
export const updateStoresURL = `${settings.API_SERVER}/api/v2/stores/update`
export const deleteStoresURL = `${settings.API_SERVER}/api/v2/stores/delete`

export const createAssetsURL = `${settings.API_SERVER}/api/v2/asset/create`
export const getAssetsURL = `${settings.API_SERVER}/api/v2/asset/read`
export const updateAssetsURL = `${settings.API_SERVER}/api/v2/asset/update`
export const deleteAssetsURL = `${settings.API_SERVER}/api/v2/asset/delete`
export const getAssetListURL = `${settings.API_SERVER}/api/v2/asset/data-list`

export const createUsecaseURL = `${settings.API_SERVER}/api/v2/asset/usecase/create`
export const getUsecaseURL = `${settings.API_SERVER}/api/v2/asset/usecase/read`
export const updateUsecaseURL = `${settings.API_SERVER}/api/v2/asset/usecase/update`
export const deleteUsecaseURL = `${settings.API_SERVER}/api/v2/asset/usecase/delete`

export const createCataloguesURL = `${settings.API_SERVER}/api/v2/catalogues/create`
export const getCataloguesURL = `${settings.API_SERVER}/api/v2/catalogues/read`
export const updateCataloguesURL = `${settings.API_SERVER}/api/v2/catalogues/update`
export const deleteCataloguesURL = `${settings.API_SERVER}/api/v2/catalogues/delete`

export const getLcURL = `${settings.API_SERVER}/api/v2/admin/location-category/read`
export const createLcURL = `${settings.API_SERVER}/api/v2/admin/location-category/create`
export const updateLcURL = `${settings.API_SERVER}/api/v2/admin/location-category/update`
export const deleteLcURL = `${settings.API_SERVER}/api/v2/admin/location-category/delete`

export const getCcURL = `${settings.API_SERVER}/api/v2/admin/catalogue-category/read`
export const createCcURL = `${settings.API_SERVER}/api/v2/admin/catalogue-category/create`
export const updateCcURL = `${settings.API_SERVER}/api/v2/admin/catalogue-category/update`
export const deleteCcURL = `${settings.API_SERVER}/api/v2/admin/catalogue-category/delete`

export const getBillURL = `${settings.API_SERVER}/api/v2/admin/bills/read`
export const createBillURL = `${settings.API_SERVER}/api/v2/admin/bills/create`
export const updateBillURL = `${settings.API_SERVER}/api/v2/admin/bills/update`
export const deleteBillURL = `${settings.API_SERVER}/api/v2/admin/bills/delete`

export const getServiceURL = `${settings.API_SERVER}/api/v2/admin/service/read`
export const createServiceURL = `${settings.API_SERVER}/api/v2/admin/service/create`
export const updateServiceURL = `${settings.API_SERVER}/api/v2/admin/service/update`
export const deleteServiceURL = `${settings.API_SERVER}/api/v2/admin/service/delete`

export const getContractURL = `${settings.API_SERVER}/api/v2/admin/contract/read`
export const createContractURL = `${settings.API_SERVER}/api/v2/admin/contract/create`
export const updateContractURL = `${settings.API_SERVER}/api/v2/admin/contract/update`
export const deleteContractURL = `${settings.API_SERVER}/api/v2/admin/contract/delete`

export const getPartnerURL = `${settings.API_SERVER}/api/v2/admin/partner/read`
export const createPartnerURL = `${settings.API_SERVER}/api/v2/admin/partner/create`
export const updatePartnerURL = `${settings.API_SERVER}/api/v2/admin/partner/update`
export const deletePartnerURL = `${settings.API_SERVER}/api/v2/admin/partner/delete`

export const opcuaURL =  `${settings.API_SERVER}/api/v2/admin/opcua_websocket_dash`

export const getAcURL = `${settings.API_SERVER}/api/v2/admin/asset_compute/read`
export const createAcURL = `${settings.API_SERVER}/api/v2/admin/asset_compute/create`
export const updateAcURL = `${settings.API_SERVER}/api/v2/admin/asset_compute/update`
export const deleteAcURL = `${settings.API_SERVER}/api/v2/admin/asset_compute/delete`

export const getLcoURL = `${settings.API_SERVER}/api/v2/admin/location_compute/read`
export const createLcoURL = `${settings.API_SERVER}/api/v2/admin/location_compute/create`
export const updateLcoURL = `${settings.API_SERVER}/api/v2/admin/location_compute/update`
export const deleteLcoURL = `${settings.API_SERVER}/api/v2/admin/location_compute/delete`


export const getIncidentURL = `${settings.API_SERVER}/api/v2/admin/incident-category/read`
export const createIncidentURL = `${settings.API_SERVER}/api/v2/admin/incident-category/create`
export const updateIncidentURL = `${settings.API_SERVER}/api/v2/admin/incident-category/update`
export const deleteIncidentURL = `${settings.API_SERVER}/api/v2/admin/incident-category/delete`



export const getWicURL = `${settings.API_SERVER}/api/v2/admin/widget-layout/get`
export const getWidgetDataURL = `${settings.API_SERVER}/api/v2/admin/widget-data/get`
export const createWicURL = `${settings.API_SERVER}/api/v2/admin/widget-config/create`
export const updateWicURL = `${settings.API_SERVER}/api/v2/admin/widget-config/update`
export const deleteWicURL = `${settings.API_SERVER}/api/v2/admin/widget-config/delete`


export const getAssetStatusURL = `${settings.API_SERVER}/api/v2/admin/assets-status-view/get`
export const getSmallWidgetURL = `${settings.API_SERVER}/api/v2/admin/dashboard-small-widgets/get`
export const getQueriesURL = `${settings.API_SERVER}/api/v2/admin/get-queries/get`
export const getRefillURL = `${settings.API_SERVER}/api/v2/admin/arefill-time-based/get`

export const getInventoryURL = `${settings.API_SERVER}/api/v2/inventory/get-inventory`
export const getInventoryDatasetURL = `${settings.API_SERVER}/api/v2/inventory/get-inventory-dataset`
export const getTimeTravelURL = `${settings.API_SERVER}/api/v2/inventory/get-inventory-time-travel`





export const getIncidentsURL = `${settings.API_SERVER}/api/v2/incident-management/incident/read`
export const createIncidentsURL = `${settings.API_SERVER}/api/v2/incident-management/incident/create`
export const updateIncidentsURL = `${settings.API_SERVER}/api/v2/incident-management/incident/update`
export const deleteIncidentsURL = `${settings.API_SERVER}/api/v2/incident-management/incident/delete`