import { Avatar, AvatarGroup, Backdrop, Badge, Card, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import * as locService from '../../../../services/locationServices'
import StoreIcon from '@mui/icons-material/Store';
import KitchenIcon from '@mui/icons-material/Kitchen';



function StoreInfo(props) {  
    const {id} = props
    const [location, setLocation] = React.useState(null)
    React.useEffect(() => {
        locService.getLocationInfo(setLocation,id)      
    }, []);
   
    return (<>
        <Grid container spacing={2}>
            {
                location ? 
                <><Grid item xs={12} md={8}>
                        <Box sx={{ width: '100%' }}>
                            <Stack spacing={2}>
                                <Card sx={{ maxWidth: '100%', height: '100%' }} key="info">
                                    <CardHeader
                                        avatar={<Avatar aria-label={location.location_category}>
                                            {
                                                location.location_category === 'Shop' ?
                                                    <Tooltip title={location.location_category}>
                                                        <StoreIcon />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title={location.location_category}>
                                                        <KitchenIcon />
                                                    </Tooltip>
                                          } 
                                        </Avatar>}
                                        title={location.location_name}
                                        subheader={location.site_code} />
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={location.pictureURL}
                                        alt={location.location_name} />
                                    <CardContent>
                                        {/* <Divider sx={{marginBottom:2}} textAlign="left">About {location.location_name}</Divider> */}

                                        <Typography variant="body2" color="text.secondary">
                                            {location.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card sx={{ maxWidth: '100%', height: '100%' }} key="map">
                                    <CardHeader
                                        // avatar={
                                        //     <Avatar sx={{ bgcolor: 'red' }} aria-label={location.location_category}>
                                        //       {location.location_category}
                                        //     </Avatar>
                                        //   }
                                        title="Map"
                                        subheader={location.location_name + "," + location.street + "," + location.zipcode} />
                                    <iframe className="w-100" title="map" src={location.mapURL} height="500"></iframe>
                                </Card>
                            </Stack>
                        </Box>
                    </Grid><Grid item xs={12} md={4}>
                            <Box sx={{ width: '100%' }}>
                                <Stack spacing={2}>
                                    <Paper sx={{ maxWidth: '100%', height: '100%', padding: 2 }} key={1}>
                                        <Divider sx={{ marginBottom: 2 }} textAlign="left">About {location.location_name}</Divider>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem alignItems="flex-start">
                                                <ListItemText
                                                    primary="Store Code :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {location.site_code}
                                                        </Typography>
                                                        {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                                        {" --Cost Center : " + location.location_code}
                                                    </React.Fragment>} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem alignItems="flex-start">
                                                <ListItemText
                                                    primary="Organization :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {location.organization}
                                                        </Typography>
                                                        {/* {" — Wish I could come, but I'm out of town this…"} */}
                                                    </React.Fragment>} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem alignItems="flex-start">

                                                <ListItemText
                                                    primary="Store Status :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {<Badge badgeContent={location.status} sx={{ marginLeft: 3 }} color={location.status === 'Online' ? "success" : "error"} />}
                                                        </Typography>

                                                    </React.Fragment>} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem alignItems="flex-start">

                                                <ListItemText
                                                    primary="Category :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {location.location_category} {location.replenishing_store === null ? '' : '<- ' + location.replenishing_store}
                                                        </Typography>

                                                    </React.Fragment>} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem alignItems="flex-start">

                                                <ListItemText
                                                    primary="PosID :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {location.posID}
                                                        </Typography>

                                                    </React.Fragment>} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem alignItems="flex-start">

                                                <ListItemText
                                                    primary="Address :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {location.address}
                                                        </Typography>
                                                        <br />
                                                        {location.street + ", " + location.house_number}
                                                        <br />
                                                        {"Zipcode : " + location.zipcode}
                                                        <br />
                                                        {"Contact : "}

                                                    </React.Fragment>} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem alignItems="flex-start">

                                                <ListItemText
                                                    primary="Assets count :"
                                                    secondary={<React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {location.asset_count}
                                                        </Typography>

                                                    </React.Fragment>} />
                                            </ListItem>
                                        </List>
                                    </Paper>
                                </Stack>
                            </Box>
                        </Grid></>
                    
                    :
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            }
            
        </Grid>
    </>)

}

export default StoreInfo