import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import Login from "./components/pages/auth/Login";
import LoginLayout from "./components/layout/auth/LoginLayout";

import Dashboard from "./components/pages/dashboard/Dashboard";
import SquirrelLayout from "./components/layout/squirrel";
import Organization from "./components/pages/organization/Organization";
import AddOrganizationForm from "./components/pages/organization/forms/addOrganization";
// import Example from "./components/pages/admin/region";
import LocationCategory from "./components/pages/admin/categories/location";
import CatalogueCategory from "./components/pages/admin/categories/catalogue";
import EditOrganizationForm from "./components/pages/organization/forms/editOrganization";
import IncidentCategory from "./components/pages/admin/incidentCategory";
import DeleteOrganizationForm from "./components/pages/organization/forms/deleteOrganization";
import OrganizationCard from "./components/pages/organization/forms/viewOrganizations";
import Locations from "./components/pages/locations/locations";
import AddLocationForm from "./components/pages/locations/partials/addLocation";
import Assets from "./components/pages/assets";
import Catalogue from "./components/pages/catalogues";
import IncidentHome from "./components/pages/incident/incidentHome";
import AddCatalogueForm from "./components/pages/catalogues/partials/addCatalogue";
import EditCatalogueForm from "./components/pages/catalogues/partials/editCatalogue";
import ViewCatalogueForm from "./components/pages/catalogues/partials/viewCatalogue";
import DeleteCatalogueForm from "./components/pages/catalogues/partials/deleteCatalogue";
import DeleteLocationForm from "./components/pages/locations/partials/deleteLocation";
import EditLocationForm from "./components/pages/locations/partials/editLocation";
import AddAssetForm from "./components/pages/assets/partials/addAsset";
import DeleteAssetForm from "./components/pages/assets/partials/deleteAsset";
import EditAssetForm from "./components/pages/assets/partials/editAsset";
import AssetCompute from "./components/pages/admin/asset_compute";
import LocationCompute from "./components/pages/admin/location_compute";
import Bill from "./components/pages/admin/bill";
import Service from "./components/pages/admin/service";
import Partner from "./components/pages/admin/partner";
import Contract from "./components/pages/admin/contract";
import ViewLocation from "./components/pages/locations/partials/viewLocation";
import IncidentFilter from "./components/pages/incident/IncidentFiltered";
import CreateIncident from "./components/pages/incident/incidentCreator";
import ViewIncident from "./components/pages/incident/viewIncident";
import Usecase from "./components/pages/admin/usecase";
import FlowConfigurator from "./components/pages/locations/views/FlowConfigurator";

const PrivateRoute = ({ isAuthenticated }) => {
  const location = useLocation();
  const authToken = localStorage.getItem("token");
  console.log("1233ds", isAuthenticated);
  return isAuthenticated || authToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
};

function Urls(props) {
  // const location = useLocation();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <LoginLayout>
            <Login {...props} />
          </LoginLayout>
        }
      />

      <Route element={<PrivateRoute isAuthenticated={props.isAuthenticated} />}>
        <Route
          exact
          path="/squirrel/dashboard"
          element={
            <SquirrelLayout>
              <Dashboard {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/organizations"
          element={
            <SquirrelLayout>
              <Organization {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/organizations/add-new-organization"
          element={
            <SquirrelLayout>
              <AddOrganizationForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/organizations/edit-organization/:id"
          element={
            <SquirrelLayout>
              <EditOrganizationForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/organizations/view-organization/:id"
          element={
            <SquirrelLayout>
              <OrganizationCard {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/organizations/delete-organization/:id/:organization"
          element={
            <SquirrelLayout>
              <DeleteOrganizationForm {...props} />
            </SquirrelLayout>
          }
        />
        {/* <Route
          exact
          path="/squirrel/admin-panel/onboard/regions"
          element={
            <SquirrelLayout>
              <Example {...props} />
            </SquirrelLayout>
          }
        /> */}
        <Route
          exact
          path="/squirrel/stores"
          element={
            <SquirrelLayout>
              <Locations {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/stores/add-store"
          element={
            <SquirrelLayout>
              <AddLocationForm {...props} />
            </SquirrelLayout>
          }
        />
<Route
          exact
          path="/squirrel/flow/configurator"
          element={
            <SquirrelLayout>
              <FlowConfigurator {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/stores/delete-store/:id/:location"
          element={
            <SquirrelLayout>
              <DeleteLocationForm  {...props} />
            </SquirrelLayout>
          }
        />
         <Route
          exact
          path="/squirrel/stores/edit-store/:id"
          element={
            <SquirrelLayout>
              <EditLocationForm  {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/stores/view-store/:id"
          element={
            <SquirrelLayout>
              <ViewLocation  {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/assets"
          element={
            <SquirrelLayout>
              <Assets {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/assets/add-asset"
          element={
            <SquirrelLayout>
              <AddAssetForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/assets/delete-asset/:id/:asset"
          element={
            <SquirrelLayout>
              <DeleteAssetForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/assets/edit-asset/:id"
          element={
            <SquirrelLayout>
              <EditAssetForm {...props} />
            </SquirrelLayout>
          }
        />

        <Route
          exact
          path="/squirrel/catalogues"
          element={
            <SquirrelLayout>
              <Catalogue {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/catalogues/add-catalogue"
          element={
            <SquirrelLayout>
              <AddCatalogueForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/catalogues/edit-catalogue/:id"
          element={
            <SquirrelLayout>
              <EditCatalogueForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/catalogues/view-catalogue/:id"
          element={
            <SquirrelLayout>
              <ViewCatalogueForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/catalogues/delete-catalogue/:id/:catalogue"
          element={
            <SquirrelLayout>
              <DeleteCatalogueForm {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/squirrel-incident-services"
          element={
            <SquirrelLayout>
              <IncidentHome {...props} />
            </SquirrelLayout>
          }
        />
         <Route
          exact
          path="/squirrel/squirrel-incident/create"
          element={
            <SquirrelLayout>
              <CreateIncident {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/squirrel-incident-services/view-incident/:id"
          element={
            <SquirrelLayout>
              <ViewIncident {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/squirrel-incident-services/incidents/:incident_type"
          element={
            <SquirrelLayout>
              <IncidentFilter {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/admin-panel/bills"
          element={<SquirrelLayout><Bill {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/services"
          element={<SquirrelLayout><Service {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/partners"
          element={<SquirrelLayout><Partner {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/contracts"
          element={<SquirrelLayout><Contract {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/onboard/asset-compute"
          element={<SquirrelLayout><AssetCompute {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/onboard/location-compute"
          element={<SquirrelLayout><LocationCompute {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/onboard/usecase"
          element={<SquirrelLayout><Usecase {...props} /></SquirrelLayout>}
        />
        <Route
          exact
          path="/squirrel/admin-panel/onboard/store-categories"
          element={
            <SquirrelLayout>
              <LocationCategory {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/admin-panel/onboard/catalogue-categories"
          element={
            <SquirrelLayout>
              <CatalogueCategory {...props} />
            </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/squirrel/admin-panel/onboard/incident-categories"
          element={
            <SquirrelLayout>
              <IncidentCategory {...props} />
            </SquirrelLayout>
          }
        />
      </Route>
    </Routes>
  );
}

export default Urls;
