import React from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
    
// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const Footer = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    return (
            <footer className="footer" sx={{background: theme.palette.eathappy.eh_sand_yellow}}>
                <div className="footer-body" >
                    <div className="right-panel">
                        ©<script>document.write(new Date().getFullYear())</script>  Squirrel Smart Edge, Made
                         by <Link to="#">Difinative Technologies.</Link>.
                    </div>
                </div>
            </footer>
        )
    }
    
    export default Footer
    