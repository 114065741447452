import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import Controls from "../../../controls/Controls";
import { Form, useForm } from '../../../useForm';


const initialFValues = {
    id: 0,
    title: '',
    application: '',
    parent: '',
    category_string: '',
    description: '',
}

export default function CCForm(props) {
    const { addOrEdit, recordForEdit } = props

    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="title"
                        label="Title"
                        value={values.title}
                        onChange={handleInputChange}
                        error={errors.title}
                    />
                    <Controls.Input
                        name="application"
                        label="Application"
                        value={values.application}
                        onChange={handleInputChange}
                        error={errors.application}
                    />
                    <Controls.Input
                        name="parent"
                        label="Parent"
                        value={values.parent}
                        onChange={handleInputChange}
                        error={errors.parent}
                    />
                    <Controls.Input
                        label="Description"
                        name="description"
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                     <Controls.Input
                        name="category_string"
                        label="Category"
                        value={values.category_string}
                        onChange={handleInputChange}
                        error={errors.category_string}
                    />
                   <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>
              
            </Grid>
        </Form>
    )
}
