
import * as api from "../config/apiConf";
import axios from "axios";
import countryList from "react-select-country-list";

export const getOrganizationsType = () => [
  { id: "Food Retailer", title: "Food Retailer" },
];

export const getCountries = () => [{ id: "Germany", title: "Germany" }];
export function getCountriesData() {
  const options = countryList().getData();
  // console.log("options", options)
  const countries = [];
  options.map((item, index) => {
    countries.push({ id: item.value, title: item.label });
    return null;
  });
  return countries;
}

export function findCountryByCOde(shortcode) {
  const countries = countryList().getData();
  
  return countries.find((country) => country.value === shortcode).label;
}

export function createOrganization(data, setNotify) {
  const token = localStorage.getItem("token");
  const payload = {
    organization: data.organization_name,
    organization_nature: data.org_type,
    description: data.description,
    email: data.email,
    address: data.address,
    country: data.country,
    primary_contact_number: data.contactNo,
    website: data.website,
    serviceURL: data.serviceURL,
    logo: data.logoURL,
  };

  // console.log(payload)
  axios
    .post(api.createOrganizationURL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Created Successfully",
        type: "success",
      });
      window.location = "/squirrel/organizations";
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function getOrganizations(setOrganization) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getOrganizationURL, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data.results)
      setOrganization(res.data.results);
    });
}
export function getOrganization(setValues, id) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getOrganizationURL+`/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log(123456, res.data)
      const results = {
        id: 0,
        organization_name: res.data.organization,
        org_type: res.data.organization_nature,
        description: res.data.description,
        email: res.data.email,
        address: res.data.address,
        country: res.data.country,
        contactNo: res.data.primary_contact_number,
        website: res.data.website,
        serviceURL: res.data.serviceURL,
        logoURL: res.data.logo,
      };
      setValues(results);
    });
}

export function getOrganizationData(setOrganization, id) {
  const token = localStorage.getItem("token");
  axios
    .get(api.getOrganizationURL+`/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      setOrganization([res.data]);
    });
}

export async function updateOrganization(data, setNotify, id) {
  const token = localStorage.getItem("token");
  const payload = {
    organization: data.organization_name,
    organization_nature: data.org_type,
    description: data.description,
    email: data.email,
    address: data.address,
    country: data.country,
    primary_contact_number: data.contactNo,
    website: data.website,
    serviceURL: data.serviceURL,
    logo: data.logoURL,
  };

  await axios
    .put(
      api.updateOrganizationURL+`/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      // console.log(res)
      setNotify({
        isOpen: true,
        message: "Update Successfully",
        type: "success",
      });
      window.location = "/squirrel/organizations";
      // window.location.reload(false);
      // return result
    })
    .catch((err) => {
      // console.log(err)
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}

export function deleteOrganization(setNotify, id) {
  const token = localStorage.getItem("token");
  axios
    .delete(api.deleteOrganizationURL+`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res);
      if (res.data.message === "Not Allowed") {
        setNotify({
          isOpen: true,
          message: "You have no permission to Delete",
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Organization Deleted",
          type: "success",
        });
        window.location = "/squirrel/organizations";
      }

      // return result
    })
    .catch((err) => {
      console.log(err);
      setNotify({
        isOpen: true,
        message: "" + err,
        type: "error",
      });
    });
}
