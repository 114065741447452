// material-ui
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

// project imports
import NavGroup from './NavGroup';
import menuItem from  '../../menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = (auth) => {
    // console.log("auth inside menu",auth)
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} auth={auth} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};
MenuList.propTypes = {
    auth:PropTypes.object
};
export default MenuList;
