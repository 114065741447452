import { Button, Card, Grid, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import * as assetService from '../../../../services/assetServices'
import TextField from '@mui/material/TextField';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import Notification from '../../../ui/Notifications';
import { styled } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  }, 
}));


function DeleteAssetForm(props) { 
    const isbutton = false
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const params = useParams()
    function deleteAsset() {
        assetService.deleteAsset(setNotify,params.id)
    }
    return (
        <>
           <Stack m={3} gap={3}>
          <StyledCard hovershadow={10}>
            <Grid
              sx={{
                display: "flex",
                alignItems: "left",
                padding: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" color="inherit">
                Delete Asset
              </Typography>
              <Button
                component={Link}
                to="/squirrel/assets"
                variant="contained"
                color="secondary"
              >
                <KeyboardBackspaceOutlinedIcon />
              </Button>
            </Grid>
          </StyledCard>
        </Stack>
            <Grid container>
                <Grid xs={12}>
                    <Form>
                        <Card sx={{padding:4}}>
                            <Typography variant="h3" gutterBottom component="div">
                                Are you sure?
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                Are you sure you want to delete the selected Asset? All of the objects and their related items will be deleted:
                                <br />
                                Asset Name : {params.asset}
                            </Typography>
                            {/* <Button type="submit">Delete</Button> */}
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deleteAsset}>
                                    Delete
                                </Button>
                            </Stack>
                        </Card>
                    </Form>
                    
                </Grid>
            </Grid>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />

        </>
    )
}

export default DeleteAssetForm