import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Grid,
    Stack,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
  } from "@mui/material";
  import Controls from '../../../controls/Controls';
  import AddIcon from "@mui/icons-material/Add";
  import Popup from "../../../ui/Popup";
  import useTable from "../../../ui/useTable";
  import { useEffect, useState } from "react";
  import CloseIcon from "@mui/icons-material/Close";
  import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
  import * as lcServices from "../../../../services/sqadmin";
  import SCForm from "./CategoryForm";
  import Notification from "../../../ui/Notifications";
  import DeleteIcon from "@mui/icons-material/Delete";
  import TableContainer from "@mui/material/TableContainer";
  import { Form } from "../../../useForm";
  import { styled } from "@mui/material/styles";
  
  const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
    backgroundColor: "transparent",
  
    ":hover": {
      boxShadow: theme.shadows[hovershadow],
    },
  }));
  
  const headCells = [
    { id: "category", label: "Category" },
    { id: "description", label: "Description" },
    { id: "actions", label: "Actions", disableSorting: true },
  ];
  function IncidentCategory(props) {
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [recordForDelete, setRecordForDelete] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [records, setRecords] = useState(null);
    const [notify, setNotify] = useState({
      isOpen: false,
      message: "",
      type: "info",
    });
    const [filterFn, setFilterFn] = useState({
      fn: (items) => {
        return items;
      },
    });
    const [isDelete, setIsDelete] = useState(false);
  
    useEffect(() => {
      lcServices.getAllIncidentCategories(setRecords);
    }, []);
  
    const addNewCategory = () => {
      // console.log("check")
      setOpenPopup(true);
      setRecordForEdit(null);
    };
    const editCategory = (item) => {
      // console.log("check")
      setRecordForEdit(item);
      setOpenPopup(true);
    };
    const deleteCategory = (item) => {
      // console.log("check")
      setRecordForDelete(item);
      setIsDelete(true);
    };
    const addOrEdit = (category, resetForm) => {
      if (category.id == 0) {
        lcServices.createIncidentCategory(category, setNotify, setRecords);
        // lcServices.getAllCategories(setRecords)
      } else {
        lcServices.updateIncidentCategory(category, setNotify, setRecords);
      }
      resetForm();
      setRecordForEdit(null);
      setOpenPopup(false);
    };
    const deletOnCategory = () => {
      lcServices.deleteIncidentCategory(recordForDelete, setNotify, setRecords);
      // lcServices.getAllCategories(setRecords)
      setRecordForDelete(false);
      setIsDelete(false);
    };
  
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
      useTable(records, headCells, filterFn);
    return (
      <>
        <Stack m={3} gap={3}>
          <StyledCard hovershadow={10}>
            <Grid
              sx={{
                display: "flex",
                alignItems: "left",
                padding: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" color="inherit">
                Incident Category
              </Typography>
            </Grid>
          </StyledCard>
          {records ? (
            <StyledCard hovershadow={10}>
              <Toolbar>
                <Controls.Button
                  text="Add"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={addNewCategory}
                />
              </Toolbar>
              <TableContainer sx={{ maxHeight: 500, padding: 3 }}>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.category}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>
                          <Controls.ActionButton
                            color="primary"
                            onClick={() => {
                              editCategory(item);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            color="secondary"
                            onClick={() => {
                              deleteCategory(item);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </TableContainer>
            </StyledCard>
          ) : (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Stack>
  
        <Popup
          title="Incident Category Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <SCForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
        </Popup>
        <Popup
          title="Delete Category"
          openPopup={isDelete}
          setOpenPopup={setIsDelete}
        >
          <Form>
            <Card sx={{ padding: 4 }}>
              <Typography variant="h3" gutterBottom component="div">
                Are you sure?
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                Are you sure you want to delete the selected Category? All of the
                objects and their related items will be deleted!!!
              </Typography>
              {/* <Button type="submit">Delete</Button> */}
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={deletOnCategory}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          </Form>
        </Popup>
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  }
  
  export default IncidentCategory;
  