import React, { useState } from 'react'
// import * as settings from '../settings';
import { useParams } from "react-router-dom"
import { Button, Card, CardActions, CardContent, Paper, Backdrop, CircularProgress ,Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import * as assetService from '../../../../services/assetServices'
import TextField from '@mui/material/TextField';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import Notification from '../../../ui/Notifications';
import { styled } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));


const initialFValues = {
    asset_id: 0,
    asset_name: '',
    shelf: '-1',
    asset_position:'',
    brand:'',
    asset_model:'',
    asset_category:"-1",
    description:"",
    purchaseDate:new Date(),
    deployementDate:new Date(),
    asset_ip:'NA',
    confForJetson:'',
    location: '-1',
    serial_no: '',
    mac_id:'',
    parent:'-1',
    usecases:'',
    
}

function EditAssetForm(props) {
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
    const [location, setLocation] = useState([])
    const [usecase, setUsecase] = useState(null)
    const [parent, setParent] = React.useState(null)

    React.useEffect(() => {  
        assetService.getLocation(setLocation)
    }, []);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('asset_name' in fieldValues)
            temp.asset_name = fieldValues.asset_name ? "" : "This field is required."
        if ('location' in fieldValues)
            temp.location = fieldValues.location ? -1 : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const isbutton = false
    const params = useParams();
    // console.log("id =", params)
    React.useEffect(() => {
        assetService.getAssetInfo(setValues,params.id)      
    }, []);
    React.useEffect(() => {
        assetService.getParent(setParent)      
    }, []);
    React.useEffect(() => {
        assetService.getUsecase(setUsecase)      
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(23, skucode)
        assetService.updateAsset(values,setNotify,params.id) 
    }
    console.log(11111111111,values,usecase)
    
    return (<>
        <Stack m={3} gap={3}>
        <StyledCard hovershadow={10}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Edit Asset
            </Typography>
                          <Button component={Link} to="/squirrel/assets" variant="contained" color="secondary">
                <KeyboardBackspaceOutlinedIcon />
              </Button>
          </Grid>
            </StyledCard> 
            </Stack>
        <Form>
            <Card>
            <CardContent>
                    <Grid container>
                    <Grid item xs={12}  md={3}>
                                        <Controls.Select
                                            name="asset_category"
                                            label="Category"
                                            value={values.asset_category}
                                            onChange={handleInputChange}
                                            options={assetService.getAssetCategory()}
                                            error={errors.asset_category}
                                            required={true}
                                        />
                        </Grid>
                {
                                    values.asset_category !== "Camera" ?
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                    id="asset_name"
                                                    name="asset_name"
                                                    label="Asset"
                                                    required={true}
                                                    value={values.asset_name}
                                                    onChange={handleInputChange}
                                                    // disabled={true}
                                                    size='small'
                                                    error={errors.asset_name}                                        
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                    id="asset_name"
                                                    name="asset_name"
                                                    label="Asset"
                                                    required={true}
                                                    value={values.asset_name}
                                                    onChange={handleInputChange}
                                                    disabled={true}
                                                    size='small'
                                                    error={errors.asset_name}                                        
                                            />
                                        </Grid>
                                }
                    <Grid item xs={12} md={3}>
                        <Controls.Select
                                                    name="location"
                                                    label="Store"
                                                    required={true}
                                                    value={values.location}
                                                    options={location}
                                                    onChange={handleInputChange}
                                                    error={errors.location}                                        
                        />
                        
                    </Grid>
                    <Grid item xs={12}  md={3}>
                                        <Controls.Input
                                            name="asset_ip"
                                            label="IP"
                                            value={values.asset_ip}
                                            onChange={handleInputChange}
                                            // options={asset_model}
                                            error={errors.asset_ip}
                                            required={true}
                                        />
                        </Grid>
                        
                    
                    
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                                    <Controls.InputTextArea
                                                    name="description"
                                                    label="Description"
                                                    required={true}
                                                    value={values.description}
                                                    onChange={handleInputChange}
                                                    error={errors.description}                                        
                                    />
                        </Grid>
                        {
                                    values.asset_category === "Camera" ?
                                        <><Grid item xs={12} md={3}>
                                            <Controls.Select
                                                name="shelf"
                                                label="Shelf"
                                                value={values.shelf}
                                                onChange={handleInputChange}
                                                options={assetService.getShelf()?assetService.getShelf():[{"id":'-1','title':"Select"}]}
                                                error={errors.shelf}
                                                required={true} />
                                        </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Controls.Select
                                                    name="asset_position"
                                                    label="Camera Identification"
                                                    value={values.asset_position}
                                                    onChange={handleInputChange}
                                                    options={assetService.getShelfPos(values.shelf)}
                                                    error={errors.asset_position}
                                                    required={true} />
                                            </Grid></>
                                        :
                                        values.asset_category === "Jetson" ? 
                                        <Grid item xs={6}>
                                                    <Controls.InputTextArea
                                                                    name="confForJetson"
                                                                    label="confForJetson"
                                                                    required={true}
                                                                    value={values.confForJetson}
                                                                    onChange={handleInputChange}
                                                                    error={errors.confForJetson}                                        
                                                    />
                                        </Grid>
                                            :
                                            values.asset_category === "Internet Router" ?
                                            <Grid item xs={12}  md={6}>
                                            <Controls.Input
                                                name="serial_no"
                                                label="Serial No."
                                                value={values.serial_no}
                                                onChange={handleInputChange}
                                                // options={asset_model}
                                                error={errors.serial_no}
                                                // required={true}
                                            />
                                                </Grid>
                                                :
                                                null
                                }

                        <Grid item xs={12} md={3}>
                        <Controls.Input
                                                    name="brand"
                                                    label="Brand"
                                                    required={true}
                                                    value={values.brand}
                                                    onChange={handleInputChange}
                                                    error={errors.brand}                                        
                        />
                        
                    </Grid>
                        <Grid item xs={12}  md={3}>
                                        <Controls.Input
                                            name="asset_model"
                                            label="Model"
                                            value={values.asset_model}
                                            onChange={handleInputChange}
                                            // options={asset_model}
                                            error={errors.asset_model}
                                            required={true}
                                        />
                        </Grid>
                        <Grid item xs={3} >
                            {/* <Controls.Input
                                            name="purchaseDate"
                                            label="purchaseDate"
                                            value={values.purchaseDate}
                                            onChange={handleInputChange}
                                            // options={catService.getSkuStatus()}
                                            error={errors.purchaseDate}
                                            required={true}
                                        /> */}
                        
                        <TextField
                                name="purchaseDate"
                                label="Purchase Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.purchaseDate}
                                value={values.purchaseDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.purchaseDate}                 
                            />
                        
                        </Grid>
                        <Grid item xs={3} >
                            {/* <Controls.Input
                                            name="deployementDate"
                                            label="deployementDate"
                                            value={values.deployementDate}
                                            onChange={handleInputChange}
                                            // options={catService.getSkuStatus()}
                                            error={errors.deployementDate}
                                            required={true}
                                        /> */}
                        <TextField
                                name="deployementDate"
                                label="Deployement Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.deployementDate}
                                value={values.deployementDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.deployementDate}                 
                            />
                        </Grid>

                    </Grid>
                    <Grid container>
                            {
                                    values.asset_category === "Camera" ?
                                        <Grid item xs={12} md={3}>
                                            <Controls.Select
                                                name="Parent"
                                                label="Parent"
                                                value={values.parent}
                                                onChange={handleInputChange}
                                                options={parent ? parent:[{ 'id': '-1', 'title': 'Select' }]}
                                                error={errors.parent}
                                                required={true} />
                                        </Grid>
                                            :null
                                }
                                {
                                    values.asset_category === "Jetson" || values.asset_category === "Raspberry pi" ?
                                        <><Grid item xs={12} md={3}>
                                            <Controls.Input
                                                name="mac_id"
                                                label="Mac IP"
                                                // required={true}
                                                value={values.mac_id}
                                                onChange={handleInputChange}
                                                error={errors.mac_id} />
                                        </Grid>
                                            {/* <Grid item xs={12} md={3}>
                                                <Controls.Input
                                                    name="usecases"
                                                    label="Usecase"
                                                    // required={true}
                                                    value={values.usecases}
                                                    onChange={handleInputChange}
                                                    error={errors.usecases} />
                                    </Grid> */}
                                    <Grid item xs={12} md={3}>
                                    <Controls.Select
                                                    name="usecases"
                                                    label="Usecase"
                                                    value={values.usecases}
                                                    onChange={handleInputChange}
                                                    options={usecase?usecase:{'id':'-1','title':'Select'}}
                                                    error={errors.usecases}
                                    />
                                </Grid>
                                </>
                                            :null
                                }
                            </Grid>
                </CardContent>
                
                <CardActions sx={{ float: 'right' }}>
                    <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>Update Asset</Button>
                    
                </CardActions>
            </Card>
        </Form>
        <Notification
                notify={notify}
                setNotify={setNotify}
            />
    </>)
}

export default EditAssetForm