import React, { useEffect, useRef, useCallback, useState } from "react";
import { Graphviz } from "graphviz-react";
import { graphviz } from "d3-graphviz";

export default function DAGSquirrel(props) {
  const { dot, width, height } = props
  const [error, setError] = useState(null); // State to hold the error message

  // gen css from props
  const styleval = {
    width: width || "100%",
    height: height || "100%",
  };
  const graphvizRoot = useRef(null);

  // update style in Graphviz div
  useEffect(() => {
    if (graphvizRoot.current) {
      const { id } = graphvizRoot.current;
      // use DOM id update style
      const el = document.getElementById(id);
      for (let [k, v] of Object.entries(styleval)) {
        el.styleval[k] = v;
      }
      try {
        graphviz(`#${id}`);
        setError(null); // Clear any previous errors
      } catch (error) {
        ////console.log(error); // Log the error message in the ////console
        setError(error.toString()); // Set the error message
      }
    }
  }, [graphvizRoot, dot, styleval]);

  const reset = useCallback(() => {
    if (graphvizRoot.current) {
      const { id } = graphvizRoot.current;
      graphviz(`#${id}`).resetZoom();
    }
  }, [graphvizRoot]);

  let graphvizComponent = null;
    try {
      //console.log(2354)
    graphvizComponent = (
      <Graphviz
        dot={dot}
        options={{
          useWorker: false,
          ...styleval,
          zoom: true,
        }}
        ref={graphvizRoot}
      />
    );
  } catch (error) {
    //console.log(1234,error);
    setError(error.toString());
  }

  return (
    <div
      style={{
        ...styleval,
        position: "relative",
      }}
    >
      {dot !== "" ? (
        <React.Fragment>
          {error ? (
            <div
              style={{
                position: "absolute",
                right: "5%",
                top: "5%",
                backgroundColor: "red",
                color: "white",
                padding: "5px",
              }}
            >
              {error}
            </div>
          ) : (
            graphvizComponent
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
};
