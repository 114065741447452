import React, { useState } from 'react'
// import SubHeader from '../../layout/squirrelLayout/SubHeader';
import { Backdrop, Box, CardHeader, CircularProgress, Fab, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import * as incService from '../../../services/incidentServices'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled } from "@mui/material/styles";
import ReactCardFlip from 'react-card-flip';
import cardimage from "../../../assets/images/incidence/3573741.jpg";

const StyledCard = styled(Card)(({ theme,hovershadow }) => ({
  ":hover": {
        boxShadow: theme.shadows[hovershadow]
  },
}));
// import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

export default function IncidentHome(props) {
    const [incidenttype, setIncidentType] = useState(null)
    const isbutton = true

    const [isFlippedArray, setIsFlippedArray] = useState(new Array(incidenttype).fill(false));

    const handleMouseEnter = (index) => {
      const newIsFlippedArray = [...isFlippedArray];
      const currentIsFlipped = newIsFlippedArray[index];
      newIsFlippedArray.fill(false); // flip all cards back to normal position
      newIsFlippedArray[index] = !currentIsFlipped; // flip the clicked card
      setIsFlippedArray(newIsFlippedArray);
  };

  const handleMouseLeave = () => {
    setIsFlippedArray(new Array(incidenttype).fill(false)); // flip all cards back to normal position
  };


    React.useEffect(() => {
        const incitypes = incService.getIncidentType()
        // console.log("types", incitypes)
        setIncidentType(incitypes)
    }, []);
  if (incidenttype) {
    return <>
      <Stack m={3} gap={3}>
      <Card sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="inherit">
              Incident Management
            </Typography>
            {/* {role === "admin" ? ( */}
            <Button component={Link} to="/squirrel/squirrel-incident/create" variant="contained" color="secondary">
              create
            </Button>
            {/* // ) : null} */}
          </Grid>
          </Card>
      </Stack>
      <Grid container spacing={3}>

        {

        incidenttype ?
          
          incidenttype.map((inci, index) => (
            <Grid item xs={12} md={3} key={inci.id}>
            <ReactCardFlip isFlipped={isFlippedArray[index]} flipDirection="horizontal">
            <div key="front" onMouseEnter={() => handleMouseEnter(index)}>
              <Card sx={{ padding: 1, width: '100%', height: 200 }} className="org_card" key={index}>
                

                <Box sx={{paddingLeft:2,paddingRight:2}}>                
                <CardMedia
                className='wsk-cp-img'
                component="img"
                alt="green iguana"
                height="140"
                image={cardimage} // Replace with the URL or path of your image
              />

                  <Card sx={{
                                      position: 'absolute',
                                      top: '3%',
                                      left: '85%',
                                      
                                      width:'fit-content',
                                      height:'fit-content',
                                      borderRadius:10,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-end',
                                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
                                      }}>

              <Fab size='small' sx={{ backgroundColor: "#FCB040" }}>
                    <Typography variant="h3">2</Typography>
                  </Fab>
                  </Card>

              </Box>


              <Card sx={{
                                      position: 'absolute',
                                      top: '75%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      backgroundColor: '#212121',
                                      width: 'fit-content',
                                      height: 'fit-content',
                                      borderRadius: 10,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-end',
                                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
                                      }}>
                                   

                                  <CardHeader  sx={{padding: 1, borderColor: 'green' }}
                                        title={inci.title}
                                        >
                                    </CardHeader>


                                    </Card>

              </Card>
              </div>
              <div key="back" onMouseLeave={handleMouseLeave}>
              <Card sx={{ padding: 1, width: '100%', height: 200 }} className="org_card" key={index}>
                {/* <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="140"
                                    image="/static/images/cards/contemplative-reptile.jpg"
                                /> */}
                <Card sx={{
                                     position: 'absolute',
                                     left: 0,
                                     right: 0,
                                     top: '50%',
                                     transform: 'translateY(-50%)',
                                     textAlign: 'center',
                                     backgroundColor:'#FCB040',
                                     }}>

                
                
                
                <CardActions >
                
                  <Button size="small" component={Link} sx={{marginLeft:"31%" ,backgroundColor:"#7D3A76",color:"black", '&:hover': {backgroundColor: '#6A1B9A', color: '#FFF'}}}
                    to={"/squirrel/squirrel-incident-services/incidents/" + inci.title}>
                      {/* <Fab sx={{marginRight:2}} size="small" color="default"  aria-label="add"   >
                                        <Tooltip title='view'> */}
                                            <IconButton aria-label="view"  >
                                                <VisibilityOutlinedIcon />
                                            </IconButton>
                                        {/* </Tooltip>
                                    </Fab> */}

                      View Incidents
                      </Button>
                </CardActions>
              



              
                </Card>
              </Card>
              </div>
              </ReactCardFlip>
            </Grid>
          ))
          : null
        }
      </Grid>
    </>
  }
  else { 
    return "Work in progress"
  }
    //     return <Backdrop
    //         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //         open
    //     >
    //         <CircularProgress color="inherit" />
    //     </Backdrop>
    // }
}
