import admin from './admin';
import dashboard from './dashboard';
import pages from './pages';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, pages, admin]
};

export default menuItems;
