import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'

const initialFValues = {
    id: 0,
    service: '-1',
    shortkey: '',
    description: ''
} 

export default function CCForm(props) {
    const { addOrEdit, recordForEdit, service } = props
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Select
                        name="service"
                        label="Service"
                        required={true}
                        value={values.service}
                        options={service}
                        onChange={handleInputChange}
                        error={errors.service}
                    />
                    <Controls.Input
                        name="shortkey"
                        label="Short Key"
                        value={values.shortkey}
                        onChange={handleInputChange}
                        error={errors.shortkey}
                    />
                    <Controls.InputTextArea
                        name="description"
                        label="Description"
                        required={true}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>

            </Grid>
        </Form>
    )
}
