import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import Controls from '../../../controls/Controls'
import { useForm, Form } from '../../../useForm'
import TextField from '@mui/material/TextField';


const initialFValues = {
    id: 0,
    location: '-1',
    ip: '',
    installeDate: '',
    confForJetson: ''
} 

export default function CCForm(props) {
    const { addOrEdit, recordForEdit, location } = props
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Select
                        name="location"
                        label="Store"
                        required={true}
                        value={values.location}
                        options={location}
                        onChange={handleInputChange}
                        error={errors.location}
                    />
                    <Controls.Input
                        name="ip"
                        label="IP"
                        value={values.ip}
                        onChange={handleInputChange}
                        error={errors.ip}
                    />
                    <TextField
                                name="installDate"
                                label="Installed Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.installDate}
                                // value={values.purchaseDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.installDate}                 
                            />
                    <Controls.InputTextArea
                        name="confForJetson"
                        label="confForJetson"
                        required={true}
                        value={values.confForJetson}
                        onChange={handleInputChange}
                        error={errors.confForJetson}
                    />
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>

            </Grid>
        </Form>
    )
}
