import React, { useEffect, useState } from "react";
import * as dsService from "../../../../services/dashboardServices";
import {
  Backdrop,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  CardContent,
  CardHeader,
  Badge,
  Box,
} from "@mui/material";
import CountUp from "react-countup";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { styled } from "@mui/material/styles";
import { MoreVert } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Widget from "./Widget";

function WidgetCustom(props) {
  const { setRecordForEdit, setOpenPopup } = props;
  const [widget, setWidget] = useState(null);
  const [dashData, setDashData] = useState(null);
  const [cards, setCards] = useState([
    { id: "1", title: "Card 1", content: "This is the content for card 1" },
    { id: "2", title: "Card 2", content: "This is the content for card 2" },
    { id: "3", title: "Card 3", content: "This is the content for card 3" },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(widget);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setWidget(items);
  };

  useEffect(() => {
    dsService.getWidgetData(setWidget);
  }, []);

  // console.log(widget);
  return (
    <>
      <Grid item xs={12} md={8} mb={3}>
        <Stack spacing={1}>
          {/* <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <DragDropContext onDragEnd={onDragEnd} style={{ width:"-webkit-fill-available !important;"}}>
              <Droppable droppableId="cards" style={{ width:"-webkit-fill-available !important;"}}>
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {widget
                      ? widget.map((wi, index) =>
                          wi.widget_pos === "left" ? (
                            <Draggable
                              key={wi.id}
                              draggableId={wi.id}
                              index={index}
                            >
                              {(provided) => (
                                <Grid
                                  item
                                  xs={12}
                                  md={wi.width}
                                  key={"widget_" + wi.id}
                                >
                                  <Widget
                                    id={wi.id}
                                    backgroundColor="#867ae9"
                                    setRecordForEdit={setRecordForEdit}
                                    setOpenPopup={setOpenPopup}
                                    wid={wi}
                                    provided={provided}
                                  />
                                </Grid>
                              )}
                            </Draggable>
                          ) : null
                        )
                      : null}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box> */}
          <Box sx={{ display: "flex", flexWrap: "wrap" }} >
  {widget
    ? widget.map((wi, index) =>
        wi.widget_pos === "left" ? (
          <Grid
            item
            xs={12}
            md={wi.width}
          key={"widget_" + wi.id}
          style={{ marginBottom: '6px' }}
          
          >
            <Widget
              id={wi.id}
              backgroundColor="#867ae9"
              setRecordForEdit={setRecordForEdit}
              setOpenPopup={setOpenPopup}
              wid={wi}
            />
          </Grid>
        ) : null
      )
    : null}
</Box>
        </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
        <Stack spacing={1}>
          {/* <Box sx={{ display: "flex", flexWrap: "wrap",width:"-webkit-fill-available !important;" }} >
            <DragDropContext onDragEnd={onDragEnd} style={{ width:"-webkit-fill-available !important;"}}>
              <Droppable droppableId="cards" style={{ width:"-webkit-fill-available !important;"}}>
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {widget
                      ? widget.map((wi, index) =>
                          wi.widget_pos === "right" ? (
                            <Draggable
                              key={wi.id}
                              draggableId={wi.id}
                              index={index}
                            >
                              {(provided) => (
                                <Grid
                                  item
                                  xs={12}
                                  md={wi.width}
                                  key={"widget_" + wi.id}
                                >
                                 
                                    <Widget
                                    id={wi.id}
                                    backgroundColor="#867ae9"
                                    setRecordForEdit={setRecordForEdit}
                                    setOpenPopup={setOpenPopup}
                                    wid={wi}
                                    provided={provided}
                                  />
                                  
                                  
                                </Grid>
                              )}
                            </Draggable>
                          ) : null
                        )
                      : null}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box> */}
           <Box sx={{ display: "flex", flexWrap: "wrap" }} >
  {widget
    ? widget.map((wi, index) =>
        wi.widget_pos === "right" ? (
          <Grid
            item
            xs={12}
            md={wi.width}
          key={"widget_" + wi.id}
          style={{ marginBottom: '6px' }}

          >
            <Widget
              id={wi.id}
              backgroundColor="#867ae9"
              setRecordForEdit={setRecordForEdit}
              setOpenPopup={setOpenPopup}
              wid={wi}
            />
          </Grid>
        ) : null
      )
    : null}
</Box>
        </Stack>
      </Grid>
    </>
  );
}

export default WidgetCustom;
