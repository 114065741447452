import {
  Avatar,
  Badge,
  Chip,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
  IconButton,
  Table,
  Typography,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import * as locService from '../../../../services/locationServices'
import Popup from '../../../ui/Popup'
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ReactTimeAgo from "react-time-ago";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
// import IconButton from '@mui/material/IconButton';
import CameraEnhanceOutlinedIcon from "@mui/icons-material/CameraEnhanceOutlined";
import TextField from "@mui/material/TextField";
import Moment from "moment";
// import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import NoImage from "../../../../assets/images/no-image-icon-15.png";
// import InventoryFlow from "../views/inventory_view_1";

TimeAgo.addDefaultLocale(en);


const values = {
  datechecker: new Date(),
  timechecker: new Date(),
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  backgroundColor: `${theme.palette.action.hover}`,
  color: `${theme.palette.common.black}`,
  fontSize: 16,
}));

function InventoryInfo(props) {
  const { id } = props;
  const [inventory, setInventory] = React.useState(null);
  const [timeTravelImages, setTimeTravelImages] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [newopenPopup, setnewOpenPopup] = React.useState(false);
  const [dateval, setDateval] = React.useState(null);
  const [timeval, setTimeval] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [dataflag, setDataflag] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);


  //console.log(inventory,"check inventory")
  
  React.useEffect(() => {
    locService.getInventoryInfo(setInventory, id);
  }, [id]);
  let date = new Date();
  if (inventory) {
    if (inventory.updated_datetime.length !== 0) {
      date = new Date(inventory.updated_datetime);
      date.setHours(date.getHours() + 3);
      date.setMinutes(date.getMinutes() + 33);
      date.setSeconds(date.getSeconds() + 2);
      //////console.log("date in new",date,new Date())
    }
  }
  // const formatAMPM = (date) => {
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   let ampm = hours >= 12 ? "pm" : "am";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12;
  //   minutes = minutes.toString().padStart(2, "0");
  //   let strTime = hours + ":" + minutes + " " + ampm;
  //   return strTime;
  // };

  function isInTheFuture(date) {
    const today = new Date();

    // 👇️ OPTIONAL!
    // This line sets the time of the current date to the
    // last millisecond, so the comparison returns `true` only if
    // date is at least tomorrow
    today.setHours(23, 59, 59, 998);

    return date > today;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "Date") {
      const is_future = isInTheFuture(value);
      if (is_future) {
        setError("Please select current date and time");
      } else {
        const formatDate = Moment(value).format("DD-MM-YYYY");
        setError(null);
        setDateval(formatDate);
      }
    } else {
      setTimeval(value.replace(":", "-"));
    }
  };
  // function chillerView() {
  //   setOpenPopup(true);
  //   // window.open('https://de002796.tele.difinative.com/', '_blank');
  //   // https://de002796.tele.difinative.com/DE002796/esp32-21/latest.png
  // }
  // locService.getImagesData()
  // function timeTravleView() {
  //   // //console.log("new", dateval, timeval, dateval === null, timeval === null)

  //   if (dateval === null) {
  //     setError("Please select date and time");
  //   } else if (timeval === null) {
  //     setError("Please select date and time");
  //   } else {
  //     const timenew = document.getElementsByName("Time")[0].value;
  //     // //console.log(timenew === '06:00',timenew,"timenew === '6:00'", typeof(timenew))
  //     // const new_date = formatAMPM(new Date());
  //     const changedtime = timenew.replace(":", "-");
  //     if (changedtime !== timeval) {
  //       setTimeval(changedtime);
  //     }
  //     setError(null);
  //     setDataflag(true);
  //     setnewOpenPopup(true);
  //   }
  // }
  function previousTime() {
    const time_data = timeval.replace("-", ":");
    let s = 0;
    if (time_data.split(":")[0] < 10) {
      s = Moment(time_data, "hh:mm").subtract("minutes", 10).format("hh:mm");
    } else {
      s = Moment(time_data, "hh:mm").subtract("minutes", 10).format("H:mm");
    }
    // const s = Moment(time_data, 'hh:mm').subtract('minutes', 10).format('H:mm')

    // const ss = Moment(time_data, 'hh:mm').add('minutes', 10).format('hh:mm')
      setTimeval(s.replace(":", "-"));
      const formatDate = document.getElementsByName("Date")[0].value;
     
      const date_str = Moment(formatDate).format("DD-MM-YYYY");
      locService.getImagesData(
        setTimeTravelImages,
        date_str,
        inventory.store_code,
        s
      );
  }
  function nextTime() {
    const time_data = timeval.replace("-", ":");
    let s = 0;
    // alert(time_data.split(':'))
    if (time_data.split(":")[0] < 10) {
      s = Moment(time_data, "hh:mm").add("minutes", 10).format("hh:mm");
    } else {
      s = Moment(time_data, "hh:mm").add("minutes", 10).format("H:mm");
    }
    // const s = Moment(time_data, 'hh:mm').add('minutes', 10).format('H:mm')
    // alert(s)
      setTimeval(s.replace(":", "-"));
      const formatDate = document.getElementsByName("Date")[0].value;
     
      const date_str = Moment(formatDate).format("DD-MM-YYYY");
      locService.getImagesData(
        setTimeTravelImages,
        date_str,
        inventory.store_code,
        s
      );
  }
  // //console.log(123,inventory.chillerimages)

  function timeTravelWithoutAuth() {
    // alert(12)
    const time_str = document.getElementsByName("Time")[0].value;
    const formatDate = document.getElementsByName("Date")[0].value;
    const date_str = Moment(formatDate).format("DD-MM-YYYY");
    //console.log(time_str.length);
    //console.log(date_str);
    if (date_str === null || date_str.length === 0) {
      setError("Please select date and time");  
    } else if (timeval === null || time_str.length === 0) {
      setError("Please select date and time");
    } else {
      setError(null);
      //   setLoading(true);
      locService.getImagesData(
        setTimeTravelImages,
        date_str,
        inventory.store_code,
        time_str
      );
    //   //console.log(123, timeTravelImages);
      //   if (timeTravelImages === null) {
      //       setLoading(true);

      //   } else {
      //     setLoading(false);
      //   }
      setDataflag(true);
      setnewOpenPopup(true);
      // setTimeout(() => {
      //     setLoading(false);
      //         // setOpen(false);
      // }, 2000)
    }
  }


  
  return (
    <>
      {inventory ? (
        <>
      {/* <InventoryFlow inventory={inventory} />  */}
          
          <Grid container spacing={1} sx={{ marginBottom: 1 }}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2 }}>
                <Divider sx={{ marginBottom: 2 }} textAlign="left">
                  {inventory.store}
                </Divider>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                  }}
                  key="first"
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Store Code"
                    />
                    {inventory.store_code}
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Last Updated"
                    />
                    {inventory.updated_datetime.length !== 0 ? (
                      <ReactTimeAgo date={date} />
                    ) : (
                      "No Data"
                    )}
                  </ListItem>
  
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Time Travel View"
                    />
                    <TextField
                      name="Date"
                      label="Date"
                      type="date"
                      size="small"
                      format="dd-MM-YYYY"
                      defaultValue={values.datechecker}
                      // value={values.datechecker}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      name="Time"
                      label="Time"
                      type="time"
                      size="small"
                      format="HH:MM"
                      defaultValue={values.timechecker}
                      // value={values.timechecker}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginLeft: 4 }}
                    />
                    {/* {inventory.shelf_count}
                     */}

                    {/* <Link to={{ pathname:`https://de002796-8080.tele.difinative.com/${inventory.store_code}.jpg`}} target="_blank"><IconButton aria-label="view" >
                                            <VisibilityOutlinedIcon />
                                        </IconButton> </Link> */}
                    {/* <Button variant="contained" color="success" onClick={timeTravleView} style={{ marginLeft: 4, height: 40 }}>
                                            View
                                        </Button> */}
                    <Button
                      variant="contained"
                      color="success"
                      onClick={timeTravelWithoutAuth}
                      style={{ marginLeft: 4, height: 40 }}
                    >
                      View
                    </Button>
                    <br></br>
                    {/* <span>{error}</span> */}
                    {/* <IconButton onClick={timeTravleView}>
                                            <VisibilityOutlinedIcon />
                                        </IconButton> */}
                  </ListItem>
                  {error ? <ListItem>{error}</ListItem> : null}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2 }}>
                <Divider
                  sx={{ marginBottom: 2, color: "red" }}
                  textAlign="left"
                >
                  Alerts !!!
                </Divider>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    color: "red",
                  }}
                  key="first"
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Empty Shelf's"
                    />
                    {/* {} */}
                    {inventory.empty_shelfs.length !== 0
                      ? inventory.empty_shelfs.join(", ")
                      : "No Empty Shelf's"}

                    {/* {//console.log(111,inventory.empty_shelfs)} */}
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Alert Message"
                    />
                    {inventory.empty_shelfs.length !== 0
                      ? "Stock to be filled"
                      : "No Empty Shelf's"}
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="Outstanding tour request"
                    />
                    {inventory.request_no}
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{ width: 150, maxWidth: 150 }}
                      align="center"
                    ></StyledTableCell>
                    {inventory.catalogue.map((cat) => {
                      return (
                        <StyledTableCell align="center">
                          {inventory.total[cat]}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventory.inventory.map((inv) => {
                    return (
                      <>
                        <StyledTableRow>
                          {inventory.column.map((col, ind) => {
                            if (ind === 0) {
                              if (inv["event"]) {
                                return (
                                  <>
                                    <StyledTableCell
                                      align="center"
                                      sx={{ width: 150, borderColor: "red" }}
                                      className="shelfempty"
                                      key={ind}
                                    >
                                      <Chip
                                        sx={{ width: 118 }}
                                        label={inv[col]}
                                        color="error"
                                      />
                                    </StyledTableCell>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <StyledTableCell
                                      align="center"
                                      sx={{ width: 150 }}
                                      key={ind}
                                      className="shelfempty"
                                    >
                                      <Chip
                                        sx={{ width: 118 }}
                                        label={inv[col]}
                                        color="success"
                                        variant="outlined"
                                      />
                                    </StyledTableCell>
                                  </>
                                );
                              }
                            } else {
                              if (inv["event"]) {
                                if (inv[col] !== 0) {
                                  return (
                                    <>
                                      <StyledTableCell
                                        align="center"
                                        sx={{ borderColor: "red" }}
                                        key={ind}
                                        className={
                                          ind === 1
                                            ? "td-first"
                                            : inventory.catalogue.length === ind
                                            ? "td-last"
                                            : "td-middle"
                                        }
                                      >
                                        <Badge
                                          overlap="circular"
                                          anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                          badgeContent={
                                            <SmallAvatar alt={col} src="#">
                                              {inv[col]}
                                            </SmallAvatar>
                                          }
                                        >
                                          <Avatar
                                            alt="1"
                                            src={inventory.icons[col]}
                                            className="cat_avatar"
                                          />
                                        </Badge>
                                      </StyledTableCell>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <StyledTableCell
                                        align="center"
                                        sx={{ borderColor: "red" }}
                                        key={ind}
                                        className={
                                          ind === 1
                                            ? "td-first"
                                            : inventory.catalogue.length === ind
                                            ? "td-last"
                                            : "td-middle"
                                        }
                                      ></StyledTableCell>
                                    </>
                                  );
                                }
                              } else {
                                if (inv[col] !== 0) {
                                  return (
                                    <>
                                      <StyledTableCell
                                        align="center"
                                        key={ind}
                                        className={
                                          ind === 1
                                            ? "td-first"
                                            : inventory.catalogue.length === ind
                                            ? "td-last"
                                            : "td-middle"
                                        }
                                      >
                                        <Badge
                                          overlap="circular"
                                          anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                          badgeContent={
                                            <SmallAvatar alt={col} src="#">
                                              {inv[col]}
                                            </SmallAvatar>
                                          }
                                        >
                                          <Avatar
                                            alt="2"
                                            src={inventory.icons[col]}
                                            className="cat_avatar"
                                          />
                                        </Badge>
                                      </StyledTableCell>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <StyledTableCell
                                        align="center"
                                        key={ind}
                                        className={
                                          ind === 1
                                            ? "td-first"
                                            : inventory.catalogue.length === ind
                                            ? "td-last"
                                            : "td-middle"
                                        }
                                      />
                                    </>
                                  );
                                }
                              }
                            }
                          })}
                        </StyledTableRow>
                      </>
                    );
                  })}
                  <StyledTableRow>
                    <StyledTableCell align="center"></StyledTableCell>
                    {inventory.catalogue.map(() => {
                      return (
                        <StyledTableCell
                          align="center"
                          className="td-footer2"
                        ></StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Popup
            title="Chiller View"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            {inventory.chillerimages.length === 0 ? (
              <div style={{ width: 300 }}>No images available</div>
            ) : (
              <ImageList cols={3} sx={{ height: 800 }} rowHeight={360} gap={10}>
                {inventory.chillerimages.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      style={{
                        transform: `rotate(${item.rotation}deg)`,
                        minWidth: 300,
                        minHeight: 362,
                      }}
                      src={`${item.img}?w=250&h=250&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=250&h=250&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      onError={() => {
                        setOpenPopup(false);

                        window.open(
                          "https://de002796.tele.difinative.com/",
                          "_blank"
                        );
                      }}
                      // loading="lazy"
                    />
                    <ImageListItemBar
                      sx={{
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                          "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                      }}
                      title={item.title}
                      position="top"
                      actionIcon={
                        <IconButton
                          sx={{ color: "white" }}
                          aria-label={`star ${item.title}`}
                        >
                          <CameraEnhanceOutlinedIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
            {/* <img style={{transform: `rotate(${rotation}deg)`}} src={""} width="600" /> */}
          </Popup>
          {/* //Time travel without Authorization */}
          <Popup
            title="Time Travel Chiller View"
            openPopup={newopenPopup}
            setOpenPopup={setnewOpenPopup}
          >
            {/* {loading ? <LinearProgress></LinearProgress> : null} */}

            {timeTravelImages ? (
              <>
                <Typography
                  variant="h3"
                  color="text.secondary"
                  sx={{ marginBottom: 2 }}
                >
                  Date And Time : {timeTravelImages[0].date}{" "}
                  {timeTravelImages[0].time}
                </Typography>
                <Button onClick={previousTime}>Prev</Button>
                <Button onClick={nextTime}>Next</Button>
                <ImageList
                  cols={2}
                  sx={{ height: 800 }}
                  rowHeight={360}
                  gap={10}
                              >
                                  {timeTravelImages.map((item) => (
                                      <ImageListItem key={item.image}>
                                          {/* <img style={{ transform: `rotate(${item.rotation}deg)`, minWidth: 300, minHeight: 362 }} */}
                                          <img style={{  minWidth: 300, minHeight: 362 }}
                                                    src={item.image} 
                                                    alt={item.camera}
                                                    onError={(event) => {
                                                        event.target.src = NoImage 
                                                    }}
                                                    
                                                />
                                                <ImageListItemBar
                                                    sx={{
                                                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                    }}
                                                    title={item.camera}
                                                    position="top"
                                                    actionIcon={<IconButton
                                                        sx={{ color: 'white' }}
                                                        aria-label={`star ${item.camera}`}
                                                    >
                                                        <CameraEnhanceOutlinedIcon />
                                                    </IconButton>}
                                                    actionPosition="left" />
                                      </ImageListItem>
                                  ))
                                  }
                </ImageList>
              </>
            ) : (
              <>
                <div style={{ width: 300 }}>No images available</div>
              </>
            )}


          </Popup>
          <Popup
            title="Alert!!!"
            openPopup={alertOpen}
            setOpenPopup={setAlertOpen}
          >
            {dateval} {timeval} images are not found !! or please{" "}
            <button
              onClick={() => {
                // setnewOpenPopup(false);
                setAlertOpen(false);
                window.open(
                  "https://squirrel-images.tele.difinative.com/",
                  "_blank"
                );
              }}
            >
              Click here to login
            </button>
          </Popup>
        </>
      ) : (
        // <Backdrop
        //         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //         open
        //     >
        //         <CircularProgress color="inherit" />
        // </Backdrop>
        "Inventory data is not mapped"
      )}
    </>
  );
}

export default InventoryInfo;
