// assets
import { StoreOutlined, CameraOutlined } from '@mui/icons-material';
import CameraOutdoorOutlinedIcon from '@mui/icons-material/CameraOutdoorOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import EventIcon from '@mui/icons-material/Event';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// constant
const icons = { StoreOutlined,FileDownloadOutlinedIcon, CameraOutlined,CameraOutdoorOutlinedIcon,FastfoodOutlinedIcon,CorporateFareOutlinedIcon,EventIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const pages = {
    id: 'location-map',
    type: 'group',
    permission:['admin','user','guest','creator','store_manager','business','operation','reporting'],
    children: [
        {
            id: 'stores',
            title: 'Store',
            type: 'item',
            url: '/stores',
            icon: icons.StoreOutlined,
            
        },
        {
            id: 'assets',
            title: 'Assets',
            type: 'item',
            url: '/assets',
            icon: icons.CameraOutdoorOutlinedIcon,
            breadcrumbs: false
            
        },
        {
            id: 'catalogues',
            title: 'Catalogues',
            type: 'item',
            url: '/catalogues',
            icon: icons.FastfoodOutlinedIcon,
            breadcrumbs: false
            
        },
        {
            id: 'organizations',
            title: 'Organizations',
            type: 'item',
            url: '/organizations',
            icon: icons.CorporateFareOutlinedIcon,
            breadcrumbs: false
            
        },
        // {
        //     id: 'incident',
        //     title: 'Incident Hub',
        //     type: 'item',
        //     url: '/squirrel-incidents',
        //     icon: icons.EventIcon,
        //     breadcrumbs: false
            
        // },
        {
            id: 'incident_management',
            title: 'Incident Management',
            type: 'item',
            url: '/squirrel-incident-services',
            icon: icons.EventIcon,
            breadcrumbs: false
            
        },
        {
            id: "Reports",
            title: "Reports",
            type: "item",
            url: "http://reporting.squirrelvision.ai",
            icon: icons.FileDownloadOutlinedIcon,
            external: true,
            target: true,
          },
    ]
};

export default pages;