
// material-ui
import Logo from '../../../../../assets/img/Squirrel_logo_Final-01.png'
import SQ from '../../../../../assets/img/sqword.png'
import {Grid, Typography } from "@mui/material";


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (

    <>
        <Grid container alignItems="center">
      <Grid item>
      <img src={Logo} alt="Squirrel" style={{width:'45px'}} />

      </Grid>
      <Grid item>
      <img src={SQ} alt="Squirrel" style={{width:'100px',marginTop:'25px'}}/>

      {/* <Typography variant="body1" sx={{color:"white",padding:1,marginTop:2}}>
            SQUIRREL
    </Typography> */}
      </Grid>
    </Grid>
    </>
);

export default LogoSection;
